import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./Dropdown.css";

function Dropdown() {
  const [productsDropdown, setProductsDropdown] = useState(false);

  const products = [
    { title: "Piefly Kisan Drones", link: "/Pieflykisandrones" },
    { title: "Surveillance Drones", link: "/surveillance" },
    { title: "Mapping Drones", link: "/mapping" },
    { title: "Solar Panel Cleaning Drones", link: "/Solarpanelcleaning" },
    { title: "Seed Dropping Drones", link: "/seed" },
    { title: "Loud Speaker Drones", link: "/loudspeakerdrone" },
    { title: "UGV", link: "/Ugvdrone" },
    { title: "Fixed Wing VTOL", link: "/Fixedwing" },
  ];

  const productsInFirstColumn = products.slice(0, 4);
  const productsInSecondColumn = products.slice(4, 8);

  useEffect(() => {
    if (productsDropdown) {
      window.scrollTo(0, 0); // Scroll to top when dropdown is opened
    }
  }, [productsDropdown]);

  const handleNavLinkClick = () => {
    setProductsDropdown(false);
    window.scrollTo(0, 0); // Scroll to top when a link is clicked
  };

  return (
    <>
      <ul
        className={productsDropdown ? "products-submenu clicked" : "products-submenu"}
        onClick={(e) => {
          setProductsDropdown(!productsDropdown);
          e.stopPropagation(); // Prevent the click event from propagating to the parent element
        }}
        style={{
          display: "flex",
          justifyContent: "center", // Center align horizontally
          alignItems: "center", // Center align vertically
          listStyle: "none",
          padding: "0px",
          marginTop: "-8px",
          marginLeft: "10px",
          fontFamily: 'Sans-serif',
          color: "blue", // Example color
          fontWeight: "bold", // Example font weight
        }}
      >
        <div style={{ flex: 1, textAlign: "center" }}>
          {productsInFirstColumn.map((item, index) => (
            <li key={index} style={{ marginBottom: "3px", marginRight: "3px", fontSize: "14px" }}>
              <NavLink
                to={item.link}
                activeClassName="active-link"
                onClick={handleNavLinkClick}
                style={{ textDecoration: "none" }}
              >
                <p style={{ margin: "0" }}>{item.title}</p>
              </NavLink>
            </li>
          ))}
        </div>
        <div style={{ flex: 1, textAlign: "center" }}>
          {productsInSecondColumn.map((item, index) => (
            <li key={index} style={{ marginBottom: "3px", marginRight: "3px", fontSize: "14px", fontFamily: "Sans-serif" }}>
              <NavLink
                to={item.link}
                activeClassName="active-link"
                onClick={handleNavLinkClick}
                style={{ textDecoration: "none" }}
              >
                <p style={{ margin: "0" }}>{item.title}</p>
              </NavLink>
            </li>
          ))}
        </div>
      </ul>
    </>
  );
}

export default Dropdown;
