import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import image from '../svg-sprite/mainslider/pieflylogo.png';
import Sidebar from './Sidebar';
import Dropdown from './Dropdown';
import Dropdown1 from './Dropdown1';
import Dropdown2 from './Dropdown2';
import "./Dropdown.css";
import Rpto from './rpto';
import { MdCall } from "react-icons/md";


const Navbar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [visible, setvisible] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [dropdown1, setDropdown1] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);
  const dropdownTimeoutRef = useRef(null);
  const [scrolledUp, setScrolledUp] = useState(true);

  // Event handlers for dropdown
  const handleMouseEnter = () => {
    clearTimeout(dropdownTimeoutRef.current);
    setDropdown(true);
    setDropdown1(false); // Close the other dropdown
  };

  const handleMouseLeave = () => {
    clearTimeout(dropdownTimeoutRef.current);
    dropdownTimeoutRef.current = setTimeout(() => {
      setDropdown(false);
    }, 500);
  };

  const handleProductsMouseEnter = (e) => {
    e.target.style.borderColor = 'white';
    e.currentTarget.style.transform = 'scale(1.05)'; // Zoom in effect
    setDropdown(true);
  };

  const handleProductsMouseLeave = (e) => {
    e.target.style.borderColor = 'transparent';
    e.currentTarget.style.transform = 'scale(1)'; // Zoom out effect
    setDropdown(false);
  };

  const handleServicesMouseEnter = (e) => {
    e.target.style.borderColor = 'white';
    e.currentTarget.style.transform = 'scale(1.05)'; // Zoom in effect
    setDropdown1(true);
  };

  const handleServicesMouseLeave = (e) => {
    e.target.style.borderColor = 'transparent';
    e.currentTarget.style.transform = 'scale(1)'; // Zoom out effect

    setDropdown1(false);
  };

  const handleRptoMouseEnter = (e) => {
    e.target.style.borderColor = 'white';
    e.currentTarget.style.transform = 'scale(1.05)'; // Zoom in effect
    setDropdown2(true);
  };

  const handleRptoMouseLeave = (e) => {
    e.target.style.borderColor = 'transparent';
    e.currentTarget.style.transform = 'scale(1)'; // Zoom out effect

    setDropdown2(false);
  };

  const handleAboutUsMouseEnter = (e) => {
    e.target.style.borderColor = ' white';
   
  };

  const handleAboutUsMouseLeave = (e) => {
    e.target.style.borderColor = 'transparent';
    
  };

  const handleNewsMouseEnter = (e) => {
    e.target.style.borderColor = ' white';
   
  };

  const handleNewsMouseLeave = (e) => {
    e.target.style.borderColor = 'transparent';
    
  };

  

  const handleArticlesMouseEnter = (e) => {
    e.target.style.borderColor = ' white';
   
  };

  const handleArticlesMouseLeave = (e) => {
    e.target.style.borderColor = 'transparent';
    
  };

  const handleContactUsMouseEnter = (e) => {
    e.target.style.borderColor = ' white';
   
  };

  const handleContactUsMouseLeave = (e) => {
    e.target.style.borderColor = 'transparent';
    
  };

  const handleLogin = () => {
    // Assume successful login, set isLoggedIn to true and set user data
    setLoggedIn(true);
    setUserData(/* user data object */);
  };

  // Event handlers for dropdown1
  const handleMouseEnter1 = () => {
    clearTimeout(dropdownTimeoutRef.current);
    setDropdown1(true);
    setDropdown(false); // Close the other dropdown
  };

  const handleMouseLeave1 = () => {
    clearTimeout(dropdownTimeoutRef.current);
    dropdownTimeoutRef.current = setTimeout(() => {
      setDropdown1(false);
    }, 500);
  };

  const handleDropdownEnter = () => {
    clearTimeout(dropdownTimeoutRef.current);
  };

  const handleDropdownLeave = () => {
    clearTimeout(dropdownTimeoutRef.current);
    setDropdown(false);
  };

  const [droneAnimation, setDroneAnimation] = useState(false);

  

  

  useEffect(() => {
    console.log('Drone animation toggled');
    const interval = setInterval(() => {
      setDroneAnimation(prevState => !prevState);
    }, 3000); // Change the duration as needed
  
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      const isScrolledUp = window.scrollY === 0;
      setScrolledUp(isScrolledUp);
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(dropdownTimeoutRef.current);
    };
  }, []);


  return (
    <div>
    {/* Start Header */}
    <header id="top-bar" style={{ background: '#000068', textAlign: 'center',fontFamily:'Sans-serif'}}>
        <div className="container-fluid">
          <div className="row justify-content-between no-gutters">
          <div className="col-auto side-col d-flex align-items-center text-nowrap" style={{ margin: '0 auto' }}>
              <img className="top-bar__logo" src={image} alt='logo' style={{ height: '160px', width: '220px', marginLeft: '0px',marginTop:'10px' }} />
              &emsp;&emsp;&emsp;&emsp;
              &emsp;&emsp;&emsp;&emsp;              &emsp;&emsp;&emsp;&emsp;
              &emsp;&emsp;&emsp;&emsp;
              &emsp;&emsp;&emsp;&emsp;

              <div className="col-auto " style={{ fontSize: 'small', color: 'white', textAlign: 'center' }}>
                <span className='playfair-display' style={{ fontWeight: 'bold',marginLeft:'34px',fontSize:'40px',fontFamily:
'Sans-serif' }}>PIEFLY AEROSPACE </span> <br />
              </div>
              &emsp;&emsp;&emsp;&emsp;
              &emsp;&emsp;&emsp;&emsp;            
              &emsp;&emsp;&emsp;&emsp;
              &emsp;&emsp;&emsp;&emsp;             
              &emsp;

<div className="col-auto">
  <Link to="/login" onMouseEnter={(e) => e.currentTarget.querySelector('button').style.transform = 'scale(1.05)'} onMouseLeave={(e) => e.currentTarget.querySelector('button').style.transform = 'scale(1)'}>
    <button className='playfair-display'style={{fontFamily:'Sans-serif', background: '#B6D0E2', fontSize: '15px', color: 'black', padding: '0px 30px', fontWeight: 'bold', borderRadius: '5px', cursor: 'pointer', transition: 'transform 0.3s' }}>
      Login
    </button>
  </Link>
</div>

          {/* End login button */}
           </div>
          </div>
          </div>
          </header>

          <Sidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(!isSidebarOpen)} />
{/* Add some spacing between Sidebar and Navbar */}
<div style={{ width: '20px' }}></div>
<div
      style={{
        background:  'white', // Change background color based on scroll position
                padding: '10px',
        marginTop: '100px',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center', // Center navbar items horizontally
      }}
    >
      <ul
  style={{
    display: 'flex',
    alignItems: 'center',
    listStyle: 'none',
    padding: 0,
    backgroundColor: 'white', // Background color set to white
  }}
>
        <li style={{ margin: '0px 10px' }}>
          <Link to="/" style={{ color: 'black', textDecoration: 'none', position: 'relative' }}>
          <span className='playfair-display'
              style={{
                position: 'relative',
                borderBottom: '4px solid transparent',
                paddingBottom: '12px',
                marginRight: '5px',
                transition: 'border-color 0.3s ease',
                fontWeight: 'bold',
                fontSize:'15px',
                fontFamily:'Sans-serif',
              }}
              onMouseOver={(e) => e.target.style.borderColor = 'black'}
              onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
            >
              HOME
            </span>
          </Link>
        </li>
        &emsp;
        &emsp;&emsp;
        <a
        href='#'
        style={{
          color: 'black',
          textDecoration: 'none',
          position: 'relative',
          fontWeight:'bold'
        }}
        onMouseEnter={handleProductsMouseEnter}
        onMouseLeave={handleProductsMouseLeave}
      >
        <span className='playfair-display'
          style={{
            position: 'relative',
            borderBottom: '4px solid transparent',
            paddingBottom: '12px',
            marginRight: '5px',
            transition: 'border-color 0.3s ease',
            fontSize:'15px',
            fontFamily:'Sans-serif',

          }}
          onMouseOver={(e) => e.target.style.borderColor = 'black'}
    onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
        >
          PRODUCTS
          {dropdown && <Dropdown />}
        </span>
      </a>
        &emsp;&emsp;&emsp;
<a
        href='#'
        style={{
          color: 'black',
          textDecoration: 'none',
          position: 'relative',
          fontWeight:'bold'
        }}
        onMouseEnter={handleServicesMouseEnter}
        onMouseLeave={handleServicesMouseLeave}
        
      >
        <span className='playfair-display'
          style={{
            position: 'relative',
            borderBottom: '4px solid transparent',
            paddingBottom: '12px',
            marginRight: '5px',
            transition: 'border-color 0.3s ease',
            fontSize:'15px',
            fontFamily:'Sans-serif',
            
          }}
          onMouseOver={(e) => e.target.style.borderColor = 'black'}
    onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
        >
          SERVICES
          {dropdown1 && <Dropdown1 />}
        </span>
      </a>
        &emsp;&emsp;&emsp;
       
<a
        
        href='#'
        style={{
          color: 'black',
          textDecoration: 'none',
          position: 'relative',
          fontWeight:'bold',
          cursor:'pointer'
        }}
        onMouseEnter={handleRptoMouseEnter}
        onMouseLeave={handleRptoMouseLeave}
        
      >
       <span className='playfair-display'
          style={{
            position: 'relative',
            borderBottom: '4px solid transparent',
            paddingBottom: '12px',
            marginRight: '5px',
            transition: 'border-color 0.3s ease',
            fontSize:'15px',
            fontFamily:'Sans-serif',

            
          }}
          onMouseOver={(e) => e.target.style.borderColor = 'black'}
    onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
        >
          RPTO
          {dropdown2 && <Dropdown2  />
}
        </span>
      </a>
&emsp; &emsp;&emsp;
<li
  style={{
    transition: 'transform 0.3s',
    position: 'relative',
    fontWeight: 'bold',
    fontFamily:'Sans-serif',
  }}
  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
  onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
>
  <a
    href="/#/about"
    style={{
      color: 'black',
      textDecoration: 'none',
      position: 'relative',
    }}
    onMouseEnter={handleAboutUsMouseEnter}
    onMouseLeave={handleAboutUsMouseLeave}
  >
   <span className='playfair-display'
      style={{
        position: 'relative',
        borderBottom: '4px solid transparent',
        paddingBottom: '12px',
        marginRight: '5px',
        transition: 'border-color 0.3s ease',
        fontSize:'15px',
        fontFamily:'Sans-serif',

      }}
      onMouseOver={(e) => e.target.style.borderColor = 'black'}
      onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
    >
      ABOUT US
    </span>
  </a>
  <Link to="/about" style={{ color: 'white', textDecoration: 'none' }}></Link>
</li>
&emsp; &emsp;&emsp;
<li
  style={{
    transition: 'transform 0.3s',
    position: 'relative',
    fontWeight: 'bold',
    fontFamily:'Sans-serif',

  }}
  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
  onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
>
  <a
    href="/#/news"
    style={{
      color: 'black',
      textDecoration: 'none',
      position: 'relative',
    }}
    onMouseEnter={handleNewsMouseEnter}
    onMouseLeave={handleNewsMouseLeave}
  >
   <span className='playfair-display'
      style={{
        position: 'relative',
        borderBottom: '4px solid transparent',
        paddingBottom: '12px',
        marginRight: '5px',
        transition: 'border-color 0.3s ease',
        fontSize:'15px',
        fontFamily:'Sans-serif',

      }}
      onMouseOver={(e) => e.target.style.borderColor = 'black'}
      onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
    >
      NEWS
    </span>
  </a>
  <Link to="/about" style={{ color: 'white', textDecoration: 'none' }}></Link>
</li>
&emsp; &emsp; &emsp;
<li
  style={{
    transition: 'transform 0.3s',
    position: 'relative',
    fontWeight: 'bold',
  }}
  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
  onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
>
  <a
    href="/#/articles"
    style={{
      color: 'black',
      textDecoration: 'none',
      position: 'relative',
    }}
    onMouseEnter={handleArticlesMouseEnter}
    onMouseLeave={handleArticlesMouseLeave}
  >
    <span className='playfair-display'
      style={{
        position: 'relative',
        borderBottom: '4px solid transparent',
        paddingBottom: '12px',
        marginRight: '5px',
        transition: 'border-color 0.3s ease',
        fontSize:'15px',
        fontFamily:'Sans-serif',


      }}
      onMouseOver={(e) => e.target.style.borderColor = 'black'}
      onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
    >
      ARTICLES
    </span>
  </a>
  <Link to="/about" style={{ color: 'white', textDecoration: 'none' }}></Link>
</li>
&emsp; &emsp;&emsp;
<li
  style={{
    transition: 'transform 0.3s',
    position: 'relative',
    fontWeight: 'bold',
  }}
  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
  onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
>
  <a
    href="/#/contactus"
    style={{
      color: 'black',
      textDecoration: 'none',
      position: 'relative',
    }}
    onMouseEnter={handleContactUsMouseEnter}
    onMouseLeave={handleContactUsMouseLeave}
  >
    <span className='playfair-display'
      style={{
        position: 'relative',
        borderBottom: '4px solid transparent',
        paddingBottom: '12px',
        transition: 'border-color 0.3s ease',
        fontSize:'15px',
        fontFamily:'Sans-serif',

      }}
      onMouseOver={(e) => e.target.style.borderColor = 'black'}
      onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
    >
      CONTACT US
    </span>
  </a>
  <Link to="/contactus" style={{ color: 'white', textDecoration: 'none' }}></Link>
</li>

</ul>   
              
  </div>

</div>
     
  );
};

export default Navbar;
