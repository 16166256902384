import React, { useState } from "react";
import "./Dropdown.css";

function Dropdown2() {
    const [RptoDropdown, setRptoDropdown] = useState(false);

    const rpto = [
       { title: "RPTO", 
        link: "#/rpto", 
        openInNewTab: true,
        style: { 
          color: 'white', 
          fontSize: '5px',
          fontFamily:'Sans-serif',
          marginLeft:'200px' 
        }}
      ,       
        { title: "INTERNSHIP", link: "#/internship" },
        { title: "WORKSHOP ", link: "#/Workshop" },
    ];

    const RptoInFirstColumn = rpto.slice(0, 4);

    return (
        <>
            <ul
                className={RptoDropdown ? "Rpto-submenu clicked" : "Rpto-submenu"}
                onClick={(e) => {
                    setRptoDropdown(!RptoDropdown);
                    e.stopPropagation();
                }}
                style={{ display: "flex", listStyle: 'none', padding: 0, marginTop: '-8px' }}
            >
                <div style={{ flex: 1 }}>
                    {RptoInFirstColumn.map((item, index) => (
                        <li key={index} style={{ marginBottom: '-20px', marginLeft: item.title === 'RPTO' ? '30px' : '10px', fontSize: '16px',marginTop:'-10px' }}>
                        <a
                                href={item.link}
                                style={{ textDecoration: 'none', fontFamily: "TimesNewRoman" }}
                                target={item.openInNewTab ? "_blank" : "_self"}  // Open in new tab if openInNewTab is true
                                rel={item.openInNewTab ? "noopener noreferrer" : ""} // Add noreferrer for security reasons
                            >
                                <p>{item.title}</p>
                            </a>
                        </li>
                    ))}
                </div>
            </ul>
        </>
    );
}

export default Dropdown2;
