import React, { useState, useEffect ,useRef} from "react";
import { loginUser } from "../auth/service";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { doLogin, isLoggedIn } from "../auth/index";
import { useNavigate, Link } from "react-router-dom";
import "./login.css";
import Navbar from './navbar';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { TfiTwitter } from "react-icons/tfi";
import { AiOutlineLinkedin } from "react-icons/ai";
import { BsYoutube } from "react-icons/bs";
import Footer from './Footer ';

import { FaUser, FaLock  } from "react-icons/fa";

function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
const [password, setPassword] = useState('');

const [logindata, setLogindata] = useState({
  username: "",
  password: "",
});

  

 
  // const handleChange = (event, field) => {
  //   let actualValue = event.target.value;
  //   setLogindata({ ...logindata, [field]: actualValue });
  // };


  const loginEnabled = username.trim() !== '' && password.trim() !== '';

  const handleLogin = () => {
    if (username.trim() === '' || password.trim() === '') {
      toast.error('Please provide both username and password');
      return;
    }

    // Proceed with login logic
  };

  



  useEffect(() => {
    if (isLoggedIn()) {
      window.history.replaceState(null, "", window.location.href);
      toast(
        "Still you are logged in, please click the forward button to view your dashboard"
      );
    }
  }, []);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    if (logindata.username.trim() === "" || logindata.password.trim() === "") {
      toast.warn("Username or password is required!!");
      setLoading(false);
      return;
    }

    loginUser(logindata)
      .then((data) => {
        toast.success("Login success");

        doLogin(data, () => {
          const userRole = JSON.parse(localStorage.getItem("data")).role;

          if (userRole === "ROLE_SUPER") {
            navigate("/superdashboard");
          } else {
            toast.info("Redirecting to user dashboard");
            navigate("/superdashboard");
          }
        });
      })
      .catch((error) => {
  if (error.response && error.response.status === 401) {
    toast.error(error.response.data.message);
  } else if (error.response && error.response.status === 404) {
    const logindetails = {
      username: logindata.username,
    };
    localStorage.setItem("Logindetails", JSON.stringify(logindetails));
    toast.error(error.response.data.message);
  } else {
    toast.error("Something went wrong!!!");
  }
})

      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
    <div className="page-background">

     <div> <Navbar/> </div>
      <br/>
      <br/>
      <br/>
    
      <form onSubmit={handleFormSubmit} style={{ border: 'none' }}>
      <div className="wrapper">
      
     

          <div>
            <h1 style={{ color: 'black' }}>Login</h1>
            
          <div className="input-box">
          <input
  type="email"
  placeholder="Username"
  value={logindata.username}
  onChange={(e) => setLogindata({ ...logindata, username: e.target.value })}
  required
  style={{ color: "black" }}
/>
              <FaUser className="icon" style={{ color: 'black' }} />
            </div>
            <div className="input-box">
            <input
  type="password"
  placeholder="Password"
  value={logindata.password}
  onChange={(e) => setLogindata({ ...logindata, password: e.target.value })}
  required
/>
              <FaLock className="icon" style={{ color: 'black' }} />
            </div>
          <div>
            <a href="#" style={{ color: 'black', display: 'flex', justifyContent: 'center', fontSize: '15px', margin: '-15px 0px 15px' }}> Forgot Password ?</a>
          </div>
          
          <Link to='/superdashboard'>
          <button type="button" onClick={handleLogin} disabled={!loginEnabled} style={{color:'black'}}>Login</button></Link>
                            <Link to='/register'>
            <div className="register-link" style={{ color: 'white' }}>
              <p> Don't have an account ? <a href="#" style={{ color: 'white' }}> Register </a></p>
            </div>
            </Link>
            <Link to='/studentlogin'>
            <div style={{ color: 'white' , textAlign:'center'}}></div>
              <p>  <a href="#" style={{ color: 'white', marginLeft:'125px' }}> Student login </a></p></Link>
              
            </div>
            
         
         
 </div>
        </form>
     
      
&emsp;
&emsp;
&emsp;
&emsp;
&emsp;


<br/>
</div>

<br/>
<br/>

<footer style={{ fontSize: '10px',fontFamily:'Sans-serif', padding: '38px', backgroundColor: '#000068', color: '#000', width: '1500px', marginLeft: '150px' }}>
    <div className="d-flex justify-content-between" style={{ marginLeft: '90px', marginRight: '90px' }}>
      <div className="col-md-12 col-lg-4 col-mobile-down">
        <div className="mobile-collapse">
          <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white' }}>Contact Information</h4>
          <div className="pt-collapse-content playfair-display" style={{ color: 'white', fontSize: '12px' }}>
            <address className='playfair-display'>
              <span># 70 </span>B block <span>1st</span> floor Anandnagar Mysore<span> 570022</span> Karnataka<br />
              <a className='playfair-display' href="mailto:pieflyaerospace@gmail.com" style={{ color: 'white', fontSize: '12px' }}>Info@pieflyaerospace.com</a>
            </address>
          </div>
        </div>
      </div>
  
      <div className="col-md-3 col-lg-2">
        <div className="mobile-collapse" style={{ maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}>
          <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white', textAlign: 'center' }}>Quick Links</h4>
          <div className="pt-collapse-content" style={{ color: 'white', fontSize: '12px' }}>
            <div className="oval-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px', padding: '10px' }}>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Home</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Training Program</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Enroll</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Shop</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Contacts</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Elements</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div className="col-md-3 col-lg-2">
        <div className="mobile-collapse" style={{ maxWidth: '300px', marginLeft: 'auto', marginRight: 'auto' }}>
          <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white', textAlign: 'center' }}>Useful Links</h4>
          <div className="pt-collapse-content" style={{ color: 'white', fontSize: '12px' }}>
            <div className="oval-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px', padding: '10px' }}>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Products Documents</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Services Documents</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Products</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Blog and News</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div className="col-md-3 col-lg-2">
        <div className="mobile-collapse" style={{ maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}>
          <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white', textAlign: 'center' }}>Main Menu</h4>
          <div className="pt-collapse-content" style={{ color: 'white', fontSize: '12px' }}>
            <div className="oval-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px', padding: '10px' }}>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>About Us</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Gallery</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Projects</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Contacts</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        <div className="footer-custom">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-4">
                {/* <div className="copyright"style={{color: 'white',fontSize:'12px' }}>
                  &copy; 2019 Quadron. All rights reserved.
                </div> */}
              </div>
            
             <div className='social-icons' style={{marginLeft:'600px',marginTop:'-20px'}}>
             <a className='social-icon-link whatsapp' href='https://wa.me/yourwhatsapplink' target='_blank' aria-label='WhatsApp' style={{ marginLeft: '-40px' }}>
  <FontAwesomeIcon icon={faWhatsapp} />
  </a>
    <a className='social-icon-link facebook' href='/' target='_blank' aria-label='Facebook'>
      <FontAwesomeIcon icon={faFacebookF} />
    </a>
    <a className='social-icon-link instagram' href='https://www.instagram.com/pieflyofficial?igsh=bmU0emdnbWs2aGIw' target='_blank' aria-label='Instagram' style={{marginLeft:'-40px'}}>
      <FontAwesomeIcon icon={faInstagram} />
    </a>
    <a className='social-icon-link twitter' href='https://x.com/pieflyofficial?t=RHOzsk0Vc47uIvKSCc5XVA&s=09' target='_blank' aria-label='Twitter' style={{marginLeft:'-40px'}}>
    <TfiTwitter />
    </a>
    <a className='social-icon-link linkedin' href='https://in.linkedin.com/company/piefly-r-d-private-limited' target='_blank' aria-label='LinkedIn' style={{marginLeft:'-40px'}}>
    <AiOutlineLinkedin />
    </a>
    <a className='social-icon-link Youtube' href='https://youtube.com/@piefly4302?si=Qecbd2nJ80cYx-Hk' target='_blank' aria-label='Youtube' style={{marginLeft:'-40px'}}>
    <BsYoutube />  </a>
  </div>
  
                </div>
              </div>
            </div>
          
      </footer>
      </>
  );
}

export default Login;


