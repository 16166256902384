import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import image1 from '../svg-sprite/mainslider/pieflylogo.png';
import Sidebar from './Sidebar';
import image2 from '../svg-sprite/mainslider/training.png';
import image7 from '../svg-sprite/mainslider/day5.png';
import { MdCall } from "react-icons/md";
import Footer from './Footer ';
import './Training.css';
import { IoMdCheckmark } from "react-icons/io";
import { PiArrowFatRightThin } from "react-icons/pi";
import image3 from '../svg-sprite/mainslider/right.png';
import image4 from '../svg-sprite/mainslider/box.png';
import image5 from '../svg-sprite/mainslider/day5-7.png';
import Dropdown3 from './Dropdown3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { TfiTwitter } from "react-icons/tfi";
import { AiOutlineLinkedin } from "react-icons/ai";
import { BsYoutube } from "react-icons/bs";
import Modal from './EnquiryModal'; // Assuming EnquiryModal component is in a file named EnquiryModal.js

const Training = () => {  
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [dropdown, setDropdown] = useState(false);  
    const [dropdown1, setDropdown1] = useState(false);
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [userData, setUserData] = useState(null);
    const dropdownTimeoutRef = useRef(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [dropdown3, setDropdown3] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    const handleEnquireClick = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
 
    const handleMouseEnter = () => {
      clearTimeout(dropdownTimeoutRef.current);
      setDropdown(true);
      setDropdown1(false); // Close the other dropdown
    };
  
    const handleMouseLeave = () => {
      clearTimeout(dropdownTimeoutRef.current);
      dropdownTimeoutRef.current = setTimeout(() => {
        setDropdown(false);
      }, 3500);
    };
  
    const handleEnrollMouseEnter = (e) => {
      e.target.style.borderColor = 'white';
      e.currentTarget.style.transform = 'scale(1.05)'; // Zoom in effect
      setDropdown3(true);
    };
  
    const handleEnrollMouseLeave = (e) => {
      e.target.style.borderColor = 'transparent';
      e.currentTarget.style.transform = 'scale(1)'; // Zoom out effect
  
      setDropdown3(false);
    };
  
  
    const handleRPTOMouseEnter = (e) => {
      e.target.style.borderColor = 'white';
    };
  
    const handleRPTOMouseLeave = (e) => {
      e.target.style.borderColor = ' transparent';
    };
  
    const handleAboutUsMouseEnter = (e) => {
      e.target.style.borderColor = ' white';
     
    };
  
    const handleAboutUsMouseLeave = (e) => {
      e.target.style.borderColor = 'transparent';
      
    };
  
    const handleNewsMouseEnter = (e) => {
      e.target.style.borderColor = ' white';
     
    };
  
    const handleNewsMouseLeave = (e) => {
      e.target.style.borderColor = 'transparent';
      
    };
    const [isHoveredday1, setIsHoveredday1] = useState(false);

  const handleMouseEnterday1 = () => {
    setIsHoveredday1(true);
  };

  const handleMouseLeaveday1 = () => {
    setIsHoveredday1(false);
  };
  
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [isHovered1, setIsHovered1] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);
    const [isHovered3, setIsHovered3] = useState(false);
    const [isHovered4, setIsHovered4] = useState(false);

    // Apply styles based on hover state
    const liStyles = {
      transition: 'transform 0.3s ease', // Add transition for smooth effect
      transform: isHovered ? 'scale(1.05)' : 'scale(1)', // Zoom in/out effect
      transform: isHovered1 ? 'scale(1.05)' : 'scale(1)', // Zoom in/out effect
      transform: isHovered2 ? 'scale(1.05)' : 'scale(1)', // Zoom in/out effect
      transform: isHovered3 ? 'scale(1.05)' : 'scale(1)', // Zoom in/out effect
      transform: isHovered4 ? 'scale(1.05)' : 'scale(1)', // Zoom in/out effect

    };
    const handleMouseEnter2 = () => {
      
      setIsHovered(true);
      setDropdownOpen(true);
    };
  
    const handleMouseLeave2 = () => {
      clearTimeout(dropdownTimeoutRef.current);
      dropdownTimeoutRef.current = setTimeout(() => {
        setDropdown(false);
      }, 3500);
      setIsHovered(false);
      setDropdownOpen(false);
    }; 
    const [activeTab, setActiveTab] = useState('0');

    const toggleTab = (tab) => {
      setActiveTab(tab);
    };
  
   
    
    const handleArticlesMouseEnter = (e) => {
      e.target.style.borderColor = ' white';
     
    };
 
    const [showDay1Content, setShowDay1Content] = useState(true);
    const [showDay2Content, setShowDay2Content] = useState(false);
    const [showDay3Content, setShowDay3Content] = useState(false);
    const [showDay4Content, setShowDay4Content] = useState(false);
    const [showDay5Content, setShowDay5Content] = useState(false);
  
    const toggleDay1Content = () => {
      setShowDay1Content(!showDay1Content);
      // Hide content for other days when toggling day 1
      setShowDay2Content(false);
      setShowDay3Content(false);
      setShowDay4Content(false);
      setShowDay5Content(false);
    };
  
    const toggleDay2Content = () => {
      setShowDay2Content(!showDay2Content);
      // Hide content for other days when toggling day 2
      setShowDay1Content(false);
      setShowDay3Content(false);
      setShowDay4Content(false);
      setShowDay5Content(false);
    };
  
    const toggleDay3Content = () => {
      setShowDay3Content(!showDay3Content);
      // Hide content for other days when toggling day 3
      setShowDay1Content(false);
      setShowDay2Content(false);
      setShowDay4Content(false);
      setShowDay5Content(false);
    };
  
    const toggleDay4Content = () => {
      setShowDay4Content(!showDay4Content);
      // Hide content for other days when toggling day 4
      setShowDay1Content(false);
      setShowDay2Content(false);
      setShowDay3Content(false);
      setShowDay5Content(false);
    };
  
    const toggleDay5Content = () => {
      setShowDay5Content(!showDay5Content);
      // Hide content for other days when toggling day 5
      setShowDay1Content(false);
      setShowDay2Content(false);
      setShowDay3Content(false);
      setShowDay4Content(false);
    };
  
    const handleArticlesMouseLeave = (e) => {
      e.target.style.borderColor = 'transparent';
      
    };
  
    const handleContactUsMouseEnter = (e) => {
      e.target.style.borderColor = ' white';
     
    };
    const tableContainerStyle = {
      position: 'relative',
    };
   
    
    const handleContactUsMouseLeave = (e) => {
      e.target.style.borderColor = 'transparent';
      
    };
  
  
    const handleMouseEnter1 = () => {
      clearTimeout(dropdownTimeoutRef.current);
      setDropdown1(true);
      setDropdown(false); // Close the other dropdown
    };
  
    const handleMouseLeave1 = () => {
      clearTimeout(dropdownTimeoutRef.current);
      dropdownTimeoutRef.current = setTimeout(() => {
        setDropdown1(false);
      }, 3500);
    };
    const toggleNav = () => {
      setIsNavOpen(!isNavOpen);
    };
  const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };
  
    
    const handleDropdownEnter = () => {
      clearTimeout(dropdownRef.current);
    };
  
    const handleDropdownLeave = () => {
      clearTimeout(dropdownRef.current);
      setDropdown(false);
    };

    const handleLogin = () => {
      // Assume successful login, set isLoggedIn to true and set user data
      setLoggedIn(true);
      setUserData(/* user data object */);
    };
    const [scrolledUp, setScrolledUp] = useState(true);


    useEffect(() => {
      const handleScroll = () => {
        const isScrolledUp = window.scrollY === 0;
        setScrolledUp(isScrolledUp);
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
return(
  <div>
    
  &emsp;&emsp;&emsp;
{/* Start Header */}

<header id="top-bar" style={{ background: '#000068' }}>
      <div className="container-fluid">
        <div className="row justify-content-between no-gutters">
        <div className="col-auto side-col d-flex align-items-center text-nowrap" style={{ margin: '0 auto' }}>
           
        <img className="top-bar__logo" src={image1} alt='logo' style={{ height: '150px', width: '150px', marginLeft: '-10px',marginTop:'10px' }} />
            &emsp;&emsp;
<br></br>
<br></br>
&emsp;

<div className="col-auto" style={{ color: 'white', textAlign: 'center' }}>
  <span className='playfair-display' style={{ fontWeight: 'bold', fontSize: '1.1em',fontFamily:'Sans-serif'}}>PIEFLY AVIATION REMOTE </span> <br />
  <span className='playfair-display' style={{ fontWeight: 'bold', fontSize: '1.1em',fontFamily:'Sans-serif'}}>PILOT TRAINING ORGANISATION</span>
</div>

&emsp;
&emsp;


<div className="col-auto" style={{ fontSize: 'small', color: 'white', textAlign: 'center' }}>
<span className='playfair-display' style={{ fontWeight: 'bold',fontFamily:'Sans-serif',
 }}>CENTRE FOR AVIATION </span> <br />
<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center',fontFamily:'Sans-serif',
 }}>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ fill: 'white' }}>
    <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
  </svg>
  <span className='playfair-display' style={{ marginLeft: '5px' }}>SJCE CAMPUS [STEP] MYSORE </span>
</div>
</div>

&emsp;
&emsp;
<div className="col-auto" style={{ fontSize: 'small', color: 'white', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ fill: 'white', marginRight: '5px' }}>
  <path d="M20 3H17V1H15V3H9V1H7V3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H20C21.1 21 22 20.1 22 19V5C22 3.9 21.1 3 20 3M20 19H4V8H20V19M7 10H9V12H7V10M11 10H13V12H11V10M15 10H17V12H15V10Z"/>
</svg>
<span style={{ display: 'inline-flex', alignItems: 'center',fontFamily:'Sans-serif',
 }}>
  <span style={{ fontWeight: 'bold', marginRight: '5px',fontFamily:'Sans-serif',
 }}>9:00 AM-6:00 PM </span>
  {/* <span style={{ marginLeft: '5px' }}>Monday-Saturday</span> */}
</span>
</div>
&emsp;
&emsp;
<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
<div style={{ fontSize: '24px', color: 'white' }}>
      <MdCall />
    </div>
    </div>
    &emsp;
    <div>
      <div style={{ marginRight: '50px', color:'white'  }}>91-96069 31676</div>
      <div className='playfair-display' style={{ marginRight: '7px', color:'white' ,fontSize:'14px',fontFamily:'Sans-serif',
}}>Piefly.rptotraining@gmail.com</div>
    </div>

&emsp;  
 
<div className="col-auto">
  <Link to="/login" onMouseEnter={(e) => e.currentTarget.querySelector('button').style.transform = 'scale(1.05)'} onMouseLeave={(e) => e.currentTarget.querySelector('button').style.transform = 'scale(1)'}>
    <button className='playfair-display' style={{ background: '#B6D0E2',fontFamily:'Sans-serif',
 fontSize: '12px', color: 'black', padding: '0px 30px', fontWeight: 'bold', borderRadius: '5px', cursor: 'pointer', transition: 'transform 0.3s' }}>
      Login
    </button>
  </Link>
</div>
            </div>
            {/* End login button */}
           </div>
          </div>
          
          </header>


      <Sidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(!isSidebarOpen)} />
{/* Add some spacing between Sidebar and Navbar */}
<div style={{ width: '20px' }}></div>
<div
      style={{
        background:  'white', // Change background color based on scroll position
                padding: '10px',
        marginTop: '100px',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center', 
        fontFamily:'Sans-serif',// Center navbar items horizontally
      }}
    >
      <ul
        style={{
          display: 'flex',
          alignItems: 'center',
          listStyle: 'none',
          padding: 0,
        }}
      >
<li style={{ marginRight: '10px', marginLeft: '50px' }} onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'} onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}>
        <a
        href="/#/rpto"
        style={{
          color: 'black',
          textDecoration: 'none',
          position: 'relative',
          fontWeight:'bold',
          fontFamily:'Sans-serif',
        }}
        onMouseEnter={handleRPTOMouseEnter}
        onMouseLeave={handleRPTOMouseLeave}
      >
        <span
          style={{
            position: 'relative',
            borderBottom: '4px solid transparent',
            paddingBottom: '12px',
            marginRight: '5px',
            transition: 'border-color 0.3s ease',
            fontFamily:'Sans-serif',
            fontSize:'15px'
          }}
          onMouseOver={(e) => e.target.style.borderColor = 'black'}
    onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
        >
         HOME
        
        </span>
      </a>
  <Link to="/#/rpto" style={{ color: 'white', textDecoration: 'none' }}></Link>
</li>

&emsp; &emsp; 
  &emsp;
  <li  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'} onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}>
          <a
          href="/#/training"
          style={{
            color: 'black',
            textDecoration: 'none',
            position: 'relative',
            fontWeight:'bold',
            fontFamily:'Sans-serif',
          }}
          onMouseEnter={handleRPTOMouseEnter}
          onMouseLeave={handleRPTOMouseLeave}
        >
          <span
           style={{
            position: 'relative',
            borderBottom: '4px solid transparent',
            paddingBottom: '12px',
            marginRight: '5px',
            transition: 'border-color 0.3s ease',
            fontFamily:'Sans-serif',
            fontSize:'15px',
            fontFamily:'Sans-serif',
            }}
            onMouseOver={(e) => e.target.style.borderColor = 'black'}
      onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
          >
           TRAINING PROGRAM
          
          </span>
        </a>
    <Link to="/#/training" style={{ color: 'white', textDecoration: 'none' }}></Link>
  </li>
  &emsp; &emsp; &emsp;
  <li  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'} onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}>
  <a
        href="/#/aboutt"
        style={{
          color: 'black',
          textDecoration: 'none',
          position: 'relative',
          fontWeight:'bold'
        }}
        onMouseEnter={handleAboutUsMouseEnter}
        onMouseLeave={handleAboutUsMouseLeave}
      >
        <span
          style={{
            position: 'relative',
            borderBottom: '4px solid transparent',
            paddingBottom: '12px',
            marginRight: '5px',
            transition: 'border-color 0.3s ease',
            fontFamily:'Sans-serif',
                fontSize:'15px'
          }}
          onMouseOver={(e) => e.target.style.borderColor = 'black'}
          onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
        >
          ABOUT US
          
        </span>
      </a>
    <Link to="/aboutt" style={{ color: 'white', textDecoration: 'none' }}></Link>
  </li>
  &emsp; &emsp; &emsp;
 
  <li  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'} onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}>
  <a
        
        style={{
          color: 'black',
          textDecoration: 'none',
          position: 'relative',
          fontWeight:'bold',
          cursor:'pointer'
        }}
        onMouseEnter={handleEnrollMouseEnter}
        onMouseLeave={handleEnrollMouseLeave}
      >
        <span
          style={{
            position: 'relative',
            borderBottom: '4px solid transparent',
            paddingBottom: '12px',
            marginRight: '5px',
            transition: 'border-color 0.3s ease',
            fontFamily:'Sans-serif',
                fontSize:'15x'
          }}
          onMouseOver={(e) => e.target.style.borderColor = 'black'}
          onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
        >
          ENROLL
          {dropdown3 && <Dropdown3 />}

        </span>
      </a>
  </li>
  &emsp; &emsp; &emsp; 
  &emsp;
  
  <li  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'} onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}>
  <a
        href="/#/studentlogin"
        style={{
          color: 'black',
          textDecoration: 'none',
          position: 'relative',
          fontWeight:'bold'
        }}
        onMouseEnter={handleArticlesMouseEnter}
        onMouseLeave={handleArticlesMouseLeave}
      >
        <span
          style={{
            position: 'relative',
            borderBottom: '4px solid transparent',
            paddingBottom: '12px',
            
            transition: 'border-color 0.3s ease',
            fontFamily:'Sans-serif',
                fontSize:'15px'
          }}
          onMouseOver={(e) => e.target.style.borderColor = 'black'}
          onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
        >
          STUDENT LOGIN
          
        </span>
      </a>
    <Link to="/studentlogin" style={{ color: 'white', textDecoration: 'none' }}></Link>
  </li>
  &emsp; &emsp; &emsp; &emsp;
 
  <li style={liStyles}>
  <a
    style={{
      color: 'black',
      textDecoration: 'none',
      position: 'relative',
      fontWeight: 'bold',
      marginRight: '10px',
    }}
    onMouseEnter={handleMouseEnter2}
    onMouseLeave={handleMouseLeave2}
  >
    <span
      style={{
        position: 'relative',
        borderBottom: '4px solid transparent',
        paddingBottom: '12px',
        transition: 'border-color 0.3s ease',
        cursor: 'pointer',
        fontFamily:'Sans-serif',
                fontSize:'15px'
      }}
      onMouseOver={(e) => e.target.style.borderColor = 'black'}
      onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
    >
      MORE
    </span>
  </a>
  {dropdownOpen && (
    <div
      style={{
        position: 'absolute',
        top: 'calc(100% + 10px)', // Adjusted top value for additional spacing
        right: '-30px',
        backgroundColor: 'white', // Dropdown background color
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Dropdown shadow
        borderRadius: '5px', // Dropdown border radius
        zIndex: '999', // Ensure dropdown appears above other content
        width: '150px', // Set dropdown width to 100%
        boxShadow: '0 0 10px rgb(232, 222, 222)', /* Adjust shadow color and size as needed */
        transition: 'transform 0.3s ease', // Add transition for smooth effect
        transform: isHovered ? 'scale(1.05)' : 'scale(1)', // Zoom in/out effect
        fontFamily:'Sans-serif',
                fontSize:'15px'
      }}
      onMouseEnter={handleMouseEnter2} // Keep dropdown open when mouse is over it
      onMouseLeave={handleMouseLeave2} // Close dropdown when mouse leaves it
    >
      {/* Dropdown content */}
      <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
        <li style={{ marginBottom: '0px', marginLeft: '15px' }}>
          <a href="/#/news" style={{ textDecoration: 'none', color: '#333' }}>
            NEWS
          </a>
        </li>
        <li style={{ marginBottom: '0px', marginLeft: '15px' }}>
          <a href="/#/contactus1" style={{ textDecoration: 'none', color: '#333' }}>
            CONTACT US
          </a>
        </li>
        {/* Add more dropdown items as needed */}
      </ul>
    </div>
  )}
</li>

</ul>                       
     
    </div> 


     <div id="page-main-content" class="page-main-content" style={{
          position: 'absolute',
          top: '100px', // Adjust this value to position the background below the navbar
          left: 0,
          width: '100%',
          height: '45%', // Adjust this value to occupy half of the page height
          background: 'linear-gradient(to bottom, #0073cf 0%, skyblue 50%, skyblue 100%)', // Shaded blue gradient
          boxShadow: 'inset 0px 144px 100px rgba(0, 0, 0, 0.1), inset 144px 0px 100px rgba(0, 0, 0, 0.1), inset -144px 0px 100px rgba(0, 0, 0, 0.1)' // Add inset shadow here
        }}>
       
       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '130px',marginLeft:'-300px' }}>

            <div class="tm-heading left tm-animation move-up" >
  <h3 class="heading playfair-display" style={{ fontSize:'40px',fontFamily:'Sans-serif'}}>Small Category (7 days) – Training </h3>
</div>
<Link to="/enroll">
  <div style={{ alignItems: 'center', marginTop: '-50px' }}>

  <button className='playfair-display' style={{marginLeft:'1000px', padding: '0px 10px',fontFamily:'Sans-serif', fontSize: '16px', width: '150px', backgroundColor: '#0073cf', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>Enroll</button>
 
 </div>
  </Link>
<div class="tm-heading left tm-animation move-up" style={{marginLeft:'150px',marginTop:'-20px'}}>
  <h3 class="heading playfair-display"  style={{  fontSize:'40px',fontFamily:'Sans-serif'}}> Curriculum as per <span className='playfair-display' style={{color:'#0073cf', fontWeight: 'bold'}}>DGCA</span>
</h3>
</div>

</div>
              </div>
              <br/>
              <br/>

              
              <div id="page-main-content" class="page-main-content" style={{
          position: 'absolute',
          top: '400px', // Adjust this value to position the background below the navbar
          left: 0,
          width: '100%',
          height: '30%', // Adjust this value to occupy half of the page height
          background: '#E6E6E6',
        }}>
       
       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '0px',marginLeft:'-300px' }}>

       <div class="tm-heading left tm-animation move-up" style={{ marginLeft:'-625px',marginTop:'20px'}}>
  <h3 class="heading playfair-display" style={{  fontSize:'25px',fontFamily:'Sans-serif', fontWeight:'normal'}}>Duration </h3>
</div>
<div class="tm-heading left tm-animation move-up" style={{marginLeft:'-625px',marginTop:'-20px'}}>
  <h3 class="heading "  style={{ fontFamily:'sans-serif', fontFamily:'Sans-serif',fontSize:'60px',color:'#0073cf'}}>7 
</h3>
</div>
<div class="tm-heading left tm-animation move-up" style={{marginLeft:'245px',marginTop:'-20px'}}>
  <h3 class="heading playfair-display"  style={{ fontSize:'22px',fontFamily:'Sans-serif',fontWeight:'normal'}}>DAYS OF TRAINING
</h3>

 <div class="tm-heading left tm-animation move-up" style={{ marginLeft:'515px',marginTop:'-200px'}}>
  <h3 class="heading playfair-display" style={{  fontSize:'25px',fontFamily:'Sans-serif', fontWeight:'normal'}}>Upto</h3>
</div>
<div class="tm-heading left tm-animation move-up" style={{marginLeft:'475px',marginTop:'-20px'}}>
  <h3 class="heading "  style={{ fontFamily:'sans-serif', fontFamily:'Sans-serif',fontSize:'60px',color:'#0073cf'}}>25Kg
</h3>
</div>
<div class="tm-heading left tm-animation move-up" style={{marginLeft:'445px',marginTop:'-20px'}}>
  <h3 class="heading playfair-display"  style={{  fontSize:'22px',fontFamily:'Sans-serif',fontWeight:'normal'}}>WEIGHT CATEGORY
</h3>
</div>
<div class="tm-heading left tm-animation move-up" style={{ marginLeft:'950px',marginTop:'-200px'}}>
  <h3 class="heading playfair-display" style={{  fontSize:'25px', fontFamily:'Sans-serif',fontWeight:'normal'}}>Topics</h3>
</div>
<div class="tm-heading left tm-animation move-up" style={{marginLeft:'955px',marginTop:'-20px'}}>
  <h3 class="heading"  style={{ fontFamily:'sans-serif', fontSize:'60px',fontFamily:'Sans-serif',color:'#0073cf'}}>68
</h3>
</div>
<div class="tm-heading left tm-animation move-up" style={{marginLeft:'905px',marginTop:'-20px'}}>
  <h3 class="heading playfair-display"  style={{  fontSize:'22px',fontFamily:'Sans-serif',fontWeight:'normal'}}>IN CURRICULUM
</h3>
</div>
</div>
            </div>
            <div>
            </div>
            </div>
            <div>
 <br/>
 <br/>
 <br/>
 <br/>
 <br/>
 <br/>
 <br/>
 <br/>
 <br/>
  <br/>
  <br/>
  <div>
  <div style={{ textAlign: 'center', marginTop: '120px' }}>
  <div
  style={{
    background:  '#FFD7BB',
    padding: '10px 20px',
    borderRadius: '5px',
    width: '150px',
    marginLeft:'200px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
    color: 'black',
    align:'center',
    marginBottom: '10px',
    transition: 'background-color 0.3s, color 0.3s, transform 0.3s', // Added transform transition
    transform: isHovered ? 'scale(1.1)' : 'scale(1)', // Apply scale based on hover state
  }}
  onClick={toggleDay1Content}
  onMouseEnter={() => setIsHovered(true)}
  onMouseLeave={() => setIsHovered(false)}
>
  <span className='playfair-display' style={{ fontSize: '24px', fontFamily:'Sans-serif',fontWeight: 'bold', marginLeft: '12px' }}>DAY 1</span>
</div>
</div>

<div style={{ textAlign: 'left', marginTop: '20px' }}>
  <div
    style={{
      background:  '#C3E3FF', // Change background color based on state
      marginLeft: '380px',
      marginTop: '-83px',
      padding: '10px 20px', // Adjusted padding
      borderRadius: '5px',
      width: '150px', // Specific width
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      cursor: 'pointer',
      align:'center',

      color: 'black' , // Change text color based on state
      marginBottom: '0px',
      transition: 'background-color 0.3s, color 0.3s', // Smooth transition for color change
      transform: isHovered1 ? 'scale(1.1)' : 'scale(1)', // Apply scale based on hover state
    }}
    onClick={toggleDay2Content} // Toggle Day 2 content visibility on click
    onMouseEnter={() => setIsHovered1(true)}
    onMouseLeave={() => setIsHovered1(false)}
  >
    <span className='playfair-display' style={{ fontSize: '24px',fontFamily:'Sans-serif', fontWeight: 'bold', marginLeft: '20px' }}>DAY 2</span>
  </div>

</div>

    </div>
    <div style={{ textAlign: 'left', marginTop: '20px' }}>
  <div
     style={{
      background:  '#fec9db', // Change background color based on state
      marginLeft: '580px',
      marginTop: '-83px',
      padding: '10px 20px', // Adjusted padding
      borderRadius: '5px',
      align:'center',

      width: '150px', // Specific width
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      cursor: 'pointer',
      color: 'black', // Change text color based on state
      marginBottom: '10px',
      transition: 'background-color 0.3s, color 0.3s', // Smooth transition for color change
      transform: isHovered2 ? 'scale(1.1)' : 'scale(1)', // Apply scale based on hover state
    }}
    onClick={toggleDay3Content} // Toggle Day 3 content visibility on click
    onMouseEnter={() => setIsHovered2(true)}
    onMouseLeave={() => setIsHovered2(false)}
  >
    <span className='playfair-display' style={{ fontSize: '24px',fontFamily:'Sans-serif', fontWeight: 'bold', marginLeft: '20px' }}>DAY 3</span>
  </div>


</div>
  </div>
  <div style={{ textAlign: 'left', marginTop: '20px' }}>
  <div
    style={{
      background: '#BCBCF2', // Change background color based on state
      marginLeft:'780px',
      marginTop:'-83px',
      padding: '10px 20px', // Adjusted padding
      borderRadius: '5px',
      width: '150px', // Specific width
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      cursor: 'pointer',
      align:'center',

      color:'black' , // Change text color based on state
      marginBottom: '10px',
      transition: 'background-color 0.3s, color 0.3s', // Smooth transition for color change
      transform: isHovered3 ? 'scale(1.1)' : 'scale(1)', // Apply scale based on hover state
    }}
    onClick={toggleDay4Content} // Toggle Day 3 content visibility on click
    onMouseEnter={() => setIsHovered3(true)}
    onMouseLeave={() => setIsHovered3(false)}
  >
    <span className='playfair-display' style={{ fontSize: '24px', fontFamily:'Sans-serif',fontWeight: 'bold', marginLeft: '20px' }}>DAY 4</span>
  </div>

</div>
<div style={{ textAlign: 'left', marginTop: '20px' }}>
  <div
    style={{
      background:  '#FFD7BB', // Change background color based on state
      marginLeft:'980px',
      marginTop:'-83px',
      padding: '10px 20px', // Adjusted padding
      borderRadius: '5px',
      align:'center',

      width: '170px', // Specific width
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      cursor: 'pointer',
      color:'black' , // Change text color based on state
      marginBottom: '10px',
      transition: 'background-color 0.3s, color 0.3s', // Smooth transition for color change
      transform: isHovered4 ? 'scale(1.1)' : 'scale(1)', // Apply scale based on hover state
    }}
    onClick={toggleDay5Content} // Toggle Day 3 content visibility on click
    onMouseEnter={() => setIsHovered4(true)}
    onMouseLeave={() => setIsHovered4(false)}
  >
    <span className='playfair-display' style={{ fontSize: '24px', fontWeight: 'bold', marginLeft: '15px' }}>DAY 5 - 7</span>
  </div>
   
  {showDay1Content && (
    
<div
        style={{
          background:'#FFF5EE',
          padding: '20px',
          width: '1200px',
          borderRadius: '10px',
          align:'center',
          marginTop: '20px',
          marginLeft:'70px',
          border: '1px solid grey',
          transition: 'margin-top 0.3s',
          cursor: 'pointer',
          boxShadow: isHoveredday1 ? '0px 0px 10px 0px rgba(0,0,0,0.5)' : 'none',
          
        }}
        onMouseEnter={handleMouseEnterday1}
        onMouseLeave={handleMouseLeaveday1}
      >
        <p>
        <h3 className='playfair-display' style={{ marginTop: '10px',fontFamily:'Sans-serif', marginLeft:'60px',fontSize:'20px' }}>
        "AVIATION REGULATION" <br />
    set by the Directorate General<br />
     of Civil Aviation (DGCA) 
      </h3>
    
        </p>
        <p className='playfair-display' style={{ marginTop: '20px', marginLeft:'60px', fontFamily:'Sans-serif',fontSize:'16px',color:'black'}}>
        <IoMdCheckmark /> &emsp;
Categorization
      </p>
      <p className='playfair-display' style={{ marginTop: '20px', marginLeft:'60px',fontFamily:'Sans-serif', fontSize:'16px',color:'black'}}>
        <IoMdCheckmark /> &emsp;
        Fundamental aviation rules
      </p>
      <p className='playfair-display' style={{ marginTop: '20px', marginLeft:'60px',fontFamily:'Sans-serif', fontSize:'16px',color:'black'}}>
        <IoMdCheckmark /> &emsp;
        Important aspects
      </p>
      <p className='playfair-display' style={{ marginTop: '20px', marginLeft:'60px', fontFamily:'Sans-serif',fontSize:'16px',color:'black'}}>
        <IoMdCheckmark /> &emsp;
        Do’s and Dont’s
      </p>
      <br/>
      <p>
        <h3 className='playfair-display' style={{ marginTop: '-380px', marginLeft:'420px',fontFamily:'Sans-serif',fontSize:'20px' }}>
        FUNDAMENTALS OF AVIATION
      </h3>
    
        </p>
        <p className='playfair-display' style={{ marginTop: '20px', marginLeft:'420px',fontFamily:'Sans-serif', fontSize:'16px',color:'black'}}>
        <IoMdCheckmark /> &emsp;
         Basic of aviation,principles of <br /> aerial navigation
      </p>
      <p className='playfair-display' style={{ marginTop: '20px', marginLeft:'420px',fontFamily:'Sans-serif', fontSize:'16px',color:'black'}}>
        <IoMdCheckmark /> &emsp;
       Aerodynamics: Air flow dynamics,<br /> study of air motion
      </p>
      <p className='playfair-display' style={{ marginTop: '20px', marginLeft:'420px', fontFamily:'Sans-serif',fontSize:'16px',color:'black'}}>
        <IoMdCheckmark /> &emsp;
        Take-off flight and landing:<br /> [Departure, airborne phase, and arrival]
      </p>
      <p className='playfair-display' style={{ marginTop: '20px', marginLeft:'420px',fontFamily:'Sans-serif', fontSize:'16px',color:'black'}}>
        <IoMdCheckmark /> &emsp;
         Maneuvers, turns, and circuit pattern:<br /> aerial movements, rotations, and flight path orbit
      </p>
      <br/>
      <p>
        <h3 className='playfair-display' style={{ marginTop: '-425px', marginLeft:'820px',fontFamily:'Sans-serif',fontSize:'20px' }}>
        AIR TRAFFIC CONTROL PROTOCOLS <br />
    AND VOICE COMMUNICATION
      </h3>
    
        </p>
        <p className='playfair-display' style={{ marginTop: '20px', marginLeft:'820px',fontFamily:'Sans-serif', fontSize:'16px',color:'black'}}>
        <IoMdCheckmark /> &emsp;
        Familiarity with ATC procedures, grasp of air traffic control operations      </p>

      <p className='playfair-display' style={{ marginTop: '20px', marginLeft:'820px', fontFamily:'Sans-serif',fontSize:'16px',color:'black'}}>
        <IoMdCheckmark /> &emsp;
 Airspace layout and restrictions, understanding of restricted areas including No Drone Zones.
       </p>
      <p className='playfair-display' style={{ marginTop: '20px', marginLeft:'820px', fontFamily:'Sans-serif',fontSize:'16px',color:'black'}}>
        <IoMdCheckmark /> &emsp;
        Interacting with ATC, including reporting position and altitude      </p>
      <p className='playfair-display' style={{ marginTop: '20px', marginLeft:'820px', fontFamily:'Sans-serif',fontSize:'16px',color:'black'}}>
        <IoMdCheckmark /> &emsp;
        Pre-flight planning processes,flight preparation procedures      </p>
        <p className='playfair-display' style={{ marginTop: '20px', marginLeft:'820px',fontFamily:'Sans-serif', fontSize:'16px',color:'black'}}>
        <IoMdCheckmark /> &emsp;
        Strategies for collision prevention   </p>
        <p className='playfair-display' style={{ marginTop: '20px', marginLeft:'820px',fontFamily:'Sans-serif', fontSize:'16px',color:'black'}}>
        <IoMdCheckmark /> &emsp;
        RT communication methods, radio telephony practices </p>
        <p className='playfair-display' style={{ marginTop: '20px', marginLeft:'820px',fontFamily:'Sans-serif', fontSize:'16px',color:'black'}}>
        <IoMdCheckmark /> &emsp;
        Accepted radio vocabulary and phraseology, standard radio language  </p>
        <p className='playfair-display' style={{ marginTop: '20px', marginLeft:'820px',fontFamily:'Sans-serif', fontSize:'16px',color:'black'}}>
        <IoMdCheckmark /> &emsp;
        Radio communication practice, training in RT techniques.  </p>
</div>
  )}
  {showDay2Content && (
    
    <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      background: '#f0f8ff',
      padding: '50px',
      width: '1100px',
      marginLeft:'100px',
      borderRadius: '10px',
      marginTop: '20px',
      border: '1px solid grey',
      boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.5)',
    }}
  >
    <div style={{ flex: '1', marginRight: '10px', borderRight: '1px solid #ccc', paddingRight: '20px' }}>

    <h1 className='playfair-display' style={{ fontSize:'20px',fontFamily:'Sans-serif',width:'500px'}}>1. Aerodynamic characteristics of fixed-wing aircraft </h1>
    <p className='playfair-display' style={{ marginTop: '20px',fontFamily:'Sans-serif', fontSize:'19px',color:'black'}}>
        <IoMdCheckmark /> &emsp;
         Varieties of fixed-wing drones construction, components, and vocabulary </p>

    <p className='playfair-display' style={{ marginTop: '20px',fontFamily:'Sans-serif', fontSize:'19px',color:'black'}}>
        <IoMdCheckmark /> &emsp;
        Functioning and movements of fixed-wing drones </p>
    
        <p className='playfair-display' style={{ marginTop: '20px',fontFamily:'Sans-serif', fontSize:'19px',color:'black'}}>
        <IoMdCheckmark /> &emsp;
       Uses and functionalities </p>
    
       <p className='playfair-display' style={{ marginTop: '20px', fontFamily:'Sans-serif',fontSize:'19px',color:'black', borderBottom: '1px solid #ccc', paddingBottom: '160px'}}>
        <IoMdCheckmark /> &emsp;
        Pros and cons compared to multi-rotor drones</p>

       </div>
       <div style={{ flex: '1', marginRight: '10px' }}>
  <h2 className='playfair-display' style={{ fontSize: '20px',fontFamily:'Sans-serif', width: '500px', marginLeft: '-40px' }}>2. Overview of multirotor drones </h2>
  <p className='playfair-display' style={{ marginTop: '20px',fontFamily:'Sans-serif', fontSize: '19px', color: 'black', marginLeft: '65px' }}>
    <IoMdCheckmark /> &emsp; Fundamental drone vocabulary
  </p>
  <p className='playfair-display' style={{ marginTop: '20px',fontFamily:'Sans-serif', fontSize: '19px', color: 'black', marginLeft: '65px' }}>
    <IoMdCheckmark /> &emsp; Varieties of drones, construction materials, and dimensions
  </p>
  <p className='playfair-display' style={{ marginTop: '20px', fontFamily:'Sans-serif',fontSize: '19px', color: 'black', marginLeft: '65px' }}>
    <IoMdCheckmark /> &emsp; Motors and Propellers: Engines and rotor blades
  </p>
  <p className='playfair-display' style={{ marginTop: '20px',fontFamily:'Sans-serif', fontSize: '19px', color: 'black', marginLeft: '65px' }}>
    <IoMdCheckmark /> &emsp; Electronic Speed Controller (ESC) , flight controllers
  </p>
  <p className='playfair-display' style={{ marginTop: '20px',fontFamily:'Sans-serif', fontSize: '19px', color: 'black', marginLeft: '65px', borderBottom: '1px solid #ccc', paddingBottom: '10px'}}>
    <IoMdCheckmark /> &emsp; Functioning and uses of drones Prons and Cons compared to fixed-wing drones
  </p>
</div>

    <div style={{ flex: '1', marginRight: '10px' }}>
    <h3 className='playfair-display' style={{ fontSize:'20px',fontFamily:'Sans-serif',width:'500px', marginTop:'550px', marginLeft:'-990px'}}>3. Atmospheric conditions, climate science </h3>
    <p className='playfair-display' style={{ marginTop: '20px',fontFamily:'Sans-serif', fontSize:'19px',color:'black',marginLeft:'-990px'}}>
        <IoMdCheckmark /> &emsp;
        Standard air properties</p>

        <p className='playfair-display' style={{ marginTop: '20px',fontFamily:'Sans-serif', fontSize:'19px',color:'black',marginLeft:'-990px'}}>
        <IoMdCheckmark /> &emsp;
        Air pressure measurement</p>
        <p className='playfair-display' style={{ marginTop: '20px',fontFamily:'Sans-serif', fontSize:'19px',color:'black',marginLeft:'-990px'}}>
        <IoMdCheckmark /> &emsp;
        Heat and temperature</p>
        <p className='playfair-display' style={{ marginTop: '20px',fontFamily:'Sans-serif', fontSize:'19px',color:'black',marginLeft:'-990px'}}>
        <IoMdCheckmark /> &emsp;
        Air currents, atmospheric movement</p>
        <p className='playfair-display' style={{ marginTop: '20px',fontFamily:'Sans-serif', fontSize:'19px',color:'black',marginLeft:'-990px'}}>
        <IoMdCheckmark /> &emsp;
        Atmospheric humidity</p>
    </div>
    <div style={{ flex: '1' }}>
    <h4 className='playfair-display' style={{ fontSize:'20px',fontFamily:'Sans-serif',width:'500px', marginTop:'550px', marginLeft:'-400px'}}>4. UAV equipment upkeep, drone servicing</h4>
    <p className='playfair-display' style={{ marginTop: '20px',fontFamily:'Sans-serif', fontSize:'19px',color:'black',marginLeft:'-400px'}}>
        <IoMdCheckmark /> &emsp;
      UAV maintenance, flight control unit <br/>upkeep, ground station servicing</p>

      <p className='playfair-display' style={{ marginTop: '20px', fontFamily:'Sans-serif',fontSize:'19px',color:'black',marginLeft:'-400px'}}>
        <IoMdCheckmark /> &emsp;
        Ground gear maintenance, battery and <br/>payload upkeep</p>
        <p className='playfair-display' style={{ marginTop: '20px',fontFamily:'Sans-serif', fontSize:'19px',color:'black',marginLeft:'-400px'}}>
        <IoMdCheckmark /> &emsp;
       Scheduled servicing: Regular maintenance,<br/> planned servicing</p>
    </div>
  </div>
  )}
  {showDay3Content && (
   
    <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      background: '#FFF0F5',
      padding: '50px',
      width: '1100px',
      marginLeft:'100px',
      borderRadius: '10px',
      marginTop: '20px',
      border: '1px solid grey',
      boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.5)',
    }}
    onMouseOver={(e) => e.target.style.borderColor = 'black'}
    onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
  >
    <div style={{ flex: '1', marginRight: '10px', borderRight: '1px solid #ccc', paddingRight: '20px' }}>

    <h1 className='playfair-display' style={{ fontSize:'20px',fontFamily:'Sans-serif',width:'500px'}}>1. In-Flight emergencies:</h1>
    <p className='playfair-display' style={{ marginTop: '20px', fontFamily:'Sans-serif',fontSize:'19px',color:'black'}}>
        <IoMdCheckmark /> &emsp;
        Communication failure, signal dropout, link disconnection </p>

    <p className='playfair-display' style={{ marginTop: '20px', fontFamily:'Sans-serif',fontSize:'19px',color:'black'}}>
        <IoMdCheckmark /> &emsp;
        Unintended flight, runaway drones, uncontrolled movement</p>
    
        <p className='playfair-display' style={{ marginTop: '20px',fontFamily:'Sans-serif', fontSize:'19px',color:'black'}}>
        <IoMdCheckmark /> &emsp;
        Power failure, engine shutdown, propulsion loss </p>
    
       <p className='playfair-display' style={{ marginTop: '20px', fontFamily:'Sans-serif',fontSize:'19px',color:'black', borderBottom: '1px solid #ccc', paddingBottom: '100px'}}>
        <IoMdCheckmark /> &emsp;
        Flight control malfunction, surface control issues, aerodynamic control loss</p>

       </div>
       <div style={{ flex: '1', marginRight: '10px' }}>
  <h2 className='playfair-display' style={{ fontSize: '20px',fontFamily:'Sans-serif', width: '500px', marginLeft: '-10px' }}>2. Freight, load Setup, deployment, application, deployment</h2>
  <p className='playfair-display' style={{ marginTop: '20px', fontFamily:'Sans-serif',fontSize: '19px', color: 'black', marginLeft: '65px' }}>
    <IoMdCheckmark /> &emsp; Payload Classifications
  </p>
  <p className='playfair-display' style={{ marginTop: '20px',fontFamily:'Sans-serif', fontSize: '19px', color: 'black', marginLeft: '65px' }}>
    <IoMdCheckmark /> &emsp; Payload Components
  </p>
  <p className='playfair-display' style={{ marginTop: '20px',fontFamily:'Sans-serif', fontSize: '19px', color: 'black', marginLeft: '65px', borderBottom: '1px solid #ccc', paddingBottom: '215px' }}>
    <IoMdCheckmark /> &emsp; Payload Attributes
  </p>

</div>

    <div style={{ flex: '1', marginRight: '10px' }}>
    <h3 className='playfair-display' style={{ fontSize:'20px',fontFamily:'Sans-serif',width:'500px', marginTop:'550px', marginLeft:'-1000px'}}>3. Visual data analysis, visual media interpretation </h3>
    <p className='playfair-display' style={{ marginTop: '20px',fontFamily:'Sans-serif', fontSize:'19px',color:'black',marginLeft:'-1000px'}}>
        <IoMdCheckmark /> &emsp;
       Visual examination guidelines, principles <br/>of visual scrutiny</p>

        <p className='playfair-display' style={{ marginTop: '20px',fontFamily:'Sans-serif', fontSize:'19px',color:'black',marginLeft:'-1000px'}}>
        <IoMdCheckmark /> &emsp;
        Visual media interpretation, image visual<br/> data assessment</p>
        <p className='playfair-display' style={{ marginTop: '20px', fontFamily:'Sans-serif',fontSize:'19px',color:'black',marginLeft:'-990px'}}>
        <IoMdCheckmark /> &emsp;
        Analysis: Data analysis, visual data <br/>analysis, media analysis</p>
        
    </div>
    <div style={{ flex: '1' }}>
    <h4 className='playfair-display' style={{ fontSize:'20px',fontFamily:'Sans-serif',width:'500px', marginTop:'550px', marginLeft:'-480px'}}>4. Supplementary sessions, and BVLOS Experiment: Beyond Visual Line of sight experiment, extended visual line of sight (EVLOS) Experiment</h4>
    <p className='playfair-display' style={{ marginTop: '20px',fontFamily:'Sans-serif', fontSize:'19px',color:'black',marginLeft:'-480px'}}>
        <IoMdCheckmark /> &emsp;
        Nocturnal flight operations, operational <br/>framework, operational plan</p>

      <p className='playfair-display' style={{ marginTop: '20px',fontFamily:'Sans-serif', fontSize:'19px',color:'black',marginLeft:'-480px'}}>
        <IoMdCheckmark /> &emsp;
       Testing protocol, evaluation procedure</p>
        <p className='playfair-display' style={{ marginTop: '20px',fontFamily:'Sans-serif',fontSize:'19px',color:'black',marginLeft:'-480px'}}>
        <IoMdCheckmark /> &emsp;
        Special operations hazard assessment</p>
    </div>
  </div>
  )}
</div>
{showDay4Content && (
  <div> 
    <div style={{ marginTop: '10px', textAlign: 'center',fontSize:'30px', fontFamily:'Sans-serif'}}>
    <div>
  <img
    className="slide-icon"
    src={image4}
    alt="img"
    style={{
      height: '300px',
      width: '520px',
      marginLeft: '-350px',
      marginTop: '100px',
      background:'#e6e6fa'
    }}
  />
  <div style={{
    marginLeft: '-500px',
    fontSize: '16px',
    marginTop:'-200px',
    color: 'black',
    fontWeight:'bold',
    fontFamily:'Sans-serif',

  }}>DAY – 4</div>
  <div style={{
        marginLeft: '-490px',
    fontSize: '16px',
    color: 'black',
    fontWeight:'bold',
    fontFamily:'Sans-serif',

  }}>SIMULATED FLIGHT TRAINING</div>
</div>

      <img
        className="slide-icon"
        src={image3}
        alt="img"
        style={{ height: '500px', width: '750px',marginLeft:'515px', marginTop:'-270px'}}
      />
<div className='playfair-display' style={{
        marginLeft: '160px',
        marginTop:'-340px',
    fontSize: '16px',
    color: 'black',
    fontFamily:'Sans-serif',
  }}>•	Pre-flight checks and start-up:Pre-flight procedures, </div>

<div className='playfair-display' style={{
        marginLeft: '65px',
    fontSize: '16px',
    color: 'black',
    fontFamily:'Sans-serif',
  }}>pre-flight inspection and initialization.</div>
    
    <div className='playfair-display'style={{
        marginLeft: '110px',
    fontSize: '16px',
    color: 'black',
    fontFamily:'Sans-serif',
  }}>•	Flight preparation and coordination, pre-flight </div>

<div className='playfair-display' style={{
        marginLeft: '-10px',
    fontSize: '16px',
    color: 'black',
    fontFamily:'Sans-serif',
  }}>planning and coordination.</div>

<div className='playfair-display' style={{
        marginLeft: '150px',
    fontSize: '16px',
    color: 'black',
    fontFamily:'Sans-serif',
  }}>•	Departure and en-route phase, takeoff and in-flight </div>

<div className='playfair-display' style={{
        marginLeft: '-155px',
    fontSize: '16px',
    color: 'black',
    fontFamily:'Sans-serif',
  }}> phase </div>

<div className='playfair-display' style={{
        marginLeft: '12px',
    fontSize: '16px',
    color: 'black',
    fontFamily:'Sans-serif',
  }}>•	Final approach and touchdown </div>
    </div>
<br/><br/><br/><br/>

    <div style={{ marginTop: '10px', textAlign: 'center',fontSize:'30px', }}>
      <div>
    <img
     className="slide-icon"
     src={image4}
     alt="img"
     style={{
      height: '300px',
      width: '520px',
      marginLeft: '-350px',
      marginTop: '100px'
    }}   />
   <div style={{
    marginLeft: '-500px',
    fontSize: '16px',
    marginTop:'-200px',
    color: 'black',
    fontWeight:'bold',
    fontFamily:'Sans-serif',

  }}>DAY – 4</div>
  <div style={{
        marginLeft: '-490px',
    fontSize: '16px',
    color: 'black',
    fontWeight:'bold',
    fontFamily:'Sans-serif',
  }}>HANDS-ON LABORATORY </div>

<div style={{
        marginLeft: '-490px',
    fontSize: '16px',
    color: 'black',
    fontWeight:'bold',
    fontFamily:'Sans-serif',

  }}>TRAINING</div>
</div>

   <img
     className="slide-icon"
     src={image3}
     alt="img"
     style={{ height: '500px', width: '750px',marginLeft:'515px', marginTop:'-305px'}}
     />
<div style={{
        marginLeft: '180px',
        marginTop:'-340px',
    fontSize: '16px',
    color: 'black',
    fontFamily:'Sans-serif',
  }}>•	Subsystem integration, module integration,component  </div>

<div style={{
        marginLeft: '-110px',
    fontSize: '16px',
    color: 'black',
    fontFamily:'Sans-serif',
  }}> integration. </div>

<div style={{
        marginLeft: '175px',
    fontSize: '16px',
    color: 'black',
    fontFamily:'Sans-serif',
  }}>•	Powerplant integration, propulsion system integration.</div>

<div style={{
        marginLeft: '157px',
    fontSize: '16px',
    color: 'black',
    fontFamily:'Sans-serif',
  }}>•	Troubleshooting and resolution, fault diagnosis and </div>

  
<div style={{
        marginLeft: '-120px',
    fontSize: '16px',
    color: 'black',
    fontFamily:'Sans-serif',
  }}>correction.</div>

<div style={{
        marginLeft: '160px',
    fontSize: '16px',
    color: 'black',
    fontFamily:'Sans-serif',
  }}>•	Maintenance and repair procedures, documentation </div>
  <div style={{
        marginLeft: '0px',
    fontSize: '16px',
    color: 'black',
    fontFamily:'Sans-serif',
  }}>of repairs and maintenance. </div>
 </div>
 </div>
  )}
  {showDay5Content && (
    <div style={{ marginTop: '100px', textAlign: 'center' }}>
      <img
        className="slide-icon"
        src={image5}
        alt="img"
        style={{ height: '400px', width: '750px' }}
      />
      <div style={{
        marginLeft: '-100px',
        marginTop:'-355px',
    fontSize: '16px',
    color: 'black',
    fontFamily:'Sans-serif',
  }}>DAY-5 </div>

<div style={{
        marginLeft: '425px',
        marginTop:'-45px',
    fontSize: '16px',
    color: 'black',
    fontFamily:'Sans-serif',
  }}>•	Practical flying with instructor </div>

<div style={{
        marginLeft: '330px',
        marginTop:'-5px',
    fontSize: '16px',
    color: 'black',
    fontFamily:'Sans-serif',
  }}>•	[small category] </div>

<div style={{
        marginLeft: '-100px',
        marginTop:'80px',
    fontSize: '16px',
    color: 'black',
    fontFamily:'Sans-serif',
  }}>DAY-6 </div>

<div style={{
        marginLeft: '445px',
        marginTop:'-45px',
    fontSize: '16px',
    color: 'black',
    fontFamily:'Sans-serif',
  }}>•	Practical flying with an instructor </div>

<div style={{
        marginLeft: '288px',
        marginTop:'-5px',
    fontSize: '16px',
    color: 'black',
    fontFamily:'Sans-serif',
  }}>•	solo flying </div>

<div style={{
        marginLeft: '-100px',
        marginTop:'80px',
    fontSize: '16px',
    color: 'black',
    fontFamily:'Sans-serif',
  }}>DAY-7 </div>

<div style={{
        marginLeft: '440px',
        marginTop:'-45px',
    fontSize: '16px',
    color: 'black',
    fontFamily:'Sans-serif',
  }}>•	Practical flying with an instructor </div>

<div style={{
        marginLeft: '470px',
        marginTop:'-5px',
    fontSize: '16px',
    color: 'black',
    fontFamily:'Sans-serif',
  }}>•	Practical flying test (Small Category) </div>


    </div>
   
  )}
   <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
   


      
</div>
    );
};
export default Training;