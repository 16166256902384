import React, { useState, useEffect, useRef } from 'react';
import Footer from './Footer ';
import image1 from '../svg-sprite/mainslider/logo.jpeg';
import "./Dropdown.css";
import "./contact.css";
import image3 from '../svg-sprite/mainslider/location.png';
import { TbTriangleInvertedFilled } from "react-icons/tb";
import { FaLocationDot } from "react-icons/fa6";
import Navbar from './navbar';
import GoogleMap from './GoogleMap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { TfiTwitter } from "react-icons/tfi";
import { AiOutlineLinkedin } from "react-icons/ai";
import { BsYoutube } from "react-icons/bs";
import Modal from './EnquiryModal'; // Assuming EnquiryModal component is in a file named EnquiryModal.js
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import image2 from '../svg-sprite/mainslider/12.png';


const Contactus = () => {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [courseJoining, setCourseJoining] = useState('');
    const [message, setMessage] = useState('');
    const dropdownTimeoutRef = useRef(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    const handleEnquireClick = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
 

    useEffect(() => {
        return () => {
            clearTimeout(dropdownTimeoutRef.current);
        };
    }, []);

    // Function to handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        // Here you can handle the form submission, e.g., sending data to a server
        console.log({
            fullName,
            email,
            phoneNumber,
            courseJoining,
            message
        });
    };
    
    return (
      <>
          <Navbar />
          <div style={{
              backgroundImage: `url(${image2})`,
              backgroundSize: 'cover',
              minHeight: '900px',
              width: '100%', // Make the background cover the full width
              padding: '20px'
          }}>
            <br/>
            <div style={{ textAlign: 'center' }}>
                    <h1 className='playfair-display' style={{ color: 'green', fontWeight: 'bold', fontFamily:'Sans-serif',fontSize: '1.0em' }}>NEED A HAND?</h1>
                    <p className='playfair-display' style={{ color: 'green', fontWeight: 'bold',fontFamily:'Sans-serif', fontSize: '1.0em' }}>SEND A REQUEST FOR CALL BACK</p>
                </div>
              <form onSubmit={handleSubmit} style={{ maxWidth: '400px', margin: '0 auto' }}>
                  <div style={{ marginBottom: '10px' }}>
                      <label className='playfair-display' htmlFor="fullName">Your Name:</label>
                      <input
                          type="text"
                          id="fullName"
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                          required
                          style={{ width: '100%' }}
                      />
                  </div>
                  <div style={{ marginBottom: '10px' }}>
                      <label className='playfair-display' htmlFor="email">Email Address:</label>
                      <input
                          type="email"
                          id="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                          style={{ width: '100%' }}
                      />
                  </div>
                  <div style={{ marginBottom: '10px' }}>
                      <label className='playfair-display' htmlFor="phoneNumber">Phone Number:</label>
                      <input
                          type="tel"
                          id="phoneNumber"
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          required
                          style={{ width: '100%' }}
                      />
                  </div>
                  <div style={{ marginBottom: '10px' }}>
                      <label className='playfair-display' htmlFor="message">Message:</label>
                      <textarea
                          id="message"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          style={{ width: '100%' }}
                      />
                  </div>
                  <button className='playfair-display'
                      type="submit"
                      style={{
                          width: '100%',
                          padding: '10px',
                          borderRadius: '5px',
                          backgroundColor: '#013220',
                          color: 'white',
                          border: 'none',
                          cursor: 'pointer'
                      }}
                  >
                      Submit
                  </button>
              </form>

              <div className="address-details" style={{ marginTop: '20px', textAlign: 'right' }}>
                  <span className='playfair-display' style={{ color: 'green', fontWeight: 'bold', fontSize: '1.0em' }}>Address</span>
                  <p className='playfair-display'>
                      PIEFLY AEROSPACE PRIVATE LIMITED<br />
                      # 70 B block, 1st floor, Anandnagar, Mysore, 570022, Karnataka<br />
                      MYSORE, 570006
                  </p>
              </div>

              <div className="cl-details" style={{ marginTop: '20px', textAlign: 'right' }}>
                  <span className='playfair-display' style={{ color: 'green', fontWeight: 'bold', fontSize: '1.0em' }}>CALL US</span>
                  <p>9606931676</p>

<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                  <p>
                      <a className='playfair-display' href={`https://www.google.com/maps/place/JSS+Technical+Institute+Campus,+Mysuru,+Karnataka+570006`} target="_blank" rel="noopener noreferrer" style={{ color: 'blue', display: 'flex', alignItems: 'center', textDecoration: 'none', justifyContent: 'right' }}>
                          <FaLocationDot /> VIEW ON GOOGLE MAP
                      </a>
                  </p>
                  {/* <br/><br/> */}
                  <img src={image3} alt="Location" style={{ height: '100px', width: '250px', float: 'right', margin: '0 50px' }} />
                  <GoogleMap />
              </div>
          </div>

<br/>
<br/>
 <div>

 <footer style={{ fontSize: '10px',fontFamily:'Sans-serif', padding: '38px', backgroundColor: '#000068', color: '#000', width: '1500px', marginLeft: '150px' }}>
    <div className="d-flex justify-content-between" style={{ marginLeft: '90px',fontFamily:'Sans-serif',  marginRight: '90px' }}>
      <div className="col-md-12 col-lg-4 col-mobile-down">
        <div className="mobile-collapse">
          <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white',fontFamily:'Sans-serif',  }}>Contact Information</h4>
          <div className="pt-collapse-content playfair-display" style={{ color: 'white',fontFamily:'Sans-serif',  fontSize: '12px' }}>
            <address className='playfair-display'>
              <span># 70 </span>B block <span>1st</span> floor Anandnagar Mysore<span> 570022</span> Karnataka<br />
              <a className='playfair-display' href="mailto:pieflyaerospace@gmail.com" style={{ color: 'white',fontFamily:'Sans-serif',  fontSize: '12px' }}>Info@pieflyaerospace.com</a>
            </address>
          </div>
        </div>
      </div>
  
      <div className="col-md-3 col-lg-2">
        <div className="mobile-collapse" style={{ maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}>
          <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white',fontFamily:'Sans-serif',  textAlign: 'center' }}>Quick Links</h4>
          <div className="pt-collapse-content" style={{ color: 'white', fontSize: '12px' }}>
            <div className="oval-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px', padding: '10px' }}>
              <div className="oval-box" style={{ width: '120px',fontFamily:'Sans-serif',  height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black',fontFamily:'Sans-serif',  textAlign: 'center' }}><span className='playfair-display'>Home</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', fontFamily:'Sans-serif', textAlign: 'center' }}><span className='playfair-display'>Training Program</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black',fontFamily:'Sans-serif',  textAlign: 'center' }}><span className='playfair-display'>Enroll</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black',fontFamily:'Sans-serif',  textAlign: 'center' }}><span className='playfair-display'>Shop</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black',fontFamily:'Sans-serif',  textAlign: 'center' }}><span className='playfair-display'>Contacts</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black',fontFamily:'Sans-serif',  textAlign: 'center' }}><span className='playfair-display'>Elements</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div className="col-md-3 col-lg-2">
        <div className="mobile-collapse" style={{ maxWidth: '300px', marginLeft: 'auto', marginRight: 'auto' }}>
          <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white', fontFamily:'Sans-serif', textAlign: 'center' }}>Useful Links</h4>
          <div className="pt-collapse-content" style={{ color: 'white', fontSize: '12px' }}>
            <div className="oval-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px', padding: '10px' }}>
              <div className="oval-box" style={{ width: '120px',fontFamily:'Sans-serif',  height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', fontFamily:'Sans-serif', textAlign: 'center' }}><span className='playfair-display'>Products Documents</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black',fontFamily:'Sans-serif',  textAlign: 'center' }}><span className='playfair-display'>Services Documents</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black',fontFamily:'Sans-serif',  textAlign: 'center' }}><span className='playfair-display'>Products</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', fontFamily:'Sans-serif', textAlign: 'center' }}><span className='playfair-display'>Blog and News</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div className="col-md-3 col-lg-2">
        <div className="mobile-collapse" style={{ maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}>
          <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white', fontFamily:'Sans-serif', textAlign: 'center' }}>Main Menu</h4>
          <div className="pt-collapse-content" style={{ color: 'white', fontSize: '12px' }}>
            <div className="oval-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px', padding: '10px' }}>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black',fontFamily:'Sans-serif',  textAlign: 'center' }}><span className='playfair-display'>About Us</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black',fontFamily:'Sans-serif',  textAlign: 'center' }}><span className='playfair-display'>Gallery</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black',fontFamily:'Sans-serif',  textAlign: 'center' }}><span className='playfair-display'>Projects</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black',fontFamily:'Sans-serif',  textAlign: 'center' }}><span className='playfair-display'>Contacts</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        <div className="footer-custom">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-4">
                {/* <div className="copyright"style={{color: 'white',fontSize:'12px' }}>
                  &copy; 2019 Quadron. All rights reserved.
                </div> */}
              </div>
            
             <div className='social-icons' style={{marginLeft:'600px',marginTop:'-20px'}}>
             <a className='social-icon-link whatsapp' href='https://wa.me/yourwhatsapplink' target='_blank' aria-label='WhatsApp' style={{ marginLeft: '-40px' }}>
  <FontAwesomeIcon icon={faWhatsapp} />
  </a>
    <a className='social-icon-link facebook' href='/' target='_blank' aria-label='Facebook'>
      <FontAwesomeIcon icon={faFacebookF} />
    </a>
    <a className='social-icon-link instagram' href='https://www.instagram.com/pieflyofficial?igsh=bmU0emdnbWs2aGIw' target='_blank' aria-label='Instagram' style={{marginLeft:'-40px'}}>
      <FontAwesomeIcon icon={faInstagram} />
    </a>
    <a className='social-icon-link twitter' href='https://x.com/pieflyofficial?t=RHOzsk0Vc47uIvKSCc5XVA&s=09' target='_blank' aria-label='Twitter' style={{marginLeft:'-40px'}}>
    <TfiTwitter />
    </a>
    <a className='social-icon-link linkedin' href='https://in.linkedin.com/company/piefly-r-d-private-limited' target='_blank' aria-label='LinkedIn' style={{marginLeft:'-40px'}}>
    <AiOutlineLinkedin />
    </a>
    <a className='social-icon-link Youtube' href='https://youtube.com/@piefly4302?si=Qecbd2nJ80cYx-Hk' target='_blank' aria-label='Youtube' style={{marginLeft:'-40px'}}>
    <BsYoutube />  </a>
  </div>
  
                </div>
              </div>
            </div>
          
      </footer>


 </div>
 

 </>
  );
};

export default Contactus;
