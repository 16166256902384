import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import image from '../svg-sprite/mainslider/logo.jpeg';
import Sidebar from './Sidebar';
import Dropdown from './Dropdown';
import Dropdown1 from './Dropdown1';
import "./Dropdown.css";
import Rpto from './rpto';

const Navbar1 = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [visible, setvisible] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [dropdown1, setDropdown1] = useState(false);
  const dropdownTimeoutRef = useRef(null);

  // Event handlers for dropdown
  const handleMouseEnter = () => {
    clearTimeout(dropdownTimeoutRef.current);
    setDropdown(true);
    setDropdown1(false); // Close the other dropdown
  };

  const handleMouseLeave = () => {
    clearTimeout(dropdownTimeoutRef.current);
    dropdownTimeoutRef.current = setTimeout(() => {
      setDropdown(false);
    }, 500);
  };

  const handleProductsMouseEnter = (e) => {
    e.target.style.borderColor = 'white';
    setDropdown(true);
  };

  const handleProductsMouseLeave = (e) => {
    e.target.style.borderColor = 'transparent';
    setDropdown(false);
  };

  const handleServicesMouseEnter = (e) => {
    e.target.style.borderColor = 'white';
    setDropdown1(true);
  };

  const handleServicesMouseLeave = (e) => {
    e.target.style.borderColor = 'transparent';
    setDropdown1(false);
  };

  const handleRPTOMouseEnter = (e) => {
    e.target.style.borderColor = 'white';
  };

  const handleRPTOMouseLeave = (e) => {
    e.target.style.borderColor = ' transparent';
  };

  const handleAboutUsMouseEnter = (e) => {
    e.target.style.borderColor = ' white';
   
  };

  const handleAboutUsMouseLeave = (e) => {
    e.target.style.borderColor = 'transparent';
    
  };

  const handleNewsMouseEnter = (e) => {
    e.target.style.borderColor = ' white';
   
  };

  const handleNewsMouseLeave = (e) => {
    e.target.style.borderColor = 'transparent';
    
  };

  const handleArticlesMouseEnter = (e) => {
    e.target.style.borderColor = ' white';
   
  };

  const handleArticlesMouseLeave = (e) => {
    e.target.style.borderColor = 'transparent';
    
  };

  const handleContactUsMouseEnter = (e) => {
    e.target.style.borderColor = ' white';
   
  };

  const handleContactUsMouseLeave = (e) => {
    e.target.style.borderColor = 'transparent';
    
  };

  const handleLogin = () => {
    // Assume successful login, set isLoggedIn to true and set user data
    setLoggedIn(true);
    setUserData(/* user data object */);
  };

  // Event handlers for dropdown1
  const handleMouseEnter1 = () => {
    clearTimeout(dropdownTimeoutRef.current);
    setDropdown1(true);
    setDropdown(false); // Close the other dropdown
  };

  const handleMouseLeave1 = () => {
    clearTimeout(dropdownTimeoutRef.current);
    dropdownTimeoutRef.current = setTimeout(() => {
      setDropdown1(false);
    }, 500);
  };

  const handleDropdownEnter = () => {
    clearTimeout(dropdownTimeoutRef.current);
  };

  const handleDropdownLeave = () => {
    clearTimeout(dropdownTimeoutRef.current);
    setDropdown(false);
  };

  useEffect(() => {
    return () => {
      clearTimeout(dropdownTimeoutRef.current);
    };
  }, []);

  return (
    <div>
      &emsp;&emsp;&emsp;
    {/* Start Header */}
    <header id="top-bar" style={{ background: 'white' }}>
      <div className="container-fluid">
        <div className="row justify-content-between no-gutters">
          <div className="col-auto side-col d-flex align-items-center text-nowrap">
           
            <img className="top-bar__logo" src={image} alt='logo' style={{height: '100px', width: '150px', marginLeft:'20px'}}/>
            &emsp;&emsp;
<br></br>
<br></br>
&emsp;&emsp;
&emsp;

            <div className="col-auto" style={{ fontSize: 'small', color: '#333333', textAlign: 'center' }}>
<span style={{ fontWeight: 'bold' }}>PIEFLY AVIATION REMOTE </span> <br />
PILOT TRAINING ORGANISATION
</div>
&emsp;&emsp;&emsp;
&emsp;


<div className="col-auto" style={{ fontSize: 'small', color: '#333333', textAlign: 'center' }}>
  <span style={{ fontWeight: 'bold' }}>CENTRE FOR AVIATION </span> <br />
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ fill: '#4285F4' }}>
      <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
    </svg>
    <span style={{ marginLeft: '5px' }}>SJCE CAMPUS [STEP] MYSORE</span>
  </div>
</div>

&emsp;
&emsp;&emsp;&emsp;
<div className="col-auto" style={{ fontSize: 'small', color: '#333333', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ fill: '#4285F4', marginRight: '5px' }}>
  <path d="M20 3H17V1H15V3H9V1H7V3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H20C21.1 21 22 20.1 22 19V5C22 3.9 21.1 3 20 3M20 19H4V8H20V19M7 10H9V12H7V10M11 10H13V12H11V10M15 10H17V12H15V10Z"/>
</svg>
<span style={{ display: 'inline-flex', alignItems: 'center' }}>
  <span style={{ fontWeight: 'bold', marginRight: '5px' }}>9:00 AM-6:00 PM </span>
  {/* <span style={{ marginLeft: '5px' }}>Monday-Saturday</span> */}
</span>
</div>
&emsp;
&emsp;&emsp;&emsp;
<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ fill: 'none', stroke: '#4285F4', strokeWidth: '2', strokeLinecap: 'round', strokeLinejoin: 'round', marginRight: '5px' }}>
  <path d="M22 5.1C21.04 5.59 20 5.882 18.91 6l-11.82-.02C5.098 5.858 4.062 5.573 3 5.1V19c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5.1zM12 14l-8-5 8-5 8 5-8 5z"/>
</svg>
<div style={{ marginLeft: '5px' }}>piefly@gmail.com</div>
</div>
</div>
 {/* Add login button */}
 <div className="col-auto" style={{  textAlign: 'center', marginTop:'-5px', marginRight:'80px' }}>
 <Link to="/login" className="btn-cart" onClick={handleLogin} style={{ color: 'white' }}>
  <button style={{ background: '#0073cf',fontSize: '16px', color: 'white', padding: '10px 30px', borderRadius: '5px', cursor: 'pointer' }}>
                Login</button>
              </Link>
            </div>
            {/* End login button */}
           </div>
          </div>
          </header>

      {isNavOpen && (
        <div className="nav-dropdown-content" style={{ background: 'blue', color: 'white' }}>
          <Link to='/enrollmentrequest'>Enrollment Request</Link>
          <br />
          <br />
          <Link to='/traceability'>Traceability</Link>
        </div>
      )}

      <Sidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(!isSidebarOpen)} />
      &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;

      <div
        style={{
          background: '#0073cf',
          color: 'white',
          padding: '20px',
          marginTop: '100px',
          position: 'fixed',
          top: 0,
          
          width: '100%',
          zIndex: 1000,
        }}
      >
        <ul
          style={{
            display: 'flex',
            alignItems: 'center',
            listStyle: 'none',
            padding: 0,
            marginLeft:'90px',
          }}
        >
          
<li style={{ marginRight: '10px', marginLeft: '-5px' }}>
  <Link to="/" style={{ color: 'white', textDecoration: 'none', position: 'relative' }}>
    <span
      style={{
        position: 'relative',
        borderBottom: '4px solid transparent',
        paddingBottom: '21px',
        marginRight: '5px',
        transition: 'border-color 0.3s ease',
      }}
      onMouseOver={(e) => e.target.style.borderColor = 'white'}
      onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
    >
      HOME
    </span>
  </Link>
</li>


          &emsp;&emsp;
          &emsp;&emsp;
          <a
          href='#'
          style={{
            color: 'white',
            textDecoration: 'none',
            position: 'relative',
          }}
          onMouseEnter={handleProductsMouseEnter}
          onMouseLeave={handleProductsMouseLeave}
        >
          <span
            style={{
              position: 'relative',
              borderBottom: '4px solid transparent',
              paddingBottom: '21px',
              marginRight: '5px',
              transition: 'border-color 0.3s ease',
            }}
            onMouseOver={(e) => e.target.style.borderColor = 'white'}
      onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
          >
            PRODUCTS
            {dropdown && <Dropdown />}
          </span>
        </a>
          &emsp;&emsp;
          &emsp;&emsp;
&emsp;&emsp;
<a
          href='#'
          style={{
            color: 'white',
            textDecoration: 'none',
            position: 'relative',
          }}
          onMouseEnter={handleServicesMouseEnter}
          onMouseLeave={handleServicesMouseLeave}
        >
          <span
            style={{
              position: 'relative',
              borderBottom: '4px solid transparent',
              paddingBottom: '21px',
              marginRight: '5px',
              transition: 'border-color 0.3s ease',
            }}
            onMouseOver={(e) => e.target.style.borderColor = 'white'}
      onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
          >
            SERVICES
            {dropdown1 && <Dropdown1 />}
          </span>
        </a>
          &emsp;&emsp;
          &emsp;&emsp;
          &emsp;&emsp;
          <li>
  <a
    href="/#/rpto"
    style={{
      color: 'white',
      textDecoration: 'none',
      position: 'relative',
    }}
    target="_blank" // Add this line to open the link in a new window
    onMouseEnter={handleRPTOMouseEnter}
    onMouseLeave={handleRPTOMouseLeave}
  >
    <span
      style={{
        position: 'relative',
        borderBottom: '4px solid transparent',
        paddingBottom: '21px',
        marginRight: '5px',
        transition: 'border-color 0.3s ease',
      }}
      onMouseOver={(e) => e.target.style.borderColor = 'white'}
      onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
    >
      RPTO
    </span>
  </a>
</li>

  &emsp; &emsp; &emsp; &emsp;&emsp;
  <li>
  <a
        href="/#/about"
        style={{
          color: 'white',
          textDecoration: 'none',
          position: 'relative',
        }}
        onMouseEnter={handleAboutUsMouseEnter}
        onMouseLeave={handleAboutUsMouseLeave}
      >
        <span
          style={{
            position: 'relative',
            borderBottom: '4px solid transparent',
            paddingBottom: '21px',
            marginRight: '5px',
            transition: 'border-color 0.3s ease',
          }}
          onMouseOver={(e) => e.target.style.borderColor = 'white'}
          onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
        >
          ABOUT US
          
        </span>
      </a>
    <Link to="/about" style={{ color: 'white', textDecoration: 'none' }}></Link>
  </li>
  &emsp; &emsp; &emsp; &emsp;&emsp;
  <li>
  <a
        href="/#/news"
        style={{
          color: 'white',
          textDecoration: 'none',
          position: 'relative',
        }}
        onMouseEnter={handleNewsMouseEnter}
        onMouseLeave={handleNewsMouseLeave}
      >
        <span
          style={{
            position: 'relative',
            borderBottom: '4px solid transparent',
            paddingBottom: '21px',
            marginRight: '5px',
            transition: 'border-color 0.3s ease',
          }}
          onMouseOver={(e) => e.target.style.borderColor = 'white'}
          onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
        >
          NEWS
          
        </span>
      </a>
    <Link to="/about" style={{ color: 'white', textDecoration: 'none' }}></Link>
  </li>
  &emsp; &emsp; &emsp; &emsp;&emsp;
  <li>
  <a
        href="/#/articles"
        style={{
          color: 'white',
          textDecoration: 'none',
          position: 'relative',
        }}
        onMouseEnter={handleArticlesMouseEnter}
        onMouseLeave={handleArticlesMouseLeave}
      >
        <span
          style={{
            position: 'relative',
            borderBottom: '4px solid transparent',
            paddingBottom: '21px',
            marginRight: '5px',
            transition: 'border-color 0.3s ease',
          }}
          onMouseOver={(e) => e.target.style.borderColor = 'white'}
          onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
        >
          ARTICLES
          
        </span>
      </a>
    <Link to="/about" style={{ color: 'white', textDecoration: 'none' }}></Link>
  </li>
  &emsp; &emsp; &emsp; &emsp;&emsp;
  <li>
  <a
        href="/#/contactus"
        style={{
          color: 'white',
          textDecoration: 'none',
          position: 'relative',
        }}
        onMouseEnter={handleContactUsMouseEnter}
        onMouseLeave={handleContactUsMouseLeave}
      >
        <span
          style={{
            position: 'relative',
            borderBottom: '4px solid transparent',
            paddingBottom: '21px',
            
            transition: 'border-color 0.3s ease',
          }}
          onMouseOver={(e) => e.target.style.borderColor = 'white'}
          onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
        >
         CONTACT US
          
        </span>
      </a>
    <Link to="/contactus" style={{ color: 'white', textDecoration: 'none' }}></Link>
  </li>
</ul>                       
      </div>
    </div>
  );
};

export default Navbar1;
