import React from 'react';
import { Link } from 'react-router-dom';

const ProductListing = () => {
  const sectionStyle = {
    backgroundImage: `url("file:///C:/Users/MeghanaM/git/Quadrone/src/svg-sprite/mainslider/main-slider-01-wrapper.png")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <section className="section" style={sectionStyle}>
      <div className="container">
        <div className="filters-options">
          <div className="filters-options__title">Products</div>
          <div className="filters-options__value">
            <div className="filters-options__mobile">
              <a className="filters-aside-btn" id="js-toggle-aside" href="#">
                <svg>
                  <use xlinkHref="#filter"></use>
                </svg>
              </a>
            </div>
            <div className="filters-options__sort">
              <select className="js-init-select select-custom-01 no-outline">
                <option>Sort by default</option>
                <option>Sort by default 01</option>
                <option>Sort by default 02</option>
                <option>Sort by default 03</option>
              </select>
              <span className="arrow-down-icon">&#9660;</span>
            </div>
            <div className="filters-options__quantity">
              <div className="wrapper-select-custom-01">
                <div className="select-caption">Show:</div>
                <select className="js-init-select select-custom-01 no-outline">
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <span className="arrow-down-icon">&#9660;</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
            <br/>
          {/* Categories */}
          <div className="block-aside">
            <h3 className="block-aside__title">Categories</h3>
            <div className="block-aside__content">
              <nav className="menu-aside">
              <ul>
											<li class="active"><a href="#">
												Mavic	<span class="value">14</span>
											</a></li>
											<li><a href="#">
												Osmo	<span class="value">6</span>
											</a></li>
											<li><a href="#">
												Spark	<span class="value">12</span>
											</a></li>
											<li><a href="#">
												phantom	<span class="value">7</span>
											</a></li>
											<li><a href="#">
												Dji googles	<span class="value">4</span>
											</a></li>
											<li><a href="#">
												inspire	<span class="value">27</span>
											</a></li>
											<li><a href="#">
												Ronin	<span class="value">12</span>
											</a></li>
											<li><a href="#">
												Industrial	<span class="value">0</span>
											</a></li>
										</ul>
              </nav>
            </div>
          </div>

          {/* Compatibility */}
          <div className="collapse-aside">
            <h3 className="collapse-aside__title">Compatibility</h3>
            <div className="collapse-aside__content">
              <ul className="list-checkbox">
                <li>
                  <div className="checkbox-group02">
                    <input type="checkbox" id="asideCheckBox01" name="checkbox" defaultChecked />
                    <label htmlFor="asideCheckBox01">
                      <span className="check"></span>
                      <span className="box"></span>
                      Osmo
                    </label>
                  </div>
                </li>
                <li>
                  <div className="checkbox-group02">
                    <input type="checkbox" id="asideCheckBox02" name="checkbox" defaultChecked />
                    <label htmlFor="asideCheckBox02">
                      <span className="check"></span>
                      <span className="box"></span>
                      Spark
                    </label>
                  </div>
                </li>
                <li>
                  <div className="checkbox-group02">
                    <input type="checkbox" id="asideCheckBox03" name="checkbox" />
                    <label htmlFor="asideCheckBox03">
                      <span className="check"></span>
                      <span className="box"></span>
                      Phantom
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          {/* Colors */}
          <div className="collapse-aside">
            <h3 className="collapse-aside__title">Colors</h3>
            <div className="collapse-aside__content">
              <ul className="list-color-switcher js-color-switcher">
                <li><a className="color-bg-01 color-border" href="#"></a></li>
                <li><a className="color-bg-02" href="#"></a></li>
                <li><a className="color-bg-03 active" href="#"></a></li>
                <li><a className="color-bg-04" href="#"></a></li>
                <li><a className="color-bg-05 color-border" href="#"></a></li>
              </ul>
            </div>
          </div>

          {/* Tags */}
          <div className="collapse-aside">
            <h3 className="collapse-aside__title">Tags</h3>
            <div className="collapse-aside__content">
              <ul className="list-border">
                <li><a href="#">DRONE</a></li>
                <li><a href="#">DRONEPHOTOGRAPHY</a></li>
                <li><a href="#">MAVICPRO</a></li>
                <li><a href="#">DRONEFLY</a></li>
                <li><a href="#">FLYMORE</a></li>
                <li><a href="#">KIT</a></li>
                <li><a href="#">CONTROLLER</a></li>
              </ul>
            </div>
          </div>

          {/* Control Buttons */}
          <div className="control-buttons">
            <a href="#" className="btn">
              APPLY
            </a>
            <a href="#" className="link-default size-lg">
              CLEAR ALL
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductListing;
