import React, { useState, useEffect, useRef } from 'react';
import image1 from '../svg-sprite/mainslider/pieflylogo.png';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import Sidebar from './Sidebar';
import Footer from './Footer ';
import { MdCall } from "react-icons/md";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import './Footer.css'
import Dropdown3 from './Dropdown3';
import Modal from './DocumentsModal'; // Assuming EnquiryModal component is in a file named EnquiryModal.js
import { TfiTwitter } from "react-icons/tfi";
import { AiOutlineLinkedin } from "react-icons/ai";

import { BsYoutube } from "react-icons/bs";


function WorkshopEnroll() {
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [userData, setUserData] = useState(null);
    const dropdownTimeoutRef = useRef(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [dropdown3, setDropdown3] = useState(false);


    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleEnquireClick = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
    const handleEnrollMouseEnter = (e) => {
      e.target.style.borderColor = 'white';
      e.currentTarget.style.transform = 'scale(1.05)'; // Zoom in effect
      setDropdown3(true);
    };
  
    const handleEnrollMouseLeave = (e) => {
      e.target.style.borderColor = 'transparent';
      e.currentTarget.style.transform = 'scale(1)'; // Zoom out effect
  
      setDropdown3(false);
    };

    // Apply styles based on hover state
    const liStyles = {
      transition: 'transform 0.3s ease', // Add transition for smooth effect
      transform: isHovered ? 'scale(1.05)' : 'scale(1)', // Zoom in/out effect
    };
  
  
    const handleRPTOMouseEnter = (e) => {
      e.target.style.borderColor = 'white';
    };
  
    const handleRPTOMouseLeave = (e) => {
      e.target.style.borderColor = ' transparent';
    };
  
    const handleAboutUsMouseEnter = (e) => {
      e.target.style.borderColor = ' white';
     
    };
  
    const handleAboutUsMouseLeave = (e) => {
      e.target.style.borderColor = 'transparent';
      
    };
  
    const handleNewsMouseEnter = (e) => {
      e.target.style.borderColor = ' white';
     
    };
  
    const handleNewsMouseLeave = (e) => {
      e.target.style.borderColor = 'transparent';
      
    };
  
    const handleArticlesMouseEnter = (e) => {
      e.target.style.borderColor = ' white';
     
    };
  
    const handleArticlesMouseLeave = (e) => {
      e.target.style.borderColor = 'transparent';
      
    };
  
    const handleContactUsMouseEnter = (e) => {
      e.target.style.borderColor = ' white';
     
    };
  
    const handleContactUsMouseLeave = (e) => {
      e.target.style.borderColor = 'transparent';
      
    };
  
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleMouseEnter2 = () => {
      setDropdownOpen(true);
    };
  
    const handleMouseLeave2 = () => {
      setDropdownOpen(false);
    };
  

    const handleLogin = () => {
      // Assume successful login, set isLoggedIn to true and set user data
      setLoggedIn(true);
      setUserData(/* user data object */);
    };

    const [isHoveredGeneral, setIsHoveredGeneral] = useState(false);
  const [isHoveredStudents, setIsHoveredStudents] = useState(false);

  const handleMouseEnterGeneral = () => {
    setIsHoveredGeneral(true);
  };

  const handleMouseLeaveGeneral = () => {
    setIsHoveredGeneral(false);
  };

  const handleMouseEnterStudents = () => {
    setIsHoveredStudents(true);
  };

  const handleMouseLeaveStudents = () => {
    setIsHoveredStudents(false);
  };

  const [scrolledUp, setScrolledUp] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolledUp = window.scrollY === 0;
      setScrolledUp(isScrolledUp);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(dropdownTimeoutRef.current);

    };
  }, []);


  return (
    <>
    <div>
      &emsp;&emsp;&emsp;
    {/* Start Header */}
    <header id="top-bar" style={{ background: '#000068' }}>
      <div className="container-fluid">
        <div className="row justify-content-between no-gutters">
        <div className="col-auto side-col d-flex align-items-center text-nowrap" style={{ margin: '0 auto' }}>
           
        <img className="top-bar__logo" src={image1} alt='logo' style={{ height: '150px', width: '150px', marginLeft: '-10px',marginTop:'10px' }} />
            &emsp;&emsp;
<br></br>
<br></br>
&emsp;

<div className="col-auto" style={{ color: 'white', textAlign: 'center' }}>
  <span className='playfair-display' style={{ fontWeight: 'bold', fontSize: '1.1em',fontFamily:'Sans-serif'}}>PIEFLY AVIATION REMOTE </span> <br />
  <span className='playfair-display' style={{ fontWeight: 'bold', fontSize: '1.1em',fontFamily:'Sans-serif'}}>PILOT TRAINING ORGANISATION</span>
</div>

&emsp;
&emsp;


<div className="col-auto" style={{ fontSize: 'small', color: 'white', textAlign: 'center' }}>
<span className='playfair-display' style={{ fontWeight: 'bold',fontFamily:'Sans-serif',
 }}>CENTRE FOR AVIATION </span> <br />
<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center',fontFamily:'Sans-serif',
 }}>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ fill: 'white' }}>
    <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
  </svg>
  <span className='playfair-display' style={{ marginLeft: '5px' }}>SJCE CAMPUS [STEP] MYSORE </span>
</div>
</div>

&emsp;
&emsp;
<div className="col-auto" style={{ fontSize: 'small', color: 'white', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ fill: 'white', marginRight: '5px' }}>
  <path d="M20 3H17V1H15V3H9V1H7V3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H20C21.1 21 22 20.1 22 19V5C22 3.9 21.1 3 20 3M20 19H4V8H20V19M7 10H9V12H7V10M11 10H13V12H11V10M15 10H17V12H15V10Z"/>
</svg>
<span style={{ display: 'inline-flex', alignItems: 'center',fontFamily:'Sans-serif',
 }}>
  <span style={{ fontWeight: 'bold', marginRight: '5px',fontFamily:'Sans-serif',
 }}>9:00 AM-6:00 PM </span>
  {/* <span style={{ marginLeft: '5px' }}>Monday-Saturday</span> */}
</span>
</div>
&emsp;
&emsp;
<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
<div style={{ fontSize: '24px', color: 'white' }}>
      <MdCall />
    </div>
    </div>
    &emsp;
    <div>
      <div style={{ marginRight: '50px', color:'white'  }}>91-96069 31676</div>
      <div className='playfair-display' style={{ marginRight: '7px', color:'white' ,fontSize:'14px',fontFamily:'Sans-serif',
}}>Piefly.rptotraining@gmail.com</div>
    </div>

&emsp;  
 
<div className="col-auto">
  <Link to="/login" onMouseEnter={(e) => e.currentTarget.querySelector('button').style.transform = 'scale(1.05)'} onMouseLeave={(e) => e.currentTarget.querySelector('button').style.transform = 'scale(1)'}>
    <button className='playfair-display' style={{ background: '#B6D0E2',fontFamily:'Sans-serif',
 fontSize: '12px', color: 'black', padding: '0px 30px', fontWeight: 'bold', borderRadius: '5px', cursor: 'pointer', transition: 'transform 0.3s' }}>
      Login
    </button>
  </Link>
</div>
            </div>
            {/* End login button */}
           </div>
          </div>
          
          </header>

   

          <Sidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(!isSidebarOpen)} />
{/* Add some spacing between Sidebar and Navbar */}
<div style={{ width: '20px' }}></div>

<div
      style={{
        background:  'white', // Change background color based on scroll position
        padding: '10px',
        marginTop: '100px',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center', 
        fontFamily:'Sans-serif',// Center navbar items horizontally
      }}
    >
      <ul
        style={{
          display: 'flex',
          alignItems: 'center',
          listStyle: 'none',
          padding: 0,
        }}
      >
<li style={{ marginRight: '10px', marginLeft: '50px' }} onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'} onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}>
        <a
        href="/#/rpto"
        style={{
          color: 'black',
          textDecoration: 'none',
          position: 'relative',
          fontWeight:'bold',
          fontFamily:'Sans-serif',
        }}
        onMouseEnter={handleRPTOMouseEnter}
        onMouseLeave={handleRPTOMouseLeave}
      >
        <span
          style={{
            position: 'relative',
            borderBottom: '4px solid transparent',
            paddingBottom: '12px',
            marginRight: '5px',
            transition: 'border-color 0.3s ease',
            fontFamily:'Sans-serif',
            fontSize:'15px'
          }}
          onMouseOver={(e) => e.target.style.borderColor = 'black'}
    onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
        >
         HOME
        
        </span>
      </a>
  <Link to="/#/rpto" style={{ color: 'white', textDecoration: 'none' }}></Link>
</li>

&emsp; &emsp; 
  &emsp;
  <li  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'} onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}>
          <a
          href="/#/training"
          style={{
            color: 'black',
            textDecoration: 'none',
            position: 'relative',
            fontWeight:'bold',
            fontFamily:'Sans-serif',
          }}
          onMouseEnter={handleRPTOMouseEnter}
          onMouseLeave={handleRPTOMouseLeave}
        >
          <span
           style={{
            position: 'relative',
            borderBottom: '4px solid transparent',
            paddingBottom: '12px',
            marginRight: '5px',
            transition: 'border-color 0.3s ease',
            fontFamily:'Sans-serif',
            fontSize:'15px',
            fontFamily:'Sans-serif',
            }}
            onMouseOver={(e) => e.target.style.borderColor = 'black'}
      onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
          >
           TRAINING PROGRAM
          
          </span>
        </a>
    <Link to="/#/training" style={{ color: 'white', textDecoration: 'none' }}></Link>
  </li>
  &emsp; &emsp; &emsp;
  <li  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'} onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}>
  <a
        href="/#/aboutt"
        style={{
          color: 'black',
          textDecoration: 'none',
          position: 'relative',
          fontWeight:'bold'
        }}
        onMouseEnter={handleAboutUsMouseEnter}
        onMouseLeave={handleAboutUsMouseLeave}
      >
        <span
          style={{
            position: 'relative',
            borderBottom: '4px solid transparent',
            paddingBottom: '12px',
            marginRight: '5px',
            transition: 'border-color 0.3s ease',
            fontFamily:'Sans-serif',
                fontSize:'15px'
          }}
          onMouseOver={(e) => e.target.style.borderColor = 'black'}
          onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
        >
          ABOUT US
          
        </span>
      </a>
    <Link to="/aboutt" style={{ color: 'white', textDecoration: 'none' }}></Link>
  </li>
  &emsp; &emsp; &emsp;
 
  <li  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'} onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}>
  <a
        
        style={{
          color: 'black',
          textDecoration: 'none',
          position: 'relative',
          fontWeight:'bold',
          cursor:'pointer'
        }}
        onMouseEnter={handleEnrollMouseEnter}
        onMouseLeave={handleEnrollMouseLeave}
      >
        <span
          style={{
            position: 'relative',
            borderBottom: '4px solid transparent',
            paddingBottom: '12px',
            marginRight: '5px',
            transition: 'border-color 0.3s ease',
            fontFamily:'Sans-serif',
                fontSize:'15x'
          }}
          onMouseOver={(e) => e.target.style.borderColor = 'black'}
          onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
        >
          ENROLL
          {dropdown3 && <Dropdown3 />}

        </span>
      </a>
  </li>
  &emsp; &emsp; &emsp; 
  &emsp;
  
  <li  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'} onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}>
  <a
        href="/#/studentlogin"
        style={{
          color: 'black',
          textDecoration: 'none',
          position: 'relative',
          fontWeight:'bold'
        }}
        onMouseEnter={handleArticlesMouseEnter}
        onMouseLeave={handleArticlesMouseLeave}
      >
        <span
          style={{
            position: 'relative',
            borderBottom: '4px solid transparent',
            paddingBottom: '12px',
            
            transition: 'border-color 0.3s ease',
            fontFamily:'Sans-serif',
                fontSize:'15px'
          }}
          onMouseOver={(e) => e.target.style.borderColor = 'black'}
          onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
        >
          STUDENT LOGIN
          
        </span>
      </a>
    <Link to="/studentlogin" style={{ color: 'white', textDecoration: 'none' }}></Link>
  </li>
  &emsp; &emsp; &emsp; &emsp;
 
  <li style={liStyles}>
  <a
    style={{
      color: 'black',
      textDecoration: 'none',
      position: 'relative',
      fontWeight: 'bold',
      marginRight: '10px',
    }}
    onMouseEnter={handleMouseEnter2}
    onMouseLeave={handleMouseLeave2}
  >
    <span
      style={{
        position: 'relative',
        borderBottom: '4px solid transparent',
        paddingBottom: '12px',
        transition: 'border-color 0.3s ease',
        cursor: 'pointer',
        fontFamily:'Sans-serif',
                fontSize:'15px'
      }}
      onMouseOver={(e) => e.target.style.borderColor = 'black'}
      onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
    >
      MORE
    </span>
  </a>
  {dropdownOpen && (
    <div
      style={{
        position: 'absolute',
        top: 'calc(100% + 10px)', // Adjusted top value for additional spacing
        right: '-30px',
        backgroundColor: 'white', // Dropdown background color
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Dropdown shadow
        borderRadius: '5px', // Dropdown border radius
        zIndex: '999', // Ensure dropdown appears above other content
        width: '150px', // Set dropdown width to 100%
        boxShadow: '0 0 10px rgb(232, 222, 222)', /* Adjust shadow color and size as needed */
        transition: 'transform 0.3s ease', // Add transition for smooth effect
        transform: isHovered ? 'scale(1.05)' : 'scale(1)', // Zoom in/out effect
        fontFamily:'Sans-serif',
         fontSize:'15px'
      }}
      onMouseEnter={handleMouseEnter2} // Keep dropdown open when mouse is over it
      onMouseLeave={handleMouseLeave2} // Close dropdown when mouse leaves it
    >
      {/* Dropdown content */}
      <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
        <li style={{ marginBottom: '0px', marginLeft: '15px' }}>
          <a href="/#/news" style={{ textDecoration: 'none', color: '#333' }}>
            NEWS
          </a>
        </li>
        <li style={{ marginBottom: '0px', marginLeft: '15px' }}>
          <a href="/#/contactus1" style={{ textDecoration: 'none', color: '#333' }}>
            CONTACT US
          </a>
        </li>
        {/* Add more dropdown items as needed */}
      </ul>
    </div>
  )}
</li>

</ul>                       
     
    </div> 
        
    <div style={{ textAlign: 'center', padding: '5px', margin: '10px',backgroundColor:'#FFE4E1' }}>

     <h2 className="screen-reader-text playfair-display" style={{ marginTop: '170px', textAlign:'center'}}>Workshop Enroll</h2>
      </div>
     <div style={{ backgroundColor: '#e6e6fa', padding: '20px', borderRadius: '0px', marginTop: '20px',width:'1500px' }}>

                <p style={{ marginLeft: '380px',color:'black',fontSize:'25px' }}><strong className='playfair-display'>Requirements:</strong></p>
                <br/>
                <div style={{ display: 'flex', alignItems: 'center',color:'black' }}>
                <ul style={{ margin: '0', paddingLeft: '20px' }}>
                  
                  <li style={{ marginBottom: '10px' }}>
                    <div className='playfair-display' style={{ display: 'flex', alignItems: 'center', marginLeft: '360px' }}>
                      <p style={{ color: 'black', fontSize: '20px', margin: '0', marginRight: '10px',color:'black' }}>
                        &#10003;
                      </p>
                      A scanned copy of a passport-size photograph
                    </div>
                  </li>
                  <li style={{ marginBottom: '10px' }}>
                    <div className='playfair-display' style={{ display: 'flex', alignItems: 'center', marginLeft: '360px' }}>
                      <p style={{ color: 'black', fontSize: '20px', margin: '0', marginRight: '10px',color:'black' }}>
                        &#10003;
                      </p>
                      A scanned copy of the Aadhar ID
                    </div>
                  </li>
                 
                  <li style={{ marginBottom: '10px' }}>
                    <div className='playfair-display' style={{ display: 'flex', alignItems: 'center', marginLeft: '360px' }}>
                      <p style={{ color: 'black', fontSize: '20px', margin: '0', marginRight: '10px' ,color:'black'}}>
                        &#10003;
                      </p>
                      A scanned copy of the 10th Grade pass certificate/mark sheet                  </div>
                  </li>
                  
                  <li style={{ marginBottom: '10px' }}>
                    <div className='playfair-display' style={{ display: 'flex', alignItems: 'center', marginLeft: '360px' }}>
                      <p style={{ color: 'black', fontSize: '20px', margin: '0', marginRight: '10px',color:'black' }}>
                        &#10003;
                      </p>
                      A scanned copy of the school/College ID card (only for the student category)                 </div>
                  </li>
                  
                </ul>
                </div>
              </div>
<br/>
             
      <Link to="/onlineadmission">
      <div style={{ alignItems: 'center', marginTop: '0px', textAlign: 'center' }}>
    <button className='playfair-display' style={{ padding: '0px', fontSize: '16px', backgroundColor: '#B6D0E2', color: 'black', border: 'none', borderRadius: '5px', cursor: 'pointer', width: '180px' }}>Enroll</button>
</div>


  </Link>
  
      </div>
 <br/>
            
 <footer style={{ fontSize: '10px',fontFamily:'Sans-serif', padding: '40px', backgroundColor: '#000068', color: '#000', width: '1500px', marginLeft: '0px' }}>
    <div className="d-flex justify-content-between" style={{ marginLeft: '100px', marginRight: '100px' }}>
      <div className="col-md-12 col-lg-4 col-mobile-down">
        <div className="mobile-collapse">
          
        <img className="top-bar__logo" src={image1} alt='logo' style={{ height: '150px', width: '150px', marginLeft: '-10px',marginTop:'10px' }} />

          <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white' }}>Contact Information</h4>
          <div className="pt-collapse-content playfair-display" style={{ color: 'white', fontSize: '12px' }}>
            <address className='playfair-display'>
              <span># 70 </span>B block <span>1st</span> floor Anandnagar Mysore<span> 570022</span> Karnataka<br />
              <a className='playfair-display' href="mailto:pieflyaerospace@gmail.com" style={{ color: 'white', fontSize: '12px' }}>Info@pieflyaerospace.com</a>
            </address>
          </div>
        </div>
      </div>
  
      <div className="col-md-3 col-lg-2">
        <div className="mobile-collapse" style={{ maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}>
          <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white', textAlign: 'center' }}>Quick Links</h4>
          <div className="pt-collapse-content" style={{ color: 'white', fontSize: '12px' }}>
            <div className="oval-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px', padding: '10px' }}>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Home</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Training Program</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Enroll</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Shop</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Contacts</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Elements</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div className="col-md-3 col-lg-2">
        <div className="mobile-collapse" style={{ maxWidth: '300px', marginLeft: 'auto', marginRight: 'auto' }}>
          <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white', textAlign: 'center' }}>Useful Links</h4>
          <div className="pt-collapse-content" style={{ color: 'white', fontSize: '12px' }}>
            <div className="oval-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px', padding: '10px' }}>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Products Documents</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Services Documents</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Products</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Blog and News</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div className="col-md-3 col-lg-2">
        <div className="mobile-collapse" style={{ maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}>
          <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white', textAlign: 'center' }}>Main Menu</h4>
          <div className="pt-collapse-content" style={{ color: 'white', fontSize: '12px' }}>
            <div className="oval-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px', padding: '10px' }}>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>About Us</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Gallery</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Projects</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Contacts</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        <div className="footer-custom">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-4">
                {/* <div className="copyright"style={{color: 'white',fontSize:'12px' }}>
                  &copy; 2019 Quadron. All rights reserved.
                </div> */}
              </div>
            
             <div className='social-icons' style={{marginLeft:'600px',marginTop:'-20px'}}>
    <a className='social-icon-link facebook' href='/' target='_blank' aria-label='Facebook'>
      <FontAwesomeIcon icon={faFacebookF} />
    </a>
    <a className='social-icon-link instagram' href='https://www.instagram.com/pieflyofficial?igsh=bmU0emdnbWs2aGIw' target='_blank' aria-label='Instagram' style={{marginLeft:'-40px'}}>
      <FontAwesomeIcon icon={faInstagram} />
    </a>
    <a className='social-icon-link twitter' href='https://x.com/pieflyofficial?t=RHOzsk0Vc47uIvKSCc5XVA&s=09' target='_blank' aria-label='Twitter' style={{marginLeft:'-40px'}}>
    <TfiTwitter />
    </a>
    <a className='social-icon-link linkedin' href='https://in.linkedin.com/company/piefly-r-d-private-limited' target='_blank' aria-label='LinkedIn' style={{marginLeft:'-40px'}}>
    <AiOutlineLinkedin />
    </a>
    <a className='social-icon-link Youtube' href='https://youtube.com/@piefly4302?si=Qecbd2nJ80cYx-Hk' target='_blank' aria-label='Youtube' style={{marginLeft:'-40px'}}>
    <BsYoutube />  </a>
  </div>
  
                </div>
              </div>
            </div>
          
      </footer>   </>
  



  );
}

export default WorkshopEnroll;

