import React, { useState, useEffect,useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../api/baseurl';
import Dashboard from '../New/Dashboard';
import image1 from '../svg-sprite/mainslider/logo.jpeg';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom
import Sidebar from './Sidebar';
import Dropdown from './Dropdown';
import Dropdown1 from './Dropdown1';
import "./Dropdown.css";


function Account() {
  const [users, setUsers] = useState([]);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const dropdownTimeoutRef = useRef(null);
  const dropdownRef = useRef(null);


  const user = JSON.parse(localStorage.getItem('data'));
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [dropdown1, setDropdown1] = useState(false);


  const handleMouseEnter = () => {
    clearTimeout(dropdownTimeoutRef.current);
    setDropdown(true);
    setDropdown1(false); // Close the other dropdown
  };

  const handleMouseLeave = () => {
    clearTimeout(dropdownTimeoutRef.current);
    dropdownTimeoutRef.current = setTimeout(() => {
      setDropdown(false);
    }, 3500);
  };

  const handleMouseEnter1 = () => {
    clearTimeout(dropdownTimeoutRef.current);
    setDropdown1(true);
    setDropdown(false); // Close the other dropdown
  };

  const handleMouseLeave1 = () => {
    clearTimeout(dropdownTimeoutRef.current);
    dropdownTimeoutRef.current = setTimeout(() => {
      setDropdown1(false);
    }, 3500);
  };

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };
const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  
  const handleDropdownEnter = () => {
    clearTimeout(dropdownRef.current);
  };

  const handleDropdownLeave = () => {
    clearTimeout(dropdownRef.current);
    setDropdown(false);
  };

  useEffect(() => {
    
    const getData = async () => {
      setLoading(true);

      const token = user.token;

      try {
        const { data } = await axios.get('/api/accounts', {
          headers: { Authorization: `Bearer ${token}` },
        });

        setUsers(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [user.token]);

  const navigate = useNavigate();

  return (
    <div>
      {/* Start Header */}
      <header id="top-bar" style={{ background: 'white' }}>
        <div className="container-fluid">
          <div className="row justify-content-between no-gutters">
            <div className="col-auto side-col d-flex align-items-center text-nowrap">
             
              {/* Start logo */}
              <img className="top-bar__logo" src={image1} alt='logo' style={{height: '100px', width: '150px', marginLeft:'170px'}}/>
              <div className="col-auto" style={{ fontSize: 'small', color: '#333333', textAlign: 'center' }}>
  <span style={{ fontWeight: 'bold' }}>PIEFLY AVIATION REMOTE </span> <br />
  PILOT TRAINING ORGANISATION
</div>
&emsp;&emsp;&emsp;
<div className="col-auto" style={{ fontSize: 'small', color: '#333333', textAlign: 'center' }}>
  <span style={{ fontWeight: 'bold' }}>CENTRE FOR AVIATION </span> <br />
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ fill: '#4285F4' }}>
      <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
    </svg>
    <span style={{ marginLeft: '5px' }}>SJCE CAMPUS [STEP] MYSORE</span>
  </div>
</div>

&emsp;&emsp;&emsp;
<div className="col-auto" style={{ fontSize: 'small', color: '#333333', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ fill: '#4285F4', marginRight: '5px' }}>
    <path d="M20 3H17V1H15V3H9V1H7V3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H20C21.1 21 22 20.1 22 19V5C22 3.9 21.1 3 20 3M20 19H4V8H20V19M7 10H9V12H7V10M11 10H13V12H11V10M15 10H17V12H15V10Z"/>
  </svg>
  <span style={{ display: 'inline-flex', alignItems: 'center' }}>
    <span style={{ fontWeight: 'bold', marginRight: '5px' }}>9:00 AM-6:00 PM </span>
    {/* <span style={{ marginLeft: '5px' }}>Monday-Saturday</span> */}
  </span>
</div>
&emsp;&emsp;&emsp;
<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ fill: 'none', stroke: '#4285F4', strokeWidth: '2', strokeLinecap: 'round', strokeLinejoin: 'round', marginRight: '5px' }}>
    <path d="M22 5.1C21.04 5.59 20 5.882 18.91 6l-11.82-.02C5.098 5.858 4.062 5.573 3 5.1V19c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5.1zM12 14l-8-5 8-5 8 5-8 5z"/>
  </svg>
  <div style={{ marginLeft: '5px' }}>piefly@gmail.com</div>
</div>
</div>
{/* Add login button */}
<div className="col-auto" style={{ fontSize: 'small', color: '#333333', textAlign: 'center', marginTop:'25px', marginRight:'30px' }}>
              <button style={{ background: '#0073cf',fontSize: '16px', color: 'white', padding: '10px 30px', borderRadius: '5px', cursor: 'pointer' }}>
                Login
              </button>
            </div>
            {/* End login button */}
             </div>
            </div>
            </header>
            <Sidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(!isSidebarOpen)} />
      &emsp;&emsp;&emsp;&emsp;&emsp;

      <div
        style={{
          background: '#0073cf',
          color: 'white',
          padding: '20px',
          marginTop: '100px',
          position: 'fixed',
          top: 0,
          
          width: '100%',
          zIndex: 1000,
        }}
      >
        <ul
          style={{
            display: 'flex',
            alignItems: 'center',
            listStyle: 'none',
            padding: 0,
            marginLeft:'90px',
          }}
        >
          <li style={{ marginRight: '10px',marginLeft:'-5px' }}>
            <Link to="/" style={{ color: 'white', textDecoration: 'none' }}>
              <span>HOME</span>
            </Link>
          </li>
          &emsp;&emsp;
          &emsp;&emsp;
          <a
            href='#'
            style={{
              color: 'white',
              textDecoration: 'none',
              position: 'relative',
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <span>
              PRODUCTS
              {dropdown && <Dropdown />}
            </span>
          </a>
          &emsp;&emsp;
          &emsp;&emsp;
&emsp;&emsp;
          <a
            href='#'
            style={{
              color: 'white',
              textDecoration: 'none',
              position: 'relative',
            }}
            onMouseEnter={handleMouseEnter1}
            onMouseLeave={handleMouseLeave1}
          >
            <span>
              SERVICES
              {dropdown1 && <Dropdown1 />}
            </span>
          </a>
          &emsp;&emsp;
          &emsp;&emsp;
          &emsp;&emsp;
  
  <li>
    <a href="/#/rpto" style={{ color: 'white', textDecoration: 'none' }}>
      <span>RPTO</span>
    </a>
    <Link to="/rpto" style={{ color: 'white', textDecoration: 'none' }}></Link>
  </li>
  &emsp; &emsp; &emsp; &emsp;&emsp;
  <li>
    <a href="/#/about" style={{ color: 'white', textDecoration: 'none' }}>
      <span>ABOUT US</span>
    </a>
    <Link to="/about" style={{ color: 'white', textDecoration: 'none' }}></Link>
  </li>
  &emsp; &emsp; &emsp; &emsp;&emsp;
  <li>
    <a href="about" style={{ color: 'white', textDecoration: 'none' }}>
      <span>NEWS</span>
    </a>
    <Link to="/about" style={{ color: 'white', textDecoration: 'none' }}></Link>
  </li>
  &emsp; &emsp; &emsp; &emsp;&emsp;
  <li>
    <a href="about" style={{ color: 'white', textDecoration: 'none' }}>
      <span>ARTICLES</span>
    </a>
    <Link to="/about" style={{ color: 'white', textDecoration: 'none' }}></Link>
  </li>
  &emsp; &emsp; &emsp; &emsp;&emsp;
  <li>
    <a href="contactus" style={{ color: 'white', textDecoration: 'none' }}>
      <span>CONTACT US</span>
    </a>
    <Link to="/contactus" style={{ color: 'white', textDecoration: 'none' }}></Link>
  </li>
</ul>                
</div>

    <div>
      <div id="layoutDrawer_content">
        {loading && <div className="loading"></div>}
        
        <div>
          <Dashboard />
        </div>

        <div className="col-9 mx-xl-auto ps-xl-9">
          <div className="card card-raised ">
            <div className="card-body ">
              <div className="card-title">User Account</div>

              <table className="table table-bordered ">
                <thead>
                  <tr
                    style={{
                      fontSize: '16px',
                      backgroundColor: '#002366',
                      color: 'white',
                    }}
                  >
                    <th>Id </th>
                    <th>UserName</th>
                    <th>Role</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user) => (
                    <tr key={user.id}>
                      <td>{user.id}</td>
                      <td>{user.username}</td>
                      <td>
                        {user.roles.map((role) => (
                          <span key={role.id}>{role.role}</span>
                        ))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default Account;
