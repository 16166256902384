import { RxCounterClockwiseClock } from 'react-icons/rx';
import { LiaLifeRing } from 'react-icons/lia';
import { SlGlobe, SlBriefcase, SlLock } from 'react-icons/sl';
import "./MainContent.css";

function MainContent() {
  return (
    <div className="main-content-container">
      <div className="page-main-content">
        <div className="content-wrapper">
          <h3 className="content-heading">Why We Get License</h3>
          <div className="content-items">
            {/* Item 1 */}
            <div className="content-item">
              <div className="icon">
                <RxCounterClockwiseClock />
              </div>
              <div className="item-details">
                <h1 className="item-number">01</h1>
                <p className="item-title">UAS Rules</p>
                <p className="item-description">Effective immediately, a Remote Pilot Certificate is mandatory for operating all drones except nano drones, as per the new "UAS Rules 2021."</p>
              </div>
            </div>
            
            {/* Item 2 */}
            <div className="content-item">
              <div className="icon">
                <LiaLifeRing />
              </div>
              <div className="item-details">
                <h1 className="item-number">02</h1>
                <p className="item-title">Penalty</p>
                <p className="item-description">Fine of Rs.25,000 will be imposed on individuals operating without a remote pilot license, as per sub-rule (6) of rule 29 of the UAS Rules.</p>
              </div>
            </div>
            
            {/* Item 3 */}
            <div className="content-item">
              <div className="icon">
                <SlGlobe />
              </div>
              <div className="item-details">
                <h1 className="item-number">03</h1>
                <p className="item-title">UAV Job Requirement</p>
                <p className="item-description">The industry is projected to require over 70,000 licensed drone pilots by 2022.</p>
              </div>
            </div>
            
            {/* Item 4 */}
            <div className="content-item">
              <div className="icon">
                <SlBriefcase />
              </div>
              <div className="item-details">
                <h1 className="item-number">04</h1>
                <p className="item-title">Apply Eligibility</p>
                <p className="item-description">Applicants must be at least 18 years of age and have completed the 10th class or its equivalent examination.</p>
              </div>
            </div>
            
            {/* Item 5 */}
            <div className="content-item">
              <div className="icon">
                <SlLock />
              </div>
              <div className="item-details">
                <h1 className="item-number">05</h1>
                <p className="item-title">License Validity</p>
                <p className="item-description">The DGCA-approved Remote Pilot License (RPL) is valid for a maximum of 10 years under the UAS Rule 2021.</p>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainContent;
