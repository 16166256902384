import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../api/baseurl';
import Nav1 from './nav1';
import Footer from '../New/Footer ';

function ContactDetails() {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);

  const user = JSON.parse(localStorage.getItem('data'));

  useEffect(() => {
    const getData = async () => {
      setLoading(true);

      const token = user.token;

      try {
        const { data } = await axios.get('/api/contact', {
          headers: { Authorization: `Bearer ${token}` },
        });

        setContacts(data); // Update state with the fetched data
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [user.token]);

  return (
    <div>
      {loading && <div className="loading"></div>}

      <Nav1 />
      <div className="card card-raised">
        <div className="card-body">
          <div className="card-title"></div>

          <br />
          <br />
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>ID</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Date of Birth</th>
                <th>Blood Group</th>
                <th>Age</th>
                <th>Aadhar Number</th>
                <th>ID Proof Type</th>
                <th>Qualification</th>
              </tr>
            </thead>
            <tbody>
              {contacts.map((contact) => (
                <tr key={contact.id}>
                  <td>{contact.id}</td>
                  <td>{contact.firstname}</td>
                  <td>{contact.lastname}</td>
                  <td>{contact.email}</td>
                  <td>{contact.phone}</td>
                  <td>{contact.dob}</td>
                  <td>{contact.bloodGroup}</td>
                  <td>{contact.age}</td>
                  <td>{contact.aadharNumber}</td>
                  <td>{contact.idProofType}</td>
                  <td>{contact.qualification || 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <Footer />
    </div>
  );
}

export default ContactDetails;
