import React, { useState, useEffect, useRef } from 'react';
import image from '../svg-sprite/mainslider/topography.png';
import image1 from '../svg-sprite/mainslider/topography1.png';
import image2 from '../svg-sprite/mainslider/topography2.png';
import image3 from '../svg-sprite/mainslider/topography3.png';
import image4 from '../svg-sprite/mainslider/topography4.png';
import "./Dropdown.css";
import Navbar from './navbar';
import Footer from './Footer ';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { TfiTwitter } from "react-icons/tfi";
import { AiOutlineLinkedin } from "react-icons/ai";
import { BsYoutube } from "react-icons/bs";
import Modal from './EnquiryModal'; // Assuming EnquiryModal component is in a file named EnquiryModal.js
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { MdMailOutline } from "react-icons/md";
import imag from '../svg-sprite/mainslider/pieflylogo.png'

const Topography = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEnquireClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
   <>
   <div> <Navbar/> </div>

      <div>
        <div style={{ textAlign: 'center',marginTop: '130px' }}>
    
    <img
      className="slide-icon"
      src={image}
      alt="img"
      style={{ height: '600px', width: '1500px', margin: '0 auto',marginLeft:'0px' }}
    />
     <h1 className="title title-lg playfair-display" style={{ fontSize: '2.4em',marginTop: '-440px',color:'white',fontFamily:'Sans-serif',marginLeft:'600px'}}>
     TOPOGRAPHICAL SURVEY [MAPPING]
    </h1>
    </div>
    <br/>
    <br/><br/><br/><br/><br/><br/><br/><br/><br/>
    <p className="para-style">
      <br/>
    What is meant by drone survey? </p>
                 <p className='para-style' style={{ textAlign: 'left', color:'black', marginLeft: '350px'  }}>
                 A drone survey involves using a drone, or unmanned aerial vehicle (UAV), to collect aerial data<br/>
                  with downward-facing sensors like RGB or multispectral cameras and LIDAR equipment.<br/> When conducting
                   a drone survey with an RGB camera, the ground is photographed multiple<br/> times from various angles, 
                   tagging each image with coordinates.</p>
                   <br/>
                   <img
      className="slide-icon"
      src={image1}
      alt="img"
      style={{ height: '400px', width: '600px', margin: '0 auto',marginLeft:'430px' }}
    />
   
                 <p className='para-style' style={{ textAlign: 'left', color:'black', fontFamily:'Sans-serif',marginLeft: '350px'  }}>
                 From this data, photogrammetry software can produce geo-referenced ortho mosaics, elevation models,<br/>
                  or 3D models of the project area. These maps can also provide highly accurate distances and volumetric<br/>
                   measurements.<br/><br/>
Unlike manned aircraft or satellite imagery, drones can fly at much lower altitudes, generating high-resolution,<br/>  high-accuracy
 data more quickly, cost-effectively, and independent of atmospheric conditions such as cloud<br/>  cover.
</p>
                   <br/>
                   <p className='para-style' style={{ fontSize: '26px', textAlign: 'left', color:'black',fontFamily:'Sans-serif',fontWeight:'bold', marginLeft: '350px'  }}>
                   What are the advantages of using drones in surveying? </p><br/>
                   <img
      className="slide-icon"
      src={image2}
      alt="img"
      style={{ height: '100px', width: '100px', margin: '0 auto',marginLeft:'360px' }}
    />
    <p className='para-style' style={{ fontSize: '26px', textAlign: 'left', color:'black',fontFamily:'Sans-serif',fontWeight:'bold', marginLeft: '350px'  }}>
    Reduce field time and survey costs: </p>
                 <p className='para-style' style={{ textAlign: 'left', color:'black',fontFamily:'Sans-serif', marginLeft: '350px'  }}>
                 Using a drone to capture topographic data is up to five times faster than traditional land-based methods<br/> and requires
                  fewer personnel. With PPK geo-tagging, the need to place numerous GCPs is eliminated,<br/> further saving time. This 
                  enables you to deliver survey results more quickly and at a lower cost.</p>
                   <br/>

                   <img
      className="slide-icon"
      src={image3}
      alt="img"
      style={{ height: '100px', width: '100px', margin: '0 auto',marginLeft:'360px' }}
    />
    <p className='para-style' style={{ fontSize: '26px', textAlign: 'left', color:'black',fontFamily:'Sans-serif',fontWeight:'bold', marginLeft: '350px'  }}>
    Provide accurate and comprehensive data: </p>
                 <p className='para-style' style={{ textAlign: 'left', color:'black', fontFamily:'Sans-serif',marginLeft: '350px'  }}>
                 Total stations measure individual points, whereas a single drone flight can generate thousands of<br/> measurements,
                  which can be represented in various formats (orthomosaic, point cloud, DTM,<br/> DSM, contour lines, etc.). Each
                   pixel of the resulting map or point in the 3D model contains 3D<br/> geo-data.</p>
                   <br/>

                   <img
      className="slide-icon"
      src={image4}
      alt="img"
      style={{ height: '100px', width: '100px', margin: '0 auto',marginLeft:'360px' }}
    />
    <p className='para-style' style={{ fontSize: '26px', textAlign: 'left', color:'black',fontFamily:'Sans-serif',fontWeight:'bold', marginLeft: '350px'  }}>
    Map otherwise inaccessible areas: </p>
                 <p className='para-style' style={{ textAlign: 'left', color:'black',fontFamily:'Sans-serif', marginLeft: '350px'  }}>
                 An aerial surveying drone can take off and fly nearly anywhere, overcoming the limitations of<br/> unreachable 
                 areas, unsafe steep slopes, or harsh terrain that traditional measuring tools<br/> cannot handle. There is no
                  need to close highways or train tracks, as data can be captured <br/>during operations without additional
                   organizational overhead.</p>
                   <br/>
                     &emsp;&emsp; &emsp;&emsp;
                  &emsp;&emsp;
                  <br/>
 <br/>

 <br/>
 <footer style={{ fontSize: '10px', padding: '40px', backgroundColor: '#000068', color: '#000', width: '1500px', marginLeft: '0px' }}>
  <div className="d-flex justify-content-between" style={{ marginLeft: '100px', marginRight: '100px' }}>
    <div className="col-md-12 col-lg-4 col-mobile-down">
      <div className="mobile-collapse">
      <img className="top-bar__logo" src={imag} alt='logo' style={{ height: '180px', width: '180px', marginLeft: '30px',marginTop:'-40px' }} />

        <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white' }}>Contact Information</h4>
        <div className="pt-collapse-content playfair-display" style={{ color: 'white', fontSize: '12px' }}>
        <address className=' playfair-display'>
                    <div style={{ display: 'flex' }}>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ fill: 'white' }}>
    <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
  </svg>
  <span> # 70 B block
          1st floor, Anandnagar
          Mysore - 570022, Karnataka
          </span> 
                   </div>
                   <a className='playfair-display' href="mailto:pieflyaerospace@gmail.com" style={{ color: 'white', fontSize: '12px', display: 'flex', alignItems: 'center' }}>
  <span style={{ fontSize: '24px', color: 'white', marginRight: '8px' }}>
    <MdMailOutline />
  </span>
  Info@pieflyaerospace.com
</a>

                    </address>
        </div>
      </div>
    </div>

    <div className="col-md-3 col-lg-2">
      <div className="mobile-collapse" style={{ maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}>
        <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white',fontFamily: 'Sans-serif', textAlign: 'center' }}>Quick Links</h4>
        <div className="pt-collapse-content" style={{ color: 'white',fontFamily: 'Sans-serif', fontSize: '12px' }}>
          <div className="oval-grid" style={{ display: 'grid', fontFamily: 'Sans-serif',gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px', padding: '10px' }}>
            <div className="oval-box" style={{ width: '120px', fontFamily: 'Sans-serif',height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
              <a href="#" style={{ color: 'black',fontFamily: 'Sans-serif', textAlign: 'center' }}><span className='playfair-display'>Home</span></a>
            </div>
            <div className="oval-box" style={{ width: '120px',fontFamily: 'Sans-serif', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
              <a href="#" style={{ color: 'black',fontFamily: 'Sans-serif', textAlign: 'center' }}><span className='playfair-display'>Training Program</span></a>
            </div>
            <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
              <a href="#" style={{ color: 'black',fontFamily: 'Sans-serif', textAlign: 'center' }}><span className='playfair-display'>Enroll</span></a>
            </div>
            <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
              <a href="#" style={{ color: 'black',fontFamily: 'Sans-serif', textAlign: 'center' }}><span className='playfair-display'>Shop</span></a>
            </div>
            <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
              <a href="#" style={{ color: 'black',fontFamily: 'Sans-serif', textAlign: 'center' }}><span className='playfair-display'>Contacts</span></a>
            </div>
            <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
              <a href="#" style={{ color: 'black',fontFamily: 'Sans-serif', textAlign: 'center' }}><span className='playfair-display'>Elements</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="col-md-3 col-lg-2">
      <div className="mobile-collapse" style={{ maxWidth: '300px', marginLeft: 'auto', marginRight: 'auto' }}>
        <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white',fontFamily: 'Sans-serif', textAlign: 'center' }}>Useful Links</h4>
        <div className="pt-collapse-content" style={{ color: 'white', fontFamily: 'Sans-serif',fontSize: '12px' }}>
          <div className="oval-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px', padding: '10px' }}>
            <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
              <a href="#" style={{ color: 'black', fontFamily: 'Sans-serif',textAlign: 'center' }}><span className='playfair-display'>Products Documents</span></a>
            </div>
            <div className="oval-box" style={{ width: '120px', fontFamily: 'Sans-serif',height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
              <a href="#" style={{ color: 'black',fontFamily: 'Sans-serif', textAlign: 'center' }}><span className='playfair-display'>Services Documents</span></a>
            </div>
            <div className="oval-box" style={{ width: '120px',fontFamily: 'Sans-serif', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
              <a href="#" style={{ color: 'black', fontFamily: 'Sans-serif',textAlign: 'center' }}><span className='playfair-display'>Products</span></a>
            </div>
            <div className="oval-box" style={{ width: '120px',fontFamily: 'Sans-serif', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
              <a href="#" style={{ color: 'black',fontFamily: 'Sans-serif', textAlign: 'center' }}><span className='playfair-display'>Blog and News</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="col-md-3 col-lg-2">
      <div className="mobile-collapse" style={{ maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}>
        <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white',fontFamily: 'Sans-serif', textAlign: 'center' }}>Main Menu</h4>
        <div className="pt-collapse-content" style={{ color: 'white', fontFamily: 'Sans-serif',fontSize: '12px' }}>
          <div className="oval-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px', padding: '10px' }}>
            <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
              <a href="#" style={{ color: 'black',fontFamily: 'Sans-serif', textAlign: 'center' }}><span className='playfair-display'>About Us</span></a>
            </div>
            <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
              <a href="#" style={{ color: 'black',fontFamily: 'Sans-serif', textAlign: 'center' }}><span className='playfair-display'>Gallery</span></a>
            </div>
            <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
              <a href="#" style={{ color: 'black', fontFamily: 'Sans-serif',textAlign: 'center' }}><span className='playfair-display'>Projects</span></a>
            </div>
            <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
              <a href="#" style={{ color: 'black',fontFamily: 'Sans-serif', textAlign: 'center' }}><span className='playfair-display'>Contacts</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
      <div className="footer-custom">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4">
              {/* <div className="copyright"style={{color: 'white',fontSize:'12px' }}>
                &copy; 2019 Quadron. All rights reserved.
              </div> */}
            </div>
          
           <div className='social-icons' style={{marginLeft:'600px',marginTop:'-20px'}}>
           <a className='social-icon-link whatsapp' href='https://wa.me/yourwhatsapplink' target='_blank' aria-label='WhatsApp' style={{ marginLeft: '-40px' }}>
<FontAwesomeIcon icon={faWhatsapp} />
</a>

  <a className='social-icon-link facebook' href='/' target='_blank' aria-label='Facebook'>
    <FontAwesomeIcon icon={faFacebookF} />
  </a>
  <a className='social-icon-link instagram' href='https://www.instagram.com/pieflyofficial?igsh=bmU0emdnbWs2aGIw' target='_blank' aria-label='Instagram' style={{marginLeft:'-40px'}}>
    <FontAwesomeIcon icon={faInstagram} />
  </a>
  <a className='social-icon-link twitter' href='https://x.com/pieflyofficial?t=RHOzsk0Vc47uIvKSCc5XVA&s=09' target='_blank' aria-label='Twitter' style={{marginLeft:'-40px'}}>
  <TfiTwitter />
  </a>
  <a className='social-icon-link linkedin' href='https://in.linkedin.com/company/piefly-r-d-private-limited' target='_blank' aria-label='LinkedIn' style={{marginLeft:'-40px'}}>
  <AiOutlineLinkedin />
  </a>
  <a className='social-icon-link Youtube' href='https://youtube.com/@piefly4302?si=Qecbd2nJ80cYx-Hk' target='_blank' aria-label='Youtube' style={{marginLeft:'-40px'}}>
  <BsYoutube />  </a>
</div>

              </div>
            </div>
          </div>
        
    </footer>                 </div>
                 
                 </>
);
};
export default Topography;