import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { SlClose } from "react-icons/sl";


const DocumentsModal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    companyName: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(formData);
    // Close modal
    onClose();
  };

  useEffect(() => {
    const handleBodyOverflow = () => {
      document.body.style.overflow = isOpen ? 'hidden' : 'auto';
    };
    handleBodyOverflow();

    const handleOverlayClick = (event) => {
      if (event.target.id === 'modalOverlay') {
        // Change background color to red temporarily
        document.body.style.backgroundColor = 'red';
        // Revert background color back to black after 1 second
        setTimeout(() => {
          document.body.style.backgroundColor = 'black';
        }, 1000);
      }
    };

    window.addEventListener('click', handleOverlayClick);

    return () => {
      document.body.style.overflow = 'auto';
      window.removeEventListener('click', handleOverlayClick);
    };
  }, [isOpen]);

  return (
<Modal
  isOpen={isOpen}
  onRequestClose={onClose}
  contentLabel="Enquiry Modal"
  shouldCloseOnOverlayClick={false}
  style={{
    overlay: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    content: {
      width: '500px',
      height: '370px',
      margin: 'auto',
      marginTop: '160px',
      overflowY: 'auto', // Enable vertical scrolling
      overflowX: 'hidden', // Hide horizontal scrolling
    },
  }}
>      <div id="modalOverlay" onClick={(e) => e.stopPropagation()}>
      <SlClose onClick={onClose} style={{ position: 'absolute', top: '0px', right: '0px', cursor: 'pointer',color:'#013220', border: 'none', width: '40px', height: '40px', borderRadius: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }} />
        {/* <button onClick={onClose} style={{ position: 'absolute', top: '0px', right: '0px', cursor: 'pointer', border: 'none', width: '40px', height: '40px', borderRadius: '0px', background: 'black', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>X</button> */}
        {/* <h2 style={{ color:'#013220',fontSize:'24px'}}>Enquire Now </h2> */}
        <form onSubmit={handleSubmit} style={{ border: 'none', marginTop:'-40px' }}>
        <div style={{ marginBottom: '10px' }}>
  <label htmlFor="name" style={{ marginRight: '10px', fontWeight: 'bold',color:'#013220',marginTop:'40px' }}> Full Name:</label>
  <input
    type="text"
    id="name"
    name="name"
    value={formData.name}
    onChange={handleChange}
    style={{
       marginTop:'-10px',
      padding: '8px',
      borderRadius: '4px',
      border: '1px solid #ccc',
      width: '100%',
      height:'30px',
      boxSizing: 'border-box',
    }}
  />
</div>

<div style={{ marginBottom: '10px' }}>
  <label htmlFor="email" style={{ marginRight: '10px', fontWeight: 'bold',color:'#013220' }}>Email:</label>
  <input
    type="email"
    id="email"
    name="email"
    value={formData.email}
    onChange={handleChange}
    style={{
        marginTop:'-10px',
      padding: '8px',
      borderRadius: '4px',
      border: '1px solid #ccc',
      width: '100%',
      height:'30px',
      boxSizing: 'border-box',
    }}
  />
</div>

<br/>


        
          <button
  type="submit"
  style={{
    backgroundColor:'#013220',
    padding: '0px ',
    
  }}
>
  Submit
</button>        </form>
      </div>
    </Modal>
  );
};

export default DocumentsModal;
