import React, { useEffect, useState } from "react";
import { useParams,useNavigate,useLocation } from "react-router-dom";
import { GoogleMap, Marker,Circle} from '@react-google-maps/api'; // Import GoogleMap and Marker from react-google-maps
import { Link } from "react-router-dom";
 
 
const centerOfIndia = { lat: 22.3511148, lng: 78.6677428 };
 
 
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
   let location = useLocation();
   let navigate = useNavigate();
 
  let params = useParams();
 
  return (
 
  <Component
  {...props}
  location={location}
  params={params}
  navigate={navigate}
  />
   );
  }
   return ComponentWithRouterProp;
  }
 
 
 
 
 
 
const MapComponent = (props) => {
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);
  const { deviceid } = useParams();
  const [agents, setAgents] = useState([]);
  const [api, setapi] = useState([]);
 
  const navigate = useNavigate();
 
 
 
 
 
 
 
  // Function to update the marker's position
  const updateMarker = (latitude, longitude) => {
    if (marker && map) {
      marker.setPosition({ lat: latitude, lng: longitude });
      map.panTo({ lat: latitude, lng: longitude });
    }
  };
 
 
 
return (
 
<div>
 
<div className="dform-group d-flex align-items-center justify-content-between mt-8 mb-0" >

</div>
 
<div id="map" style={{   position: "absolute", left: "300px", top: "150px", height: '550px', width: '80%' }}>
{agents.map((agent) => (
 
<GoogleMap
      mapContainerStyle={{ height: '100%', width: '100%' }}
      center={  {lat: parseFloat(agent.latitude), lng: parseFloat(agent.longitude)}}
      zoom={10}
>
<React.Fragment key={agent.id}>
{console.log("Agent Data:", agent)}  
{console.log("Latitude:", parseFloat(agent.latitude))}    
{console.log("Longitude:", parseFloat(agent.longitude))}
 
<Circle
             key= {`circle_${agent.id}`}
            center={{ lat: parseFloat(agent.latitude), lng: parseFloat(agent.longitude) }}
            radius={3000}
            options={{
              fillColor: 'blue',
              fillOpacity: 0.1,
              strokeColor: 'blue',
              strokeWeight: 1,
            }}
          />
<Marker
            key= {agent.id}
            position={{ lat: parseFloat(agent.latitude), lng: parseFloat(agent.longitude) }}
            icon={{
              // path: window.google.maps.SymbolPath.CIRCLE,
              // fillColor: 'blue',
              // fillOpacity: 0.5,
              // strokeColor: 'blue',
              // strokeWeight: 2,
              // scale: 8,
              url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png', // URL to your custom pointer icon
              scaledSize: new window.google.maps.Size(40, 40), // Adjust the size as needed
 
            }}
          />
</React.Fragment>
</GoogleMap>
      ))}
 
</div>
</div>
);
};
 
export default withRouter(MapComponent);