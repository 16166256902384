import React, { useState } from 'react';
import image from '../svg-sprite/mainslider/drone3.png';

const DraggableImage = () => {
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setStartPosition({
      x: event.clientX - position.x,
      y: event.clientY - position.y
    });
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;
    setPosition({
      x: event.clientX - startPosition.x,
      y: event.clientY - startPosition.y
    });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div 
      className="item"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      style={{ 
        position: 'absolute', 
        top: position.y, 
        left: position.x, 
        cursor: isDragging ? 'grabbing' : 'grab' 
      }}
    >
      <span className="icon-video"><i></i></span>
      <img 
        className="slide-icon" 
        src={image} 
        alt='img' 
        style={{ height: '800px', width: '1500px' }} 
      />
    </div>
  );
};

export default DraggableImage;
