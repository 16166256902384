//is loggedin
export const isLoggedIn = () => {
    let data = localStorage.getItem("data");
    if (data == null) {
      return false;
    } else {
      return true;
    }
  };
  
  //doLogin=>data=>set to localStorage
  export const doLogin = (data, next) => {
    localStorage.setItem("data", JSON.stringify(data));
  
    next();
  };
  //do logout=>remove from localstorage
  export const doLogout = () => {
    localStorage.removeItem("data");
  };
  
  //get currentuser
  export const getCurrentUserDetail = () => {
    if (isLoggedIn()) {
      return JSON.parse(localStorage.getItem("data"));
    } else {
      return undefined;
    }
  };
  