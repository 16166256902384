import React, { useState } from "react";
import "./Dropdown.css";
import { NavLink } from "react-router-dom";

function Dropdown3() {
    const [EnrollDropdown, setEnrollDropdown] = useState(false);

    const rpto = [
        { title: "RPTO ENROLL ", link: "/enroll" },
        
        { title: "INTERNSHIP ENROLL", link: "/InternshipEnroll" },
        { title: "WORKSHOP ENROLL", link: "/WorkshopEnroll" },
    ];

    const EnrollInFirstColumn = rpto.slice(0, 4);

    return (
        <>
            <ul
                className={EnrollDropdown ? "Enroll-submenu clicked" : "Enroll-submenu"}
                onClick={(e) => {
                    setEnrollDropdown(!EnrollDropdown);
                    e.stopPropagation();
                }}
                style={{ display: "flex", listStyle: 'none', padding: 0, marginTop: '-8px' }}
            >
                <div style={{ flex: 1 }}>
                    {EnrollInFirstColumn.map((item, index) => (
                        <li key={index} style={{ marginBottom: '-20px', marginLeft: '20px', fontSize: '16px',marginTop:'-10px' }}>
                            <NavLink
                to={item.link}
                activeClassName="active-link"
                onClick={() => setEnrollDropdown(false)}
                style={{ textDecoration: 'none', fontFamily: "TimesNewRoman" }}
                >
                <p>{item.title}</p>
              </NavLink>
                        </li>
                    ))}
                </div>
            </ul>
        </>
    );
}

export default Dropdown3;
