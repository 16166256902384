import React, { useState, useEffect } from 'react';
import axios from '../api/baseurl';
import Nav1 from './nav1';
import Footer from '../New/Footer ';

function Traceability() {
  const [ipAddresses, setIpAddresses] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const submitFormData = async () => {
      setLoading(true);

      try {
        const storedData = JSON.parse(localStorage.getItem('data'));

        if (!storedData || !storedData.token) {
          console.error('Token not found in localStorage');
          // Handle the case where the token is not available
          // You might want to redirect the user to the login page or take appropriate action
          return;
        }

        const token = storedData.token;
        console.log('Token:', token);

        const actualIpAddress = '0:0:0:0:0:0:0:1';
        const response = await axios.get(`/api/geolocation/ipaddress=${actualIpAddress}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response) {
          console.log('Response:', response.data);
          // Update your state or perform any other actions with the response data
          setIpAddresses(response.data);
        } else {
          console.error('Response is undefined or null');
        }
      } catch (error) {
        console.error('Error submitting data:', error);

        if (error.response) {
          console.error('Error response:', error.response.data);
        }

      } finally {
        setLoading(false);
      }
    };

    submitFormData();
  }, []); 

  const displayIpAddress = (ipDetails) => {
    const ipAddress = ipDetails.ipaddress;
    return ipAddress === '0:0:0:0:0:0:0:1' ? ipAddress : ipAddress || '';
  };

  return (
    <div>
      {loading && <div className="loading"></div>}

      <Nav1 />
      <div className="card card-raised">
        <div className="card-body">
          <div className="card-title"></div>

          <br />
          <br />

          <table className="table table-bordered">
            <thead>
              <tr>
                <th>ID</th>
                <th>IP Address</th>
                <th>Latitude</th>
                <th>Longitude</th>
                <th>Country</th>
                <th>Region</th>
                <th>City</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {ipAddresses.map((ipDetails, index) => (
                <tr key={index}>
                  <td>{ipDetails.id || ''}</td>
                  <td>{displayIpAddress(ipDetails)}</td>
                  <td>{ipDetails.lat || ''}</td>
                  <td>{ipDetails.lon || ''}</td>
                  <td>{ipDetails.country || ''}</td>
                  <td>{ipDetails.region || ''}</td>
                  <td>{ipDetails.city || ''}</td>
                  <td>{ipDetails.date ? new Date(ipDetails.date).toLocaleString() : 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <Footer />
    </div>
  );
}

export default Traceability;
