import React from 'react';
import image from '../svg-sprite/mainslider/searchdrones.png';
import "./Dropdown.css";
import Navbar from './navbar';
import Footer from './Footer ';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { TfiTwitter } from "react-icons/tfi";
import { AiOutlineLinkedin } from "react-icons/ai";
import { BsYoutube } from "react-icons/bs";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { MdMailOutline } from "react-icons/md";
import image1 from '../svg-sprite/mainslider/pieflylogo.png'

const Searchdrones = () => {
 

  return (
    <> 
<div> <Navbar/> </div>
<br/>
      <div>
        <div style={{ textAlign: 'center',marginTop: '100px' }}>
    <h1 className="title title-lg" style={{ fontSize: '2em',fontFamily:'Sans-serif'}}>
 SEARCH AND RESCUE DRONES
    </h1>
    <img
      className="slide-icon"
      src={image}
      alt="img"
      style={{ height: '500px', width: '800px', margin: '20 auto' }}
    />
    </div>
    <div style={{ textAlign: 'center', padding: '5px', margin: '10px',backgroundColor:'#e5eaf5' }}>

    <p className="para-style">
                The utilization of drones in search and rescue operations yields multifaceted
                 benefits that extend beyond the welfare of those being rescued,encompassing enhanced
                 safety measures for rescue teams. Drones serve as invaluable tools by furnishing
                  critical preliminary information about the situation, thereby mitigating risks faced by
                  ground personnel before deployment.
                  In search and rescue scenarios, particularly in harsh environmental conditions,
                   such as extreme cold or heat, drones play a pivotal role in safeguarding the well-being
                   being of rescue teams. By obviating the need for prolonged exposure centerto freezing or
                    sweltering environments, drones significantly reduce the physical hazards
                    associated with such conditions.Moreover, in areas inhabited by dangerous 
                    wildlife species, drones facilitate risk mitigation by eliminating the necessity for individuals
                    to traverse potentially hazardous terrain, thereby ensuring the safety of rescue personnel.
                    Furthermore, drones excel in assisting rescue teams in locating individuals in challenging 
                    terrains, including near rivers with swift currents and obscured embankments. 
                    Their aerial perspective enables swift identification of individuals who may be 
                    obscured from ground view, thus streamlining search efforts and enhancing overall 
                    operational efficiency.
                    Overall, the integration of drones into search and rescue operations enables teams
                     to cover expansive terrain more safely,shielding them from environmental extremes,
                     hazardous bodies of water, and potentially perilous wildlife encounters.
                     
              </p>
 
 <br/>
                 </div>

                

                 </div> 
<div>
<footer style={{ fontSize: '10px', padding: '40px', backgroundColor: '#000068', color: '#000', width: '1500px', marginLeft: '0px' }}>
  <div className="d-flex justify-content-between" style={{ marginLeft: '100px', marginRight: '100px' }}>
    <div className="col-md-12 col-lg-4 col-mobile-down">
      <div className="mobile-collapse">
      <img className="top-bar__logo" src={image1} alt='logo' style={{ height: '180px', width: '180px', marginLeft: '30px',marginTop:'-40px' }} />

        <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white',fontFamily:'Sans-serif', }}>Contact Information</h4>
        <div className="pt-collapse-content playfair-display" style={{ color: 'white',fontFamily:'Sans-serif', fontSize: '12px' }}>
        <address className=' playfair-display'>
                    <div style={{ display: 'flex' }}>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ fill: 'white' }}>
    <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
  </svg>
  <span> # 70 B block
          1st floor, Anandnagar
          Mysore - 570022, Karnataka
          </span> 
                   </div>
                   <a className='playfair-display' href="mailto:pieflyaerospace@gmail.com" style={{ color: 'white', fontSize: '12px', display: 'flex', alignItems: 'center' }}>
  <span style={{ fontSize: '24px', color: 'white', marginRight: '8px' }}>
    <MdMailOutline />
  </span>
  Info@pieflyaerospace.com
</a>

                    </address>
        </div>
      </div>
    </div>

    <div className="col-md-3 col-lg-2">
      <div className="mobile-collapse" style={{ maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}>
        <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white',fontFamily:'Sans-serif', textAlign: 'center' }}>Quick Links</h4>
        <div className="pt-collapse-content" style={{ color: 'white',fontFamily:'Sans-serif', fontSize: '12px' }}>
          <div className="oval-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px', padding: '10px' }}>
            <div className="oval-box" style={{ width: '120px',fontFamily:'Sans-serif', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
              <a href="#" style={{ color: 'black', fontFamily:'Sans-serif',textAlign: 'center' }}><span className='playfair-display'>Home</span></a>
            </div>
            <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
              <a href="#" style={{ color: 'black',fontFamily:'Sans-serif', textAlign: 'center' }}><span className='playfair-display'>Training Program</span></a>
            </div>
            <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
              <a href="#" style={{ color: 'black',fontFamily:'Sans-serif', textAlign: 'center' }}><span className='playfair-display'>Enroll</span></a>
            </div>
            <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
              <a href="#" style={{ color: 'black',fontFamily:'Sans-serif', textAlign: 'center' }}><span className='playfair-display'>Shop</span></a>
            </div>
            <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
              <a href="#" style={{ color: 'black', fontFamily:'Sans-serif',textAlign: 'center' }}><span className='playfair-display'>Contacts</span></a>
            </div>
            <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
              <a href="#" style={{ color: 'black', fontFamily:'Sans-serif',textAlign: 'center' }}><span className='playfair-display'>Elements</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="col-md-3 col-lg-2">
      <div className="mobile-collapse" style={{ maxWidth: '300px', marginLeft: 'auto', marginRight: 'auto' }}>
        <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white',fontFamily:'Sans-serif', textAlign: 'center' }}>Useful Links</h4>
        <div className="pt-collapse-content" style={{ color: 'white',fontFamily:'Sans-serif', fontSize: '12px' }}>
          <div className="oval-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px', padding: '10px' }}>
            <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
              <a href="#" style={{ color: 'black',fontFamily:'Sans-serif', textAlign: 'center' }}><span className='playfair-display'>Products Documents</span></a>
            </div>
            <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
              <a href="#" style={{ color: 'black',fontFamily:'Sans-serif', textAlign: 'center' }}><span className='playfair-display'>Services Documents</span></a>
            </div>
            <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
              <a href="#" style={{ color: 'black',fontFamily:'Sans-serif', textAlign: 'center' }}><span className='playfair-display'>Products</span></a>
            </div>
            <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
              <a href="#" style={{ color: 'black',fontFamily:'Sans-serif', textAlign: 'center' }}><span className='playfair-display'>Blog and News</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="col-md-3 col-lg-2">
      <div className="mobile-collapse" style={{ maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}>
        <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white',fontFamily:'Sans-serif', textAlign: 'center' }}>Main Menu</h4>
        <div className="pt-collapse-content" style={{ color: 'white',fontFamily:'Sans-serif', fontSize: '12px' }}>
          <div className="oval-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px', padding: '10px' }}>
            <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
              <a href="#" style={{ color: 'black', fontFamily:'Sans-serif',textAlign: 'center' }}><span className='playfair-display'>About Us</span></a>
            </div>
            <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
              <a href="#" style={{ color: 'black',fontFamily:'Sans-serif', textAlign: 'center' }}><span className='playfair-display'>Gallery</span></a>
            </div>
            <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
              <a href="#" style={{ color: 'black',fontFamily:'Sans-serif', textAlign: 'center' }}><span className='playfair-display'>Projects</span></a>
            </div>
            <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
              <a href="#" style={{ color: 'black',fontFamily:'Sans-serif', textAlign: 'center' }}><span className='playfair-display'>Contacts</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
      <div className="footer-custom">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4">
              {/* <div className="copyright"style={{color: 'white',fontSize:'12px' }}>
                &copy; 2019 Quadron. All rights reserved.
              </div> */}
            </div>
          
           <div className='social-icons' style={{marginLeft:'600px',marginTop:'-20px'}}>
           <a className='social-icon-link whatsapp' href='https://wa.me/yourwhatsapplink' target='_blank' aria-label='WhatsApp' style={{ marginLeft: '-40px' }}>
<FontAwesomeIcon icon={faWhatsapp} />
</a>
  <a className='social-icon-link facebook' href='/' target='_blank' aria-label='Facebook'>
    <FontAwesomeIcon icon={faFacebookF} />
  </a>
  <a className='social-icon-link instagram' href='https://www.instagram.com/pieflyofficial?igsh=bmU0emdnbWs2aGIw' target='_blank' aria-label='Instagram' style={{marginLeft:'-40px'}}>
    <FontAwesomeIcon icon={faInstagram} />
  </a>
  <a className='social-icon-link twitter' href='https://x.com/pieflyofficial?t=RHOzsk0Vc47uIvKSCc5XVA&s=09' target='_blank' aria-label='Twitter' style={{marginLeft:'-40px'}}>
  <TfiTwitter />
  </a>
  <a className='social-icon-link linkedin' href='https://in.linkedin.com/company/piefly-r-d-private-limited' target='_blank' aria-label='LinkedIn' style={{marginLeft:'-40px'}}>
  <AiOutlineLinkedin />
  </a>
  <a className='social-icon-link Youtube' href='https://youtube.com/@piefly4302?si=Qecbd2nJ80cYx-Hk' target='_blank' aria-label='Youtube' style={{marginLeft:'-40px'}}>
  <BsYoutube />  </a>
</div>

              </div>
            </div>
          </div>
        
    </footer>
  </div>

                 </>


                );
};
export default Searchdrones;
