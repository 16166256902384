import React, { useState } from 'react';
import "./Register.css";

function Register() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleRegister = () => {
    // Perform registration logic here
    console.log('Registering...');
  };

  return (
    <div class="wrapperr">
      <div class="form-wrapperr sign-in">
        <form action="" style={{ border: 'none' }}>
          <div style={{ textAlign: 'center' , marginTop:'-30px'}}>
            <h2>Register</h2>
            <div class="input-group">
            <label>Username:</label>
            <input type="text" value={username} onChange={handleUsernameChange} />
            </div>
            
            <div class="input-group">
            <label>Password:</label>
            <input type="password" value={password} onChange={handlePasswordChange} />
            </div>
            
            <div class="input-group">
            <label>Confirm Password:</label>
            <input type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} />
            </div>
            
            <button onClick={handleRegister}>Register</button>
          </div>
        </form>
      </div>
    </div> 
  );
}

export default Register;
