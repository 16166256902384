import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./Dropdown.css";

function Dropdown1() {
  const [servicesDropdown, setServicesDropdown] = useState(false);

  const services = [
    { title: "Agriculture", link: "/agriculture" },
    { title: "Cell Tower", link: "/Celltower" },
    { title: "Powerline Inspection", link: "/powerline" },
    { title: "Topographical Survey", link: "/topography" },
    { title: "Anti Drone System", link: "/antidrone" },
    { title: "High Payload Carrying Drones", link: "/highpayload" },
    { title: "Traffic Monitoring", link: "/trafficmonitor" },
    { title: "Structural Inspection And Damage Assessment", link: "/structuralinspection" },
    { title: "Disaster Management", link: "/disaster" },
    { title: "Wind Turbine Inspection", link: "/windturbine" },
    { title: "Forest Management", link: "/forestmanagement" },
    { title: "Weather Forcasting Drones", link: "/weather" },
    { title: "Search And Rescue Drones", link: "/searchdrones" },
  ];

  const servicesInFirstColumn = services.slice(0, 7);
  const servicesInSecondColumn = services.slice(7, 14);

  useEffect(() => {
    if (servicesDropdown) {
      window.scrollTo(0, 0); // Scroll to top when dropdown is opened
    }
  }, [servicesDropdown]);

  const handleNavLinkClick = () => {
    setServicesDropdown(false);
    window.scrollTo(0, 0); // Scroll to top when a link is clicked
  };

  return (
    <>
      <ul
        className={servicesDropdown ? "services-submenu clicked" : "services-submenu"}
        onClick={(e) => {
          setServicesDropdown(!servicesDropdown);
          e.stopPropagation(); // Prevent the click event from propagating to the parent element
        }}
      >
        <div className="column">
          {servicesInFirstColumn.map((item, index) => (
            <li key={index}>
              <NavLink
                to={item.link}
                activeClassName="active-link"
                onClick={handleNavLinkClick}
              >
                <p>{item.title}</p>
              </NavLink>
            </li>
          ))}
        </div>
        <div className="column column-right">
          {servicesInSecondColumn.map((item, index) => (
            <li key={index}>
              <NavLink
                to={item.link}
                activeClassName="active-link"
                onClick={handleNavLinkClick}
              >
                <p>{item.title}</p>
              </NavLink>
            </li>
          ))}
        </div>
      </ul>
    </>
  );
}

export default Dropdown1;
