import React, { useState, useEffect, useRef } from 'react';
import image from '../svg-sprite/mainslider/celltower1.png';
import image1 from '../svg-sprite/mainslider/celltower.png';
import image2 from '../svg-sprite/mainslider/utilize2.png';
import image3 from '../svg-sprite/mainslider/utilize.png';
import image4 from '../svg-sprite/mainslider/inspection.png';
import image6 from '../svg-sprite/mainslider/pieflylogo.png';
import "./Dropdown.css";
import Navbar from './navbar';
import Footer from './Footer ';
import "./Celltower.css";
import { MdMailOutline } from "react-icons/md";
import { IoChevronForwardCircleOutline } from "react-icons/io5";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { TfiTwitter } from "react-icons/tfi";
import { AiOutlineLinkedin } from "react-icons/ai";
import { BsYoutube } from "react-icons/bs";
import Modal from './EnquiryModal'; // Assuming EnquiryModal component is in a file named EnquiryModal.js
import video1 from '../svg-sprite/mainslider/video3.mp4';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';


function Celltower() {
    const dropdownTimeoutRef = useRef(null);
    const dropdownRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleEnquireClick = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
  useEffect(() => {
    return () => {
      clearTimeout(dropdownTimeoutRef.current);
    };
  }, []);

  const containerStyles = {
    display: 'flex',
    alignItems: 'flex-start', // Aligns items at the start of the container
    justifyContent: 'flex-start', // Aligns elements to start without extra space
    padding: '20px',
    maxWidth: '1200px', // Max width to contain the flex items
    margin: '0 auto', // Center container on page
  };
  
  const flexContainerStyle = {
    display: 'flex',
    flexDirection: 'row', // Ensures the paragraph and image are side by side
    alignItems: 'stretch', // Aligns items to stretch full container height
    gap: '20px', // Adds consistent space between elements
  };
  
  const paraStyle = {
    flex: 1, // Takes up the available space
    margin: '0', // Resets margin
    padding: '10px', // Add padding if needed
    boxSizing: 'border-box', // Ensures padding is included in width calculations
  };
  
  const imageStyle = {
    height: 'auto', // Maintain aspect ratio
    width: '350px', // Set a fixed width for the image
    objectFit: 'cover', // Ensures the image covers its container
  };
  
  return (
    

    <>
    <div> <Navbar/> </div>

    <div>
      <div style={{ textAlign: 'center',marginTop: '80px'  }}>
 

  <br/>
<br/>
<br/>
  
<div className="video">
            <video width="100%"  autoPlay muted>
              <source src={video1} type="video/mp4" /> 
            </video>
          </div>
  <h1 className="title title-lg playfair-display" style={{ fontSize: '2em',marginTop:'-430px', color:'white',fontFamily:'Sans-serif' }}>
 CELL TOWER INSPECTIONS
  </h1>
  <br/><br/>
  <p className='playfair-display'
  style={{
    display: 'inline-flex', 
    textAlign: 'left',
    color: 'white',
    fontFamily:'Sans-serif',
    marginLeft: '0px',
    marginTop: '0px',
    fontSize: '25px',
    transition: 'transform 0.3s ease', // Add transition for smooth effect
    backgroundColor: '#013220', 
  borderRadius: '10px', 
  padding: '10px', 
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', 
  }}
  onMouseEnter={(e) => { e.target.style.transform = 'scale(1.05)'; }} // Zoom in effect
  onMouseLeave={(e) => { e.target.style.transform = 'scale(1)'; }} // Zoom out effect
>
  "Drones the need to place people in dangerous situations during cell tower inspections."
</p>
</div>
      </div>
      <br/>
      <br/><br/><br/><br/>
      
      <br/>
      <br/>
      <br/>
      <br/>
      <div style={containerStyles}>
      <div style={flexContainerStyle}>
        <p style={paraStyle} className="para-style">
          The Federal Communications Commission (FCC) mandates regular inspections of cell towers
          to ensure safety, maintain infrastructure integrity, and prolong operational life. Inspections
          are required for existing towers, both as part of routine maintenance and following natural
          disasters. Additionally, potential sites for new towers must undergo inspection. These
          inspections are labor-intensive and pose significant risks. The inspection checklist for a cell
          tower is comprehensive, covering the tower structure, electrical and lighting systems, guy
          wires, antennas, anchors, and transmission lines. Cell tower inspections are crucial for
          infrastructure preservation, encompassing everything from maintenance to monitoring and
          managing encroaching vegetation.
        </p>
        <img
          className="slide-icon"
          src={image1}
          alt="img"
          style={imageStyle}
        />
      </div>
    </div>


    <div className="containers">
  <div className="content-wrappers">
    <div className="text-wrapper">
      <div style={{ display: 'flex', alignItems: 'flex-start', margin: '20px 0' }}>
        <p className='parag-style' style={{ textAlign: 'left', color: 'white', fontFamily: 'Sans-serif', flex: 1, marginRight: '20px' }}>
          Cell tower inspections inherently pose risks to personnel conducting the assessments. Challenges related to scheduling and conflicts can lead to delays in        
          completing inspections. Significant planning is required, including weeks of travel, tower climbing, reporting, and analysis of findings. Manual inspections incur high costs in terms of labor and resources, such as safety equipment and insurance.
        </p>
        <img
          className="slide-icon"
          src={image2}
          alt="img"
          style={{ height: '300px', width: '450px', marginTop: '20px' }}
        />
      </div>
    </div>
  </div>
</div>


<br/><br/>
<div className="containers">
      <div className="content-wrappers">
        <div className="text-wrapper">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
  <p className='parag-style' style={{ textAlign: 'left', color: 'white', fontFamily: 'Sans-serif', width: '60%' }}>
    Implementing drone technology for inspections significantly enhances safety, as drones are operated securely from the ground. Drones also reduce inspection times from weeks to mere hours, enabling more efficient use of time and the 
    ability to inspect a greater number of towers. Our drone pilots have successfully conducted three cell tower inspections in a single day. 
    Drones gather and store historical data, providing valuable reference points for future analysis and aiding in the planning of subsequent infrastructure inspections. The data collected is promptly transmitted to the cell tower company, allowing 
    inspectors to make quick, informed decisions. Drones can perform thorough inspections, scanning components of the tower such as generators, gas and 
    electric meters, platforms, pedestals, cabinets, and transformers, identifying issues that may otherwise go unnoticed, such as loose bolts or rust that could lead to
    damage. Drone inspections also offer the advantage of ground observation around the tower. Drones provide a bird's-eye view that surpasses human 
    capabilities, allowing for the detection of potential hazards such as encroaching vegetation or debris that could cause disruptions or pose safety risks.
  </p>
  <img
    className="slide-icon"
    src={image3}
    alt="img"
    style={{ height: '300px', width: '400px', marginLeft: '20px' }}
  />
</div>

  </div>
</div>
</div>
<br/><br/>
<div style={{backgroundColor:'#bcbcbc'}}> 

<div style={{ textAlign: 'left',marginTop: '0px' ,marginLeft: '200px' }}>
  <br/>
  <h1 className="title title-lg playfair-display" style={{ fontSize: '40px',fontFamily:'Sans-serif' }}>
 CELL TOWER AUDIT
  </h1>
  <h2 className="title title-lg playfair-display" style={{ textAlign: 'left',fontSize: '25px',fontFamily:'Sans-serif',marginLeft: '400px' }}>
  MANUAL METHOD  v/s  DRONES
  </h2>
  </div>


  <p className='playfair-display' style={{ textAlign: 'left', color:'black', fontFamily:'Sans-serif', marginLeft: '200px' }}>
  In 2022, there were 7.4 lakh towers in India. And that number is steadily growing as the world 
  grows more and more connected. All of these towers must be
</p>
<p className='playfair-display' style={{ textAlign: 'left', color:'black', fontFamily:'Sans-serif', marginLeft: '200px' }}>
inspected every 3 to 5 years and then even more frequently as they get older.
</p>

<br/>
<p className='playfair-display' style={{ textAlign: 'left', color:'black', fontFamily:'Sans-serif', marginLeft: '200px'}}>
However traditional inspection methods have several drawbacks. And that’s why drone cell tower
inspections are quickly becoming an attractive alternative. 
</p>

<div  style={{ textAlign: 'left',marginTop: '100px' ,fontFamily:'Sans-serif', marginLeft: '450px' }}>
  <h3 className="title title-lg playfair-display" style={{ fontSize: '25px'}}>
  TRADITIONAL METHODS OF CELL TOWER INSPECTIONS
  </h3>
  <p className='playfair-display' style={{ textAlign: 'left', color:'black', fontFamily:'Sans-serif',  marginLeft: '-250px' }}>
  Traditional cell tower inspections are a highly dangerous job. Most towers are 100 to 300 feet (30 to 90
m) tall, and some even reach up to 1000 feet (300 m).
</p>

<p className='playfair-display' style={{ textAlign: 'left', color:'black', fontFamily:'Sans-serif', marginLeft: '-250px' }}>
So naturally, climbing up them presents some serious safety risks. Falling is most often deadly and the cell
 tower equipment gives off dangerous radiofreque
</p>

<p className='playfair-display' style={{ textAlign: 'left', color:'black', fontFamily:'Sans-serif', marginLeft: '-250px'}}>
-ncy  (RF) radiation. In fact, Traditional Methods of Cell Tower Inspections.
</p>
<br/>
<p className='playfair-display' style={{ textAlign: 'left', color:'black', fontFamily:'Sans-serif', marginLeft: '-250px' }}>
On top of that, a manual cell tower inspection is expensive, costing anywhere
from $2,000 to $5,000 per tower. It takes a highly skilled engineering team to
</p>

<p className='playfair-display' style={{ textAlign: 'left', color:'black', fontFamily:'Sans-serif', marginLeft: '-250px' }}>
operate a lot of heavy equipment that has to be brought on-site. Large teams
must be dispatched to slowly inspect miles of cell towers. Inevitably, they are
</p>

<p className='playfair-display' style={{ textAlign: 'left', color:'black', fontFamily:'Sans-serif',marginLeft: '-250px' }}>
forced to prioritize towers that have the greatest risk because they can’t get to
all the towers that need an inspection.
</p>
</div>
<br/>
  </div>
  <br/><br/>
  <div style={{backgroundColor:'black',marginTop: '0px'}}> 
  &emsp;
  <div style={{ textAlign: 'left',marginTop: '80px' ,marginLeft: '550px' }}>
  <img
        className="slide-icon"
        src={image4}
        alt="img"
        style={{
          height: '300px',
          width: '2050px',
          display: 'block',
          margin: '0 auto',
          marginTop: '-10px',
          marginLeft: '-250px',
        }}
      />
  <h4 className="title title-lg playfair-display" style={{ fontSize: '25px',color:'white',fontFamily:'Sans-serif',fontSize:'50px',marginTop:'-230px',marginLeft:'-240px'}}>
  DRONE INSPECTIONS <br/><br/>OF CELL TOWERS 
  </h4>
<br/><br/><br/><br/><br/><br/>

<div className="parent-containerb">
  <div className="background-boxb"></div>

  <div className="boxb">
      <p className="boldb playfair-display"><IoChevronForwardCircleOutline style={{ color: 'white', fontFamily:'Sans-serif', fontSize: '34px', marginLeft: '10px' }} />
                &emsp;
 Less Coastly
</p>

<p className='playfair-display' style={{ textAlign: 'left', color:'white',fontFamily:'Sans-serif', marginLeft: '50px' ,fontSize:'15px'}}>
Drone inspections are less costly because they need less manpower. Manual inspections require large teams of
technicians who are paid $95 
to $105 per hour plus travel expenses.But a single drone pilot can perform the
same task in a fraction of the time. 
<br/>

Unlike traditional inspections, drone inspections also allow cell towers to continue operating. This means asset
owners don’t lose any revenue  
through downtime. And since drones can perform more frequent inspections,
they can catch maintenance issues early on to prevent them from 

developing into costly problems later down
the road.
<br/>

Detailed drone inspection reports also make it easier for asset owners to file insurance and warranty claims,
which could save them thousands


of dollars. And because drone teams are smaller, they also have fewer health
costs to cover and can pass those savings on to asset owners.
When all is said and done, drones can cut inspection costs by up to 50%.</p>
</div>
</div>
<br/>
 
<div className="parent-containera">
  <div className="background-boxa"></div>

  <div className="boxa">
      <p className="bolda playfair-display"><IoChevronForwardCircleOutline style={{ color: 'white',fontFamily:'Sans-serif', fontSize: '34px', marginLeft: '10px' }} />

&emsp; More Detailed Inspections
</p>

<p className='playfair-display' style={{ textAlign: 'left', color:'white',fontFamily:'Sans-serif', marginLeft: '50px' ,fontSize:'15px'}}>
Drones also perform highly detailed inspections. For example, in addition to checking the integrity of a
tower and identifying hazards from vegetation or animals, they can record the tiniest information, like the
model and serial numbers of antennas. 


<br/>
Drones also provide valuable inspection data with the help of the following different types of payloads: 


<br/>
• 4K RGB cameras that offer high-resolution images and streamable video footage.

<br/>
• Thermal sensors that deliver radiometric data for exact temperatures and heat maps to identify hot spots.

<br/>
• Light Detection and Ranging (LiDAR) lasers that provide geospatial data to help generate highly accurate point clouds topographic maps.

<br/>
Together, these cameras and sensors can provide a lot of actionable information. And when a drone captures
enough overlapping footage from 
multiple  angles, mapping software can use photogrammetry to turn the
data into valuable 3D maps as well.</p>
</div>
</div>
<br/><br/><br/>
<div className="parent-container">
  <div className="background-box"></div>

  <div className="box">
      <p className="bold playfair-display"><IoChevronForwardCircleOutline style={{ color: 'white', fontFamily:'Sans-serif',fontSize: '34px', marginLeft: '10px' }} />
                &emsp; Reduced Risk</p>
      <p className='playfair-display' style={{ fontSize:'15px'}}>
        Drones have the potential to save lives. They can easily assess the
        condition of every component of a cell tower from the air and different
        angles.
      
        Inspectors can then watch the livestream on a tablet from the ground.
        This eliminates both the risk of climbers falling to the ground and the
        risk of
      
        tools being dropped and breaking or hurting someone. It also minimizes
        workers’ exposure to hazardous radiofrequency (RF) radiation.
      
      <br />
      
        At the same time, the drone itself is protected from damage through
        collision avoidance, geofencing, and real-time kinematic (RTK)
        positioning technology. So switching to drone cell tower inspections minimizes the
        risk of costly damage to the cell tower and expensive equipment.
      </p>
    </div>
    </div>
<br/>
<div className="parent-containerc">
  <div className="background-boxc"></div>

  <div className="boxc">
      <p className="boldc playfair-display"><IoChevronForwardCircleOutline style={{ color: 'white', fontFamily:'Sans-serif',fontSize: '34px', marginLeft: '10px' }} />
                &emsp; Faster Inspection Times
</p>

<p className='playfair-display' style={{ textAlign: 'left', color:'white',fontFamily:'Sans-serif', marginLeft: '50px' }}>
A manual inspection takes over 6 hours per cell tower. This is because each inspection requires multiple safety checks,
bringing heavy equipment on site, and carefully climbing tall towers one by one But with drones, you can significantly
lower inspection times by automating workflows. 
 Data capture, 3D modelling, analytics, and reports can all be
automated to streamline the inspection process. For example, drone pilots can dial in predetermined flight routes to let
the drone fly on its own while they monitor it to make sure everything runs smoothly. This makes inspections incredibly fast and easy. </p>
</div>
</div>
<br/>
<div className="parent-containerd">
  <div className="background-boxd"></div>

  <div className="boxd">
      <p className="boldd playfair-display"><IoChevronForwardCircleOutline style={{ color: 'white',fontFamily:'Sans-serif', fontSize: '34px', marginLeft: '10px' }} />
                &emsp;  Easily Scalable
</p>

<p className='playfair-display' style={{ textAlign: 'left', color:'white',fontFamily:'Sans-serif', marginLeft: '50px' }}>
Drone inspections are very scalable. You can easily increase the number of towers you inspect by
programming the desired inspection route and 
executing it over and over again across several cell
towers.<br/>
This also allows you to benefit from economies of scale. The more cell tower inspections you
outsource to a drone company, the more efficient 
the process becomes and the more you’ll save
over time. </p>

</div></div>
<br/>
  </div>
</div>
  
          
 <br/><br/>
<footer style={{ fontSize: '10px', padding: '40px', backgroundColor: '#000068', color: '#000', width: '1500px', marginLeft:'150px' }}>
      <div className="footer-col">
        <div className="container">
          <div className="list-col">
            <div className="row">
              <div className="col-md-12 col-lg-4 col-mobile-down">
              <div className="mobile-collapse">
                <img className="top-bar__logo" src={image6} alt='logo' style={{ height: '180px', width: '180px', marginLeft: '30px',marginTop:'-40px' }} />

                <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white' }}>Contact Information</h4>
                &emsp;
                  <div className="pt-collapse-content  playfair-display"style={{  color: 'white',fontSize:'12px' }}>
                    <address className=' playfair-display'>
                    <div style={{ display: 'flex' }}>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ fill: 'white' }}>
    <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
  </svg>
  <span> # 70 B block
          1st floor, Anandnagar
          Mysore - 570022, Karnataka
          </span> <br/> 
                   </div>
                   <a className='playfair-display' href="mailto:pieflyaerospace@gmail.com" style={{ color: 'white', fontSize: '12px', display: 'flex', alignItems: 'center' }}>
  <span style={{ fontSize: '24px', color: 'white', marginRight: '8px' }}>
    <MdMailOutline />
  </span>
  Info@pieflyaerospace.com
</a>

                    </address>
                          
                  </div>
                </div>
              </div>
              {/* <div className="col-md-3 col-lg-2" style={{ marginLeft: 'auto', marginRight: '100px' }}> */}
  <div className="mobile-collapse" style={{ maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}>
    <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white', fontFamily:'Sans-serif',textAlign: 'center' }}>Quick Links</h4>
    <div className="pt-collapse-content" style={{ color: 'white', fontFamily:'Sans-serif',fontSize: '12px' }}>
      <div className="oval-grid" style={{fontFamily:'Sans-serif', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '20px', padding: '10px' }}>
        <div className="oval-box" style={{ fontFamily:'Sans-serif',width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="#" style={{ fontFamily:'Sans-serif',color: 'black', textAlign: 'center' }}><span className='playfair-display'>Home</span></a>
        </div>
        <div className="oval-box" style={{fontFamily:'Sans-serif', width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="/#/training" style={{ fontFamily:'Sans-serif',color: 'black', textAlign: 'center' }}><span className='playfair-display'>Training Program</span></a>
        </div>
        <div className="oval-box" style={{ fontFamily:'Sans-serif',width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="#" style={{fontFamily:'Sans-serif', color: 'black', textAlign: 'center' }}><span className='playfair-display'>Products</span></a>
        </div>
        <div className="oval-box" style={{ fontFamily:'Sans-serif',width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="/#/contactus" style={{ fontFamily:'Sans-serif',color: 'black', textAlign: 'center' }}><span className='playfair-display'>Contact Us</span></a>
        </div>
        <div className="oval-box" style={{ fontFamily:'Sans-serif',width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Services</span></a>
        </div>
        <div className="oval-box" style={{fontFamily:'Sans-serif', width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>About Us</span></a>
        </div>
      </div>
    </div>
  </div>
</div>
         </div>
          </div>
        </div>
      {/* </div> */}

      <div className="footer-custom">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4">
              {/* <div className="copyright"style={{color: 'white',fontSize:'12px' }}>
                &copy; 2019 Quadron. All rights reserved.
              </div> */}
            </div>
           
                <div className='social-icons' style={{marginLeft:'600px',marginTop:'30px'}}>
                <a className='social-icon-link whatsapp' href='https://wa.me/yourwhatsapplink' target='_blank' aria-label='WhatsApp' style={{ marginLeft: '-40px' }}>
  <FontAwesomeIcon icon={faWhatsapp} />
  </a>
  <a className='social-icon-link facebook' href='/' target='_blank' aria-label='Facebook'>
    <FontAwesomeIcon icon={faFacebookF} />
  </a>
  <a className='social-icon-link instagram' href='https://www.instagram.com/pieflyofficial?igsh=bmU0emdnbWs2aGIw' target='_blank' aria-label='Instagram' style={{marginLeft:'-40px'}}>
    <FontAwesomeIcon icon={faInstagram} />
  </a>
  <a className='social-icon-link twitter' href='https://x.com/pieflyofficial?t=RHOzsk0Vc47uIvKSCc5XVA&s=09' target='_blank' aria-label='Twitter' style={{marginLeft:'-40px'}}>
  <TfiTwitter />
  </a>
  <a className='social-icon-link linkedin' href='https://in.linkedin.com/company/piefly-r-d-private-limited' target='_blank' aria-label='LinkedIn' style={{marginLeft:'-40px'}}>
  <AiOutlineLinkedin />
  </a>
  <a className='social-icon-link Youtube' href='https://youtube.com/@piefly4302?si=Qecbd2nJ80cYx-Hk' target='_blank' aria-label='Youtube' style={{marginLeft:'-40px'}}>
  <BsYoutube />  </a>
</div>

              </div>
            </div>
          </div>
        
    </footer>
    
    
    </>
    
  )
}

export default Celltower;


