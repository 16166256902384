import React, { useState, useEffect, useRef } from 'react';
import Footer from './Footer ';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import image from '../svg-sprite/mainslider/pieflylogo.png';
import img from '../svg-sprite/mainslider/pieflylogo.png';
import Sidebar from './Sidebar';
import "./Dropdown.css";
// import image2 from '../svg-sprite/mainslider/backgroundimage.png';
import './Aboutt.css'
import { MdCall } from "react-icons/md";
import Dropdown3 from './Dropdown3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { TfiTwitter } from "react-icons/tfi";
import { AiOutlineLinkedin } from "react-icons/ai";
import { BsYoutube } from "react-icons/bs";
import Modal from './EnquiryModal'; // Assuming EnquiryModal component is in a file named EnquiryModal.js
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';


const Aboutt = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const dropdownTimeoutRef = useRef(null);
    const dropdownRef = useRef(null);
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [userData, setUserData] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [dropdown1, setDropdown1] = useState(false);
    const [dropdown3, setDropdown3] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    const handleEnquireClick = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
  useEffect(() => {
    return () => {
      clearTimeout(dropdownTimeoutRef.current);
    };
  }, []);
  

    const handleEnrollMouseEnter = (e) => {
      e.target.style.borderColor = 'white';
      e.currentTarget.style.transform = 'scale(1.05)'; // Zoom in effect
      setDropdown3(true);
    };
  
    const handleEnrollMouseLeave = (e) => {
      e.target.style.borderColor = 'transparent';
      e.currentTarget.style.transform = 'scale(1)'; // Zoom out effect
  
      setDropdown3(false);
    };

    const handleRPTOMouseEnter = (e) => {
      e.target.style.borderColor = 'white';
    };
  
    const handleRPTOMouseLeave = (e) => {
      e.target.style.borderColor = ' transparent';
    };
  
    const handleAboutUsMouseEnter = (e) => {
      e.target.style.borderColor = ' white';
     
    };
  
    const handleAboutUsMouseLeave = (e) => {
      e.target.style.borderColor = 'transparent';
      
    };
  
    const handleNewsMouseEnter = (e) => {
      e.target.style.borderColor = ' white';
     
    };
  
    const handleNewsMouseLeave = (e) => {
      e.target.style.borderColor = 'transparent';
      
    };
  
    const handleArticlesMouseEnter = (e) => {
      e.target.style.borderColor = ' white';
     
    };
  
    const handleArticlesMouseLeave = (e) => {
      e.target.style.borderColor = 'transparent';
      
    };
  
    const handleContactUsMouseEnter = (e) => {
      e.target.style.borderColor = ' white';
     
    };
  
    
    const handleMouseEnter = () => {
      clearTimeout(dropdownTimeoutRef.current);
      setDropdown(true);
      setDropdown1(false); // Close the other dropdown
    };
  
    const handleMouseLeave = () => {
      clearTimeout(dropdownTimeoutRef.current);
      dropdownTimeoutRef.current = setTimeout(() => {
        setDropdown(false);
      }, 3500);
    };

    const [dropdownOpen, setDropdownOpen] = useState(false);
 const [isHovered, setIsHovered] = useState(false);

    // Apply styles based on hover state
    const liStyles = {
      transition: 'transform 0.3s ease', // Add transition for smooth effect
      transform: isHovered ? 'scale(1.05)' : 'scale(1)', // Zoom in/out effect
    };
    const handleMouseEnter2 = () => {
      setIsHovered(true);
      setDropdownOpen(true);
    };
  
    const handleMouseLeave2 = () => {
      setIsHovered(false);
      setDropdownOpen(false);
    }; 

    const handleContactUsMouseLeave = (e) => {
      e.target.style.borderColor = 'transparent';
      
    };
  
    const handleLogin = () => {
      // Assume successful login, set isLoggedIn to true and set user data
      setLoggedIn(true);
      setUserData(/* user data object */);
    };


    const [scrolledUp, setScrolledUp] = useState(true);

    useEffect(() => {
      const handleScroll = () => {
        const isScrolledUp = window.scrollY === 0;
        setScrolledUp(isScrolledUp);
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
        clearTimeout(dropdownTimeoutRef.current);

      };
    }, []);
    <br/>


    return (
<div> 

 <div className="page-backgroundd">
 <div>
      &emsp;&emsp;&emsp;
    {/* Start Header */}
    <header id="top-bar" style={{ background: '#000068', textAlign: 'center',fontFamily:'Sans-serif'}}>
        <div className="container-fluid">
          <div className="row justify-content-between no-gutters">
          <div className="col-auto side-col d-flex align-items-center text-nowrap" style={{ margin: '0 auto' }}>
              <img className="top-bar__logo" src={image} alt='logo' style={{ height: '160px', width: '220px', marginLeft: '0px',marginTop:'10px' }} />
              &emsp;&emsp;&emsp;&emsp;
              &emsp;&emsp;&emsp;&emsp;              &emsp;&emsp;&emsp;&emsp;
              &emsp;&emsp;&emsp;&emsp;
              &emsp;&emsp;&emsp;&emsp;

              <div className="col-auto " style={{ fontSize: 'small', color: 'white', textAlign: 'center' }}>
                <span className='playfair-display' style={{ fontWeight: 'bold',marginLeft:'34px',fontSize:'40px',fontFamily:
'Sans-serif' }}>PIEFLY AEROSPACE </span> <br />
              </div>
              &emsp;&emsp;&emsp;&emsp;
              &emsp;&emsp;&emsp;&emsp;            
              &emsp;&emsp;&emsp;&emsp;
              &emsp;&emsp;&emsp;&emsp;             
              &emsp;

<div className="col-auto">
  <Link to="/login" onMouseEnter={(e) => e.currentTarget.querySelector('button').style.transform = 'scale(1.05)'} onMouseLeave={(e) => e.currentTarget.querySelector('button').style.transform = 'scale(1)'}>
    <button className='playfair-display'style={{fontFamily:'Sans-serif', background: '#B6D0E2', fontSize: '15px', color: 'black', padding: '0px 30px', fontWeight: 'bold', borderRadius: '5px', cursor: 'pointer', transition: 'transform 0.3s' }}>
      Login
    </button>
  </Link>
</div>

          {/* End login button */}
           </div>
          </div>
          </div>
          </header>

   

          <Sidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(!isSidebarOpen)} />
{/* Add some spacing between Sidebar and Navbar */}
<div style={{ width: '20px' }}></div>

<div
      style={{
        background:  'white', // Change background color based on scroll position
                padding: '10px',
        marginTop: '100px',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center',
        fontFamily:'Sans-serif', // Center navbar items horizontally
      }}
    >
      <ul
        style={{
          display: 'flex',
          alignItems: 'center',
          listStyle: 'none',
          padding: 0,
        }}
      >
<li style={{ marginRight: '10px', marginLeft: '50px' }} onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'} onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}>
        <a
        href="/#/rpto"
        style={{
          color: 'black',
          textDecoration: 'none',
          position: 'relative',
          fontWeight:'bold'
        }}
        onMouseEnter={handleRPTOMouseEnter}
        onMouseLeave={handleRPTOMouseLeave}
      >
        <span className='playfair-display'
          style={{
            position: 'relative',
            borderBottom: '4px solid transparent',
            paddingBottom: '12px',
            marginRight: '5px',
            transition: 'border-color 0.3s ease',
            fontSize:'15px',
            fontFamily:'Sans-serif',
          }}
          onMouseOver={(e) => e.target.style.borderColor = 'black'}
    onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
        >
         HOME
        
        </span>
      </a>
  <Link to="/#/rpto" style={{ color: 'white', textDecoration: 'none' }}></Link>
</li>

&emsp; &emsp;
  &emsp;
  <li  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'} onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}>
          <a
          href="/#/training"
          style={{
            color: 'black',
            textDecoration: 'none',
            position: 'relative',
            fontWeight:'bold'
          }}
          onMouseEnter={handleRPTOMouseEnter}
          onMouseLeave={handleRPTOMouseLeave}
        >
          <span className='playfair-display'
           style={{
            position: 'relative',
            borderBottom: '4px solid transparent',
            paddingBottom: '12px',
            marginRight: '5px',
            transition: 'border-color 0.3s ease',
            fontSize:'15px',
            fontFamily:'Sans-serif',
            }}
            onMouseOver={(e) => e.target.style.borderColor = 'black'}
      onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
          >
           TRAINING PROGRAM
          
          </span>
        </a>
    <Link to="/#/training" style={{ color: 'white', textDecoration: 'none' }}></Link>
  </li>
  &emsp; &emsp; 
  &emsp;
  <li  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'} onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}>
  <a
        href="/#/aboutt"
        style={{
          color: 'black',
          textDecoration: 'none',
          position: 'relative',
          fontWeight:'bold'
        }}
        onMouseEnter={handleAboutUsMouseEnter}
        onMouseLeave={handleAboutUsMouseLeave}
      >
        <span className='playfair-display'
          style={{
            position: 'relative',
            borderBottom: '4px solid transparent',
            paddingBottom: '12px',
            marginRight: '5px',
            transition: 'border-color 0.3s ease',
                fontSize:'15px',
                fontFamily:'Sans-serif',
          }}
          onMouseOver={(e) => e.target.style.borderColor = 'black'}
          onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
        >
          ABOUT US
          
        </span>
      </a>
    <Link to="/aboutt" style={{ color: 'white', textDecoration: 'none' }}></Link>
  </li>
  &emsp; &emsp; 
  &emsp;
 
 
  <li  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'} onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}>
  <a
        
        style={{
          color: 'black',
          textDecoration: 'none',
          position: 'relative',
          fontWeight:'bold',
          cursor:'pointer'
        }}
        onMouseEnter={handleEnrollMouseEnter}
        onMouseLeave={handleEnrollMouseLeave}
      >
        <span className='playfair-display'
          style={{
            position: 'relative',
            borderBottom: '4px solid transparent',
            paddingBottom: '12px',
            marginRight: '5px',
            transition: 'border-color 0.3s ease',
                fontSize:'15px',
                fontFamily:'Sans-serif',
          }}
          onMouseOver={(e) => e.target.style.borderColor = 'black'}
          onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
        >
          ENROLL
          {dropdown3 && <Dropdown3 />}

        </span>
      </a>
  </li>
  &emsp; &emsp; &emsp; 
  
  
  <li  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'} onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}>
  <a
        href="/#/studentlogin"
        style={{
          color: 'black',
          textDecoration: 'none',
          position: 'relative',
          fontWeight:'bold'
        }}
        onMouseEnter={handleArticlesMouseEnter}
        onMouseLeave={handleArticlesMouseLeave}
      >
        <span className='playfair-display'
          style={{
            position: 'relative',
            borderBottom: '4px solid transparent',
            paddingBottom: '12px',
            
            transition: 'border-color 0.3s ease',
                fontSize:'15px',
                fontFamily:'Sans-serif',
          }}
          onMouseOver={(e) => e.target.style.borderColor = 'black'}
          onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
        >
          STUDENT LOGIN
          
        </span>
      </a>
    <Link to="/studentlogin" style={{ color: 'white', textDecoration: 'none' }}></Link>
  </li>
  &emsp; &emsp; &emsp; 
 
  <li style={liStyles}>
  <a
    style={{
      color: 'black',
      textDecoration: 'none',
      position: 'relative',
      fontWeight: 'bold',
      marginRight: '10px',
    }}
    onMouseEnter={handleMouseEnter2}
    onMouseLeave={handleMouseLeave2}
  >
    <span className='playfair-display'
      style={{
        position: 'relative',
        borderBottom: '4px solid transparent',
        paddingBottom: '12px',
        transition: 'border-color 0.3s ease',
        cursor: 'pointer',
                fontSize:'15px',
                fontFamily:'Sans-serif',
      }}
      onMouseOver={(e) => e.target.style.borderColor = 'black'}
      onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
    >
      MORE
    </span>
  </a>
  {dropdownOpen && (
    <div
      style={{
        position: 'absolute',
        top: 'calc(100% + 10px)', // Adjusted top value for additional spacing
        right: '-30px',
        backgroundColor: '#fff', // Dropdown background color
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Dropdown shadow
        borderRadius: '5px', // Dropdown border radius
        zIndex: '999', // Ensure dropdown appears above other content
        width: '150px', // Set dropdown width to 100%
        boxShadow: '0 0 10px rgb(232, 222, 222)', /* Adjust shadow color and size as needed */
        transition: 'transform 0.3s ease', // Add transition for smooth effect
        transform: isHovered ? 'scale(1.05)' : 'scale(1)', // Zoom in/out effect
        fontFamily:'Sans Serif',
                fontSize:'15px'
      }}
      onMouseEnter={handleMouseEnter2} // Keep dropdown open when mouse is over it
      onMouseLeave={handleMouseLeave2} // Close dropdown when mouse leaves it
    >
      {/* Dropdown content */}
      <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
        <li style={{ marginBottom: '0px', marginLeft: '15px' }}>
          <a className='playfair-display' href="/#/news" style={{ textDecoration: 'none', color: '#333' }}>
            NEWS
          </a>
        </li>
        <li style={{ marginBottom: '0px', marginLeft: '15px' }}>
          <a className='playfair-display' href="/#/contactus1" style={{ textDecoration: 'none', color: '#333' }}>
            CONTACT US
          </a>
        </li>
        {/* Add more dropdown items as needed */}
      </ul>
    </div>
  )}
</li>

</ul>                       
     
    </div> 
       

        <div
        className="a5-size-form"
        style={{
          width: '30cm',
          height: '5cm',
          margin: 'auto',
          padding: '1cm',
          position: 'relative',
        }}
      >
      <div
        className="section__indent-05"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <div className="container">
            <div className="col-sm-12">
                <div className="description">
                


              <p className='playfair-display' style={{ textAlign: 'center', fontFamily:'Sans-serif',fontWeight: 'bold', color: 'white' }}>ABOUT THE PIEFLY AVIATION RESEARCH CENTRE[PFARC]</p>
                </div>


              </div>
        
             <div>
             <p > 
             <h1 className='playfair-display' style={{ textAlign: 'center',fontFamily:'Sans-serif', fontWeight: 'bold', color: 'white' }}>ABOUT US</h1>
</p>

              <p className='playfair-display' style={{ textAlign: 'center',fontFamily:'Sans-serif', color: 'white', fontSize:'18px' }}>
              "In 2020, the Centre for PIEFLY AVIATION RESEARCH CENTRE (PFARC) was established at SJCE (STEP),
               JSS SCIENCE AND TECHNOLOGY University, Mysore, as an autonomous research center dedicated to aerospace studies. 
               Over the past three years, 
              PFARC has focused extensively on research and development in Unmanned Aerial Vehicles (UAVs), 
              emerging as a leader in drone-based societal applications.
              Recently, PFARC achieved another significant milestone by becoming one of India's Remote Pilot Training Organizations (RPTO) under the UAS Rules 2021. 
              Registered under the Ministry of Civil Aviation and approved by the Directorate General of Civil Aviation, PFARC's RPTO aims to
               provide professional and comprehensive training to eligible candidates to fulfill the increasing demand for UAV operators nationwide
              PFARC RPTO is committed to offering attractive training schemes and concessions, making it an ideal platform for students from diverse
               backgrounds seeking to enter the field of UAV operation."

              </p></div>
              
          <div style={{ textAlign: 'center', padding: '40px', margin: '50px' }}>
</div>
        
 
</div>
</div>
</div>


    </div>

</div>
<div>
<footer style={{ 
    fontSize: '10px',
    fontFamily: 'Sans-serif', 
    padding: '10px', // Reduced padding for a more compact look
    backgroundColor: '#000068', 
    color: '#fff', 
    width: '100%', 
    margin: '0 auto',
    boxSizing: 'border-box'
}}>
    <div className="d-flex justify-content-between" style={{ padding: '0 20px' }}>
        <div style={{ margin: '0', padding: '0' }}></div>
        <div style={{ margin: '0', padding: '0' }}></div>
           <div className="col-md-12 col-lg-4 col-mobile-down">
        <div className="mobile-collapse">
        <img className="top-bar__logo" src={img} alt='logo' style={{ height: '150px', width: '150px', marginLeft: '-10px',marginTop:'10px' }} />

          <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white' }}>Contact Information</h4>
          <div className="pt-collapse-content playfair-display" style={{ color: 'white',fontFamily:'Sans-serif',  fontSize: '12px' }}>
            <address className='playfair-display'>
              <span># 70 </span>B block <span>1st</span> floor Anandnagar Mysore<span> 570022</span> Karnataka<br />
              <a className='playfair-display' href="mailto:pieflyaerospace@gmail.com" style={{ color: 'white', fontFamily:'Sans-serif', fontSize: '12px' }}>Info@pieflyaerospace.com</a>
            </address>
          </div>
        </div>
      </div>
  
      <div className="col-md-3 col-lg-2">
        <div className="mobile-collapse" style={{ maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}>
          <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white', fontFamily:'Sans-serif', textAlign: 'center' }}>Quick Links</h4>
          <div className="pt-collapse-content" style={{ color: 'white',fontFamily:'Sans-serif',  fontSize: '12px' }}>
            <div className="oval-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px', padding: '10px' }}>
              <div className="oval-box" style={{ width: '120px', fontFamily:'Sans-serif', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black',fontFamily:'Sans-serif',  textAlign: 'center' }}><span className='playfair-display'>Home</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px',fontFamily:'Sans-serif',  height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black',fontFamily:'Sans-serif',  textAlign: 'center' }}><span className='playfair-display'>Training Program</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px',fontFamily:'Sans-serif',  height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', fontFamily:'Sans-serif', textAlign: 'center' }}><span className='playfair-display'>Enroll</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px',fontFamily:'Sans-serif',  height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', fontFamily:'Sans-serif', textAlign: 'center' }}><span className='playfair-display'>Shop</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px',fontFamily:'Sans-serif',  borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black',fontFamily:'Sans-serif',  textAlign: 'center' }}><span className='playfair-display'>Contacts</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px',fontFamily:'Sans-serif',  height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', fontFamily:'Sans-serif', textAlign: 'center' }}><span className='playfair-display'>Elements</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div className="col-md-3 col-lg-2">
        <div className="mobile-collapse" style={{ maxWidth: '300px', marginLeft: 'auto', marginRight: 'auto' }}>
          <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white',fontFamily:'Sans-serif',  textAlign: 'center' }}>Useful Links</h4>
          <div className="pt-collapse-content" style={{ color: 'white',fontFamily:'Sans-serif',  fontSize: '12px' }}>
            <div className="oval-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px', padding: '10px' }}>
              <div className="oval-box" style={{ width: '120px', fontFamily:'Sans-serif', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', fontFamily:'Sans-serif', textAlign: 'center' }}><span className='playfair-display'>Products Documents</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px',fontFamily:'Sans-serif',  height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black',fontFamily:'Sans-serif',  textAlign: 'center' }}><span className='playfair-display'>Services Documents</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', fontFamily:'Sans-serif', textAlign: 'center' }}><span className='playfair-display'>Products</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black',fontFamily:'Sans-serif',  textAlign: 'center' }}><span className='playfair-display'>Blog and News</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div className="col-md-3 col-lg-2">
        <div className="mobile-collapse" style={{ maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}>
          <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white', textAlign: 'center' }}>Main Menu</h4>
          <div className="pt-collapse-content" style={{ color: 'white', fontSize: '12px' }}>
            <div className="oval-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px', padding: '10px' }}>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', fontFamily:'Sans-serif', textAlign: 'center' }}><span className='playfair-display'>About Us</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black',fontFamily:'Sans-serif',  textAlign: 'center' }}><span className='playfair-display'>Gallery</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black',fontFamily:'Sans-serif',  textAlign: 'center' }}><span className='playfair-display'>Projects</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', fontFamily:'Sans-serif', textAlign: 'center' }}><span className='playfair-display'>Contacts</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        <div className="footer-custom">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-4">
                {/* <div className="copyright"style={{color: 'white',fontSize:'12px' }}>
                  &copy; 2019 Quadron. All rights reserved.
                </div> */}
              </div>
            
             <div className='social-icons' style={{marginLeft:'600px',marginTop:'-20px'}}>
             <a className='social-icon-link whatsapp' href='https://wa.me/yourwhatsapplink' target='_blank' aria-label='WhatsApp' style={{ marginLeft: '-40px' }}>
  <FontAwesomeIcon icon={faWhatsapp} />
  </a>
    <a className='social-icon-link facebook' href='/' target='_blank' aria-label='Facebook'>
      <FontAwesomeIcon icon={faFacebookF} />
    </a>
    <a className='social-icon-link instagram' href='https://www.instagram.com/pieflyofficial?igsh=bmU0emdnbWs2aGIw' target='_blank' aria-label='Instagram' style={{marginLeft:'-40px'}}>
      <FontAwesomeIcon icon={faInstagram} />
    </a>
    <a className='social-icon-link twitter' href='https://x.com/pieflyofficial?t=RHOzsk0Vc47uIvKSCc5XVA&s=09' target='_blank' aria-label='Twitter' style={{marginLeft:'-40px'}}>
    <TfiTwitter />
    </a>
    <a className='social-icon-link linkedin' href='https://in.linkedin.com/company/piefly-r-d-private-limited' target='_blank' aria-label='LinkedIn' style={{marginLeft:'-40px'}}>
    <AiOutlineLinkedin />
    </a>
    <a className='social-icon-link Youtube' href='https://youtube.com/@piefly4302?si=Qecbd2nJ80cYx-Hk' target='_blank' aria-label='Youtube' style={{marginLeft:'-40px'}}>
    <BsYoutube />  </a>
  </div>
  
                </div>
              </div>
            </div>
          
      </footer>
</div>
           </div>
          
  );
};

export default Aboutt;
