import React, { useState, useEffect, useRef } from 'react';
import image from '../svg-sprite/mainslider/pieflydroneimage.jpg'
import image1 from '../svg-sprite/mainslider/pieflylogo.png'
import "./Dropdown.css";
import Navbar from './navbar';
import Footer from './Footer ';
import Modal from './EnquiryModal'; // Assuming EnquiryModal component is in a file named EnquiryModal.js
import { IoIosArrowForward } from "react-icons/io";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { TfiTwitter } from "react-icons/tfi";
import { AiOutlineLinkedin } from "react-icons/ai";
import { BsYoutube } from "react-icons/bs";
import { MdMailOutline } from "react-icons/md";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';


const Pieflykisandrones = () => {
    const dropdownTimeoutRef = useRef(null);
    const dropdownRef = useRef(null);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleEnquireClick = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
  useEffect(() => {
    return () => {
      clearTimeout(dropdownTimeoutRef.current);
    };
  }, []);
  return (

    <>
    <div> <Navbar/> </div>

    <div>
      <div style={{ textAlign: 'center',marginTop: '200px'  }}>
  <h1 className="title title-lg playfair-display " style={{fontFamily: 'Sans-serif', fontSize: '1.5em' }}>
  PIEFLY  KISAN  DRONES
  </h1>
  <br/>
  <img
    className="slide-icon"
    src={image}
    alt="img"
    style={{ fontFamily: 'Sans-serif',height: '500px', width: '1200px', margin: '0 auto' }}
  />
</div>
<button className='playfair-display' style={{ fontFamily: 'Sans-serif',display: 'block', margin: '50px auto', width: '200px', padding:'0px' }} onClick={handleEnquireClick}>Enquire Now <IoIosArrowForward /></button>
  <Modal isOpen={isModalOpen} onClose={closeModal} />     </div>
  <div style={{ textAlign: 'center', padding: '5px', margin: '10px',backgroundColor:'#e5eaf5' }}>

  <p className="para-style">
    Revolutionizing the agricultural landscape, PIEFLY Kisan Drones stand at the forefront of cutting-edge technology,
    poised to redefine industry standards. With an expansive coverage area that surpasses conventional methods by more than double, these drones offer a compelling
    solution for maximizing efficiency in agricultural operations.
</p>
<br/>
<p className="para-style">
    A standout feature of PIEFLY Kisan Drones is their unparalleled cost-effectiveness, presenting a game-changing
    alternative to manual labor. The economic advantages are further underscored by their ability to optimize water usage, facilitating efficient spraying techniques that
    contribute to substantial savings.
</p>
<br/>
<p className="para-style">
    Moreover, the operational costs associated with PIEFLY Kisan Drones are notably lower, positioning them as a
    financially prudent choice for forward-thinking agricultural enterprises. In embracing these state-of-the-art drones, the agricultural industry stands to benefit from a harmonious
    blend of innovation, efficiency, and economic viability.
</p>
      <div style={{ textAlign: 'center' }}>
        <br />
        <br />
 <br/>

 </div>
               
    </div>
    <footer style={{ fontFamily: 'Sans-serif',fontSize: '10px', padding: '40px', backgroundColor: '#000068', color: '#000', width: '1600px', marginLeft:'40px' }}>
      <div className="footer-col">
        <div className="container">
          <div className="list-col">
            <div className="row">
              <div className="col-md-12 col-lg-4 col-mobile-down">
              <div className="mobile-collapse">
                <img className="top-bar__logo" src={image1} alt='logo' style={{ height: '180px', width: '180px', marginLeft: '30px',marginTop:'-40px' }} />

                <h4 className="footer-title collapse-title playfair-display" style={{ fontFamily: 'Sans-serif',color: 'white' }}>Contact Information</h4>
                &emsp;
                  <div className="pt-collapse-content  playfair-display"style={{ fontFamily: 'Sans-serif', color: 'white',fontSize:'12px' }}>
                    <address className=' playfair-display'>
                    <div style={{ display: 'flex' }}>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ fontFamily: 'Sans-serif',fill: 'white' }}>
    <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
  </svg>
  <span> # 70 B block
          1st floor, Anandnagar
          Mysore - 570022, 
          Karnataka
          </span>
                   </div>
                   <a className='playfair-display' href="mailto:pieflyaerospace@gmail.com" style={{ fontFamily: 'Sans-serif',color: 'white', fontSize: '12px', display: 'flex', alignItems: 'center' }}>
  <span style={{ fontSize: '24px', color: 'white', marginRight: '8px' }}>
    <MdMailOutline />
  </span>
  Info@pieflyaerospace.com
</a>

                    </address>
                    
                    {/* <ul className="social-icon">
                      <li><a href="#"><svg width="6" height="12"><use xlinkHref="#f"></use></svg></a></li>
                      <li><a href="#"><svg width="14" height="14"><use xlinkHref="#insta"></use></svg></a></li>
                      <li><a href="#"><svg width="13" height="11"><use xlinkHref="#in"></use></svg></a></li>
                    </ul> */}
                    
                  </div>
                </div>
              </div>
             

<div className="col-md-3 col-lg-2" style={{ fontFamily: 'Sans-serif',marginLeft: '200px', marginRight: '100px' }}>
  <div className="mobile-collapse" style={{fontFamily: 'Sans-serif', maxWidth: '400px', marginLeft: 'auto', marginRight: 'auto' }}>
    <h4 className="footer-title collapse-title playfair-display" style={{fontFamily: 'Sans-serif', color: 'white', textAlign: 'center' }}>Quick Links</h4>
    <div className="pt-collapse-content" style={{ fontFamily: 'Sans-serif',color: 'white', fontSize: '12px' }}>
      <div className="oval-grid" style={{fontFamily: 'Sans-serif', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '20px', padding: '10px' }}>
        <div className="oval-box" style={{fontFamily: 'Sans-serif', width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="#" style={{ fontFamily: 'Sans-serif',color: 'black', textAlign: 'center' }}><span className='playfair-display'>Home</span></a>
        </div>
        <div className="oval-box" style={{fontFamily: 'Sans-serif', width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="/#/training" style={{ fontFamily: 'Sans-serif',color: 'black', textAlign: 'center' }}><span className='playfair-display'>Training Program</span></a>
        </div>
        <div className="oval-box" style={{fontFamily: 'Sans-serif', width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="#" style={{fontFamily: 'Sans-serif', color: 'black', textAlign: 'center' }}><span className='playfair-display'>Products</span></a>
        </div>
        <div className="oval-box" style={{ fontFamily: 'Sans-serif',width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="/#/contactus" style={{ fontFamily: 'Sans-serif',color: 'black', textAlign: 'center' }}><span className='playfair-display'>Contact Us</span></a>
        </div>
        <div className="oval-box" style={{ fontFamily: 'Sans-serif',width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="#" style={{fontFamily: 'Sans-serif', color: 'black', textAlign: 'center' }}><span className='playfair-display'>Services</span></a>
        </div>
        <div className="oval-box" style={{fontFamily: 'Sans-serif', width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="#" style={{ fontFamily: 'Sans-serif',color: 'black', textAlign: 'center' }}><span className='playfair-display'>About Us</span></a>
        </div>
      </div>
    </div>
  </div>
</div>
   


             
            </div>
          </div>
        </div>
      </div>
      <div className="footer-custom">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4">
              {/* <div className="copyright"style={{color: 'white',fontSize:'12px' }}>
                &copy; 2019 Quadron. All rights reserved.
              </div> */}
            </div>
           
                <div className='social-icons' style={{marginLeft:'600px',marginTop:'30px'}}>
                <a className='social-icon-link whatsapp' href='https://wa.me/yourwhatsapplink' target='_blank' aria-label='WhatsApp' style={{ marginLeft: '-40px' }}>
  <FontAwesomeIcon icon={faWhatsapp} />
  </a>
  <a className='social-icon-link facebook' href='/' target='_blank' aria-label='Facebook'>
    <FontAwesomeIcon icon={faFacebookF} />
  </a>
  <a className='social-icon-link instagram' href='https://www.instagram.com/pieflyofficial?igsh=bmU0emdnbWs2aGIw' target='_blank' aria-label='Instagram' style={{marginLeft:'-40px'}}>
    <FontAwesomeIcon icon={faInstagram} />
  </a>
  <a className='social-icon-link twitter' href='https://x.com/pieflyofficial?t=RHOzsk0Vc47uIvKSCc5XVA&s=09' target='_blank' aria-label='Twitter' style={{marginLeft:'-40px'}}>
  <TfiTwitter />
  </a>
  <a className='social-icon-link linkedin' href='https://in.linkedin.com/company/piefly-r-d-private-limited' target='_blank' aria-label='LinkedIn' style={{marginLeft:'-40px'}}>
  <AiOutlineLinkedin />
  </a>
  <a className='social-icon-link Youtube' href='https://youtube.com/@piefly4302?si=Qecbd2nJ80cYx-Hk' target='_blank' aria-label='Youtube' style={{marginLeft:'-40px'}}>
  <BsYoutube />  </a>
</div>


              </div>
            </div>
          </div>
        
    </footer>
    
    </>
    
  );
};

export default Pieflykisandrones;
