import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import './Footer.css'
import Modal from './DocumentsModal'; // Assuming EnquiryModal component is in a file named EnquiryModal.js
import { TfiTwitter } from "react-icons/tfi";
import { AiOutlineLinkedin } from "react-icons/ai";
import { BsYoutube } from "react-icons/bs";
import image1 from '../svg-sprite/mainslider/pieflylogo.png';


function Footer() {

  const [showDocumentsPopup, setShowDocumentsPopup] = useState(false); // State for managing the visibility of the popup

  const toggleDocumentsPopup = (e) => {
    e.preventDefault(); // Prevent the default behavior of the anchor tag
    setShowDocumentsPopup(!showDocumentsPopup);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

    const handleEnquireClick = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
  return (
    <footer style={{ fontSize: '10px',fontFamily:'Sans-serif', padding: '40px', backgroundColor: '#000068', color: '#000', width: '1500px', marginLeft: '450px' }}>
    <div className="d-flex justify-content-between" style={{ marginLeft: '100px', marginRight: '100px' }}>
      <div className="col-md-12 col-lg-4 col-mobile-down">
        <div className="mobile-collapse">
          
        <img className="top-bar__logo" src={image1} alt='logo' style={{ height: '150px', width: '150px', marginLeft: '-10px',marginTop:'10px' }} />

          <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white' }}>Contact Information</h4>
          <div className="pt-collapse-content playfair-display" style={{ color: 'white', fontSize: '12px' }}>
            <address className='playfair-display'>
              <span># 70 </span>B block <span>1st</span> floor Anandnagar Mysore<span> 570022</span> Karnataka<br />
              <a className='playfair-display' href="mailto:pieflyaerospace@gmail.com" style={{ color: 'white', fontSize: '12px' }}>Info@pieflyaerospace.com</a>
            </address>
          </div>
        </div>
      </div>
  
      <div className="col-md-3 col-lg-2">
        <div className="mobile-collapse" style={{ maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}>
          <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white', textAlign: 'center' }}>Quick Links</h4>
          <div className="pt-collapse-content" style={{ color: 'white', fontSize: '12px' }}>
            <div className="oval-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px', padding: '10px' }}>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Home</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Training Program</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Enroll</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Shop</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Contacts</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Elements</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div className="col-md-3 col-lg-2">
        <div className="mobile-collapse" style={{ maxWidth: '300px', marginLeft: 'auto', marginRight: 'auto' }}>
          <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white', textAlign: 'center' }}>Useful Links</h4>
          <div className="pt-collapse-content" style={{ color: 'white', fontSize: '12px' }}>
            <div className="oval-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px', padding: '10px' }}>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Products Documents</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Services Documents</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Products</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Blog and News</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div className="col-md-3 col-lg-2">
        <div className="mobile-collapse" style={{ maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}>
          <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white', textAlign: 'center' }}>Main Menu</h4>
          <div className="pt-collapse-content" style={{ color: 'white', fontSize: '12px' }}>
            <div className="oval-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px', padding: '10px' }}>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>About Us</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Gallery</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Projects</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Contacts</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        <div className="footer-custom">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-4">
                {/* <div className="copyright"style={{color: 'white',fontSize:'12px' }}>
                  &copy; 2019 Quadron. All rights reserved.
                </div> */}
              </div>
            
             <div className='social-icons' style={{marginLeft:'600px',marginTop:'-20px'}}>
    <a className='social-icon-link facebook' href='/' target='_blank' aria-label='Facebook'>
      <FontAwesomeIcon icon={faFacebookF} />
    </a>
    <a className='social-icon-link instagram' href='https://www.instagram.com/pieflyofficial?igsh=bmU0emdnbWs2aGIw' target='_blank' aria-label='Instagram' style={{marginLeft:'-40px'}}>
      <FontAwesomeIcon icon={faInstagram} />
    </a>
    <a className='social-icon-link twitter' href='https://x.com/pieflyofficial?t=RHOzsk0Vc47uIvKSCc5XVA&s=09' target='_blank' aria-label='Twitter' style={{marginLeft:'-40px'}}>
    <TfiTwitter />
    </a>
    <a className='social-icon-link linkedin' href='https://in.linkedin.com/company/piefly-r-d-private-limited' target='_blank' aria-label='LinkedIn' style={{marginLeft:'-40px'}}>
    <AiOutlineLinkedin />
    </a>
    <a className='social-icon-link Youtube' href='https://youtube.com/@piefly4302?si=Qecbd2nJ80cYx-Hk' target='_blank' aria-label='Youtube' style={{marginLeft:'-40px'}}>
    <BsYoutube />  </a>
  </div>
  
                </div>
              </div>
            </div>
          
      </footer>
  );
}

export default Footer;
