import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };
  const Sidebar = ({ isOpen, onClose }) => {
    const sidebarStyle = {
      width: isOpen ? '100%' : '0',
      height: '100%',
      position: 'fixed',
      top: 0,
      left: 0,
      backgroundColor: '#111',
      overflowX: 'hidden',
      transition: '0.5s',
      paddingTop: '60px', // Adjust padding based on your design
      zIndex: 1,
    };
  
    const linkStyle = {
      padding: '8px 8px 8px 32px',
      textDecoration: 'none',
      fontSize: '25px',
      color: '#818181',
      display: 'block',
      transition: '0.3s',
    };
  
    const closeBtnStyle = {
      position: 'absolute',
      top: '0',
      right: '25px',
      fontSize: '36px',
      marginLeft: '50px',
    };
  }  
  return (
    <div>
      <div className={`sidebar ${isNavOpen ? 'open' : ''}`}>
        {/* <Link to='/enrollmentrequest'>Enrollment Request</Link>
        <Link to='/traceability'>Traceability</Link> */}

        {isNavOpen && (
          <div className="nav-dropdown-content" style={{ background: 'blue', color: 'white' }}>
         <Link to='/enrollmentrequest'>Enrollment Request</Link>
        <Link to='/traceability'>Traceability</Link> 
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
