import React, { useState, useEffect, useRef } from 'react';
import image from '../svg-sprite/mainslider/trafficmonitor.png';
import "./Dropdown.css";
import Navbar from './navbar';
import Footer from './Footer ';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { TfiTwitter } from "react-icons/tfi";
import { AiOutlineLinkedin } from "react-icons/ai";
import { BsYoutube } from "react-icons/bs";
import Modal from './EnquiryModal'; // Assuming EnquiryModal component is in a file named EnquiryModal.js
import image1 from '../svg-sprite/mainslider/pieflylogo.png';
import { MdMailOutline } from "react-icons/md";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const Trafficmonitor = () => {
  const dropdownTimeoutRef = useRef(null);
  const dropdownRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEnquireClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
useEffect(() => {
  return () => {
    clearTimeout(dropdownTimeoutRef.current);
  };
}, []);

  return (
   <> 
<div> <Navbar/> </div>

      <div>
      <div style={{ position: 'relative', textAlign: 'center', marginTop: '120px' }}>
  <img
    className="slide-icon"
    src={image}
    alt="img"
    style={{
      filter: 'blur(4px)',
      height: '600px',
      width: '1500px',
      margin: '0 auto',
      marginLeft: '0px'
    }}
  />
  <h1
    className="title title-lg playfair-display"
    style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: '2em',
      color: 'white'
    }}
  >
    TRAFFIC MONITORING
  </h1>
</div>

   
    <br/>
    <div style={{ textAlign: 'center', padding: '5px', margin: '10px',backgroundColor:'#e5eaf5' }}>

    <p className="para-style">
    What is traffic monitoring?
                 Traffic monitoring is an essential aspect of road management and optimization efforts. It involves systematically 
                 observing and analysing traffic conditions to address various road-related situations and enhance existing systems. 
                 Drones are frequently used for traffic monitoring tasks, including managing incidents such as accidents and conducting
                  vehicle flow analysis for research. 
                   

                   How does traffic monitoring work? 
                 Traffic surveillance is a critical component of road management and optimization strategies. It entails systematic observation 
                 and analysis of traffic conditions to address diverse road-related scenarios and improve current systems. Drones are commonly 
                 utilized for traffic surveillancetasks, encompassing incident management like accidents and conducting vehicle flow analysis
                  for research endeavours. 
                   <br/>
                   Advantages of Drone Utilization<br/>
                 1. Rapid Deployment: Drones can be swiftly deployed to gain situational awareness of a scenario within minutes,
                  facilitating timely response and decision-making.<br/>

                  2. Enhanced Aerial Monitoring: Aerial surveillance from drones offers superior visibility compared to ground-level monitoring, enabling
                   comprehensive coverage over a wider area. Drones can be operated kilometers away, providing extensive insights into traffic conditions.
<br/>
                   3. Cost-Effectiveness: Drones present a more economical option compared to manned aircraft.Multiple drones can be deployed simultaneously
                    for a fraction of the cost of operating a single helicopter or Aeroplane, optimizing resource allocation.<br/>
                    4. Extended Monitoring Capability: Utilizing tethered drones allows for continuous aerial monitoring of traffic for up to 24 hours
                     without interruption, surpassing the endurance of alternative methods.</p>

                   &emsp;&emsp; &emsp;&emsp;
                  &emsp;&emsp;
                  <br/>

 <br/>
</div>

 <footer style={{ fontSize: '10px', padding: '40px', backgroundColor: '#000068', color: '#000', width: '1500px', marginLeft:'150px' }}>
      <div className="footer-col">
        <div className="container">
          <div className="list-col">
            <div className="row">
              <div className="col-md-12 col-lg-4 col-mobile-down">
              <div className="mobile-collapse">
                <img className="top-bar__logo" src={image1} alt='logo' style={{ height: '180px', width: '180px', marginLeft: '30px',marginTop:'-40px' }} />

                <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white',fontFamily:'Sans-serif', }}>Contact Information</h4>
                &emsp;
                  <div className="pt-collapse-content  playfair-display"style={{  color: 'white',fontFamily:'Sans-serif',fontSize:'12px' }}>
                  <address className=' playfair-display'>
                    <div style={{ display: 'flex' }}>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ fill: 'white' }}>
    <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
  </svg>
  <span> # 70 B block
          1st floor, Anandnagar
          Mysore - 570022, Karnataka
          </span> 
                   </div>
                   <a className='playfair-display' href="mailto:pieflyaerospace@gmail.com" style={{ color: 'white', fontSize: '12px', display: 'flex', alignItems: 'center' }}>
  <span style={{ fontSize: '24px', color: 'white', marginRight: '8px' }}>
    <MdMailOutline />
  </span>
  Info@pieflyaerospace.com
</a>

                    </address>
                  </div>
                </div>
              </div>

              <div className="col-md-3 col-lg-2" style={{ fontFamily: 'Sans-serif',marginLeft: '200px', marginRight: '100px' }}>
  <div className="mobile-collapse" style={{fontFamily: 'Sans-serif', maxWidth: '400px', marginLeft: 'auto', marginRight: 'auto' }}>
    <h4 className="footer-title collapse-title playfair-display" style={{fontFamily: 'Sans-serif', color: 'white', textAlign: 'center' }}>Quick Links</h4>
    <div className="pt-collapse-content" style={{ fontFamily: 'Sans-serif',color: 'white', fontSize: '12px' }}>
      <div className="oval-grid" style={{fontFamily: 'Sans-serif', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '20px', padding: '10px' }}>
        <div className="oval-box" style={{fontFamily: 'Sans-serif', width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="#" style={{ fontFamily: 'Sans-serif',color: 'black', textAlign: 'center' }}><span className='playfair-display'>Home</span></a>
        </div>
        <div className="oval-box" style={{fontFamily: 'Sans-serif', width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="/#/training" style={{ fontFamily: 'Sans-serif',color: 'black', textAlign: 'center' }}><span className='playfair-display'>Training Program</span></a>
        </div>
        <div className="oval-box" style={{fontFamily: 'Sans-serif', width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="#" style={{fontFamily: 'Sans-serif', color: 'black', textAlign: 'center' }}><span className='playfair-display'>Products</span></a>
        </div>
        <div className="oval-box" style={{ fontFamily: 'Sans-serif',width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="/#/contactus" style={{ fontFamily: 'Sans-serif',color: 'black', textAlign: 'center' }}><span className='playfair-display'>Contact Us</span></a>
        </div>
        <div className="oval-box" style={{ fontFamily: 'Sans-serif',width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="#" style={{fontFamily: 'Sans-serif', color: 'black', textAlign: 'center' }}><span className='playfair-display'>Services</span></a>
        </div>
        <div className="oval-box" style={{fontFamily: 'Sans-serif', width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="#" style={{ fontFamily: 'Sans-serif',color: 'black', textAlign: 'center' }}><span className='playfair-display'>About Us</span></a>
        </div>
      </div>
    </div>
  </div>
</div>

</div>
         </div>
          </div>
        </div>
      {/* </div> */}
      <div className="footer-custom">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4">
              {/* <div className="copyright"style={{color: 'white',fontSize:'12px' }}>
                &copy; 2019 Quadron. All rights reserved.
              </div> */}
            </div>
           
                <div className='social-icons' style={{marginLeft:'600px',marginTop:'30px'}}>
                <a className='social-icon-link whatsapp' href='https://wa.me/yourwhatsapplink' target='_blank' aria-label='WhatsApp' style={{ marginLeft: '-40px' }}>
<FontAwesomeIcon icon={faWhatsapp} />
</a>

  <a className='social-icon-link facebook' href='/' target='_blank' aria-label='Facebook'>
    <FontAwesomeIcon icon={faFacebookF} />
  </a>
  <a className='social-icon-link instagram' href='https://www.instagram.com/pieflyofficial?igsh=bmU0emdnbWs2aGIw' target='_blank' aria-label='Instagram' style={{marginLeft:'-40px'}}>
    <FontAwesomeIcon icon={faInstagram} />
  </a>
  <a className='social-icon-link twitter' href='https://x.com/pieflyofficial?t=RHOzsk0Vc47uIvKSCc5XVA&s=09' target='_blank' aria-label='Twitter' style={{marginLeft:'-40px'}}>
  <TfiTwitter />
  </a>
  <a className='social-icon-link linkedin' href='https://in.linkedin.com/company/piefly-r-d-private-limited' target='_blank' aria-label='LinkedIn' style={{marginLeft:'-40px'}}>
  <AiOutlineLinkedin />
  </a>
  <a className='social-icon-link Youtube' href='https://youtube.com/@piefly4302?si=Qecbd2nJ80cYx-Hk' target='_blank' aria-label='Youtube' style={{marginLeft:'-40px'}}>
  <BsYoutube />  </a>
</div>

              </div>
            </div>
          </div>
        
    </footer>
    </div>
                
                 </>
);
};
export default Trafficmonitor;