import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import image1 from '../svg-sprite/mainslider/logo.jpeg';
import './enroll.css';
import axios from '../api/baseurl';
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';  // Import Link from react-router-dom
import Footer from '../New/Footer ';
import Sidebar from './Sidebar';
import { MdCall } from "react-icons/md";
import "./Dropdown.css";
import Dropdown3 from './Dropdown3';
import Modal from './DocumentsModal'; // Assuming EnquiryModal component is in a file named EnquiryModal.js
import { TfiTwitter } from "react-icons/tfi";
import { AiOutlineLinkedin } from "react-icons/ai";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import image from '../svg-sprite/mainslider/agriculturedrone1.png';


const Onlineadmission = () => {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    address: '',
    dob: null,
    bloodGroup: '',
    photo: null,
    age: '',
    qualification: '',
    aadharNumber: '',
    idProofType: '', 
    panCard: false,
    drivingLicense: false,
  });
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const dropdownTimeoutRef = useRef(null);
  const dropdownRef = useRef(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [dropdown1, setDropdown1] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
    const [userData, setUserData] = useState(null);
    const [dropdown3, setDropdown3] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleEnquireClick = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
    const handleEnrollMouseEnter = (e) => {
      e.target.style.borderColor = 'white';
      e.currentTarget.style.transform = 'scale(1.05)'; // Zoom in effect
      setDropdown3(true);
    };
  
    const handleEnrollMouseLeave = (e) => {
      e.target.style.borderColor = 'transparent';
      e.currentTarget.style.transform = 'scale(1)'; // Zoom out effect
  
      setDropdown3(false);
    };
  
 
  const handleRPTOMouseEnter = (e) => {
    e.target.style.borderColor = 'white';
  };

  const handleRPTOMouseLeave = (e) => {
    e.target.style.borderColor = ' transparent';
  };

  const handleAboutUsMouseEnter = (e) => {
    e.target.style.borderColor = ' white';
   
  };

  const handleAboutUsMouseLeave = (e) => {
    e.target.style.borderColor = 'transparent';
    
  };

  const handleNewsMouseEnter = (e) => {
    e.target.style.borderColor = ' white';
   
  };

  const handleNewsMouseLeave = (e) => {
    e.target.style.borderColor = 'transparent';
    
  };

  const handleArticlesMouseEnter = (e) => {
    e.target.style.borderColor = ' white';
   
  };

  const handleArticlesMouseLeave = (e) => {
    e.target.style.borderColor = 'transparent';
    
  };

  const handleContactUsMouseEnter = (e) => {
    e.target.style.borderColor = ' white';
   
  };

  const handleContactUsMouseLeave = (e) => {
    e.target.style.borderColor = 'transparent';
    
  };



  const [isHoveredGeneral, setIsHoveredGeneral] = useState(false);
const [isHoveredStudents, setIsHoveredStudents] = useState(false);

const handleMouseEnterGeneral = () => {
  setIsHoveredGeneral(true);
};

const handleMouseLeaveGeneral = () => {
  setIsHoveredGeneral(false);
};

const handleMouseEnterStudents = () => {
  setIsHoveredStudents(true);
};

const handleMouseLeaveStudents = () => {
  setIsHoveredStudents(false);
};

const [dropdownOpen, setDropdownOpen] = useState(false);

const [isHovered, setIsHovered] = useState(false);

// Apply styles based on hover state
const liStyles = {
  transition: 'transform 0.3s ease', // Add transition for smooth effect
  transform: isHovered ? 'scale(1.05)' : 'scale(1)', // Zoom in/out effect
};
const handleMouseEnter2 = () => {
  
  setIsHovered(true);
  setDropdownOpen(true);
};

const handleMouseLeave2 = () => {
  clearTimeout(dropdownTimeoutRef.current);
  dropdownTimeoutRef.current = setTimeout(() => {
    setDropdown(false);
  }, 3500);
  setIsHovered(false);
  setDropdownOpen(false);
}; 

const handleDropdownEnter = () => {
  clearTimeout(dropdownRef.current);
};

const handleDropdownLeave = () => {
  clearTimeout(dropdownRef.current);
  setDropdown(false);
};

const handleLogin = () => {
  // Assume successful login, set isLoggedIn to true and set user data
  setLoggedIn(true);
  setUserData(/* user data object */);
};

const [scrolledUp, setScrolledUp] = useState(true);

useEffect(() => {
  const handleScroll = () => {
    const isScrolledUp = window.scrollY === 0;
    setScrolledUp(isScrolledUp);
  };

  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);


  const handleChange = (e) => {
    const { name, value, type, files, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'file' ? files[0] : type === 'checkbox' ? checked : value,
    }));
  };

  const handleDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      dob: date,
    }));
  };

  const handleBloodGroupChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      bloodGroup: e.target.value,
    }));
  };

  const handleIdProofTypeChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      idProofType: e.target.value,
    }));
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const storedData = JSON.parse(localStorage.getItem("data"));
      
      if (!storedData || !storedData.token) {
        console.error('Token not found in localStorage');
        // Handle the case where the token is not available
        // You might want to redirect the user to the login page or take appropriate action
        return;
      }
  
      const token = storedData.token;
  
      // Submit the data to the server to generate token
      const response = await axios.post(`/api/contact/create`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      // Handle the response
      console.log('Response:', response.data);
      // Update your state or perform any other actions with the response data
      // setFormData(response.data);
    } catch (error) {
      console.error('Error submitting data:', error);
  
      // Log the error response
      if (error.response) {
        console.error('Error response:', error.response.data);
      }
  
      // Handle other errors
      // ...
  
      // You might want to notify the user about the error using a toast or other UI element
      // toast.error("An error occurred while processing your request.");
    }
  };
  


  return (
    <>
    <div>
      &emsp;&emsp;&emsp;
      <div className="page-backgrounddd" style={{ backgroundColor:'white', backgroundSize: 'cover', minHeight: '900px', width: '2500px', display: 'flex', flexDirection: 'column' }}>
      <header id="top-bar" style={{ background: '#000068' }}>
      <div className="container-fluid">
        <div className="row justify-content-between no-gutters">
        <div className="col-auto side-col d-flex align-items-center text-nowrap" style={{ margin: '0 auto' }}>
           
        <img className="top-bar__logo" src={image1} alt='logo' style={{ height: '150px', width: '150px', marginLeft: '-10px',marginTop:'10px' }} />
            &emsp;&emsp;
<br></br>
<br></br>
&emsp;

<div className="col-auto" style={{ color: 'white', textAlign: 'center' }}>
  <span className='playfair-display' style={{ fontWeight: 'bold', fontSize: '1.1em',fontFamily:'Sans-serif'}}>PIEFLY AVIATION REMOTE </span> <br />
  <span className='playfair-display' style={{ fontWeight: 'bold', fontSize: '1.1em',fontFamily:'Sans-serif'}}>PILOT TRAINING ORGANISATION</span>
</div>

&emsp;
&emsp;


<div className="col-auto" style={{ fontSize: 'small', color: 'white', textAlign: 'center' }}>
<span className='playfair-display' style={{ fontWeight: 'bold',fontFamily:'Sans-serif',
 }}>CENTRE FOR AVIATION </span> <br />
<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center',fontFamily:'Sans-serif',
 }}>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ fill: 'white' }}>
    <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
  </svg>
  <span className='playfair-display' style={{ marginLeft: '5px' }}>SJCE CAMPUS [STEP] MYSORE </span>
</div>
</div>

&emsp;
&emsp;
<div className="col-auto" style={{ fontSize: 'small', color: 'white', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ fill: 'white', marginRight: '5px' }}>
  <path d="M20 3H17V1H15V3H9V1H7V3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H20C21.1 21 22 20.1 22 19V5C22 3.9 21.1 3 20 3M20 19H4V8H20V19M7 10H9V12H7V10M11 10H13V12H11V10M15 10H17V12H15V10Z"/>
</svg>
<span style={{ display: 'inline-flex', alignItems: 'center',fontFamily:'Sans-serif',
 }}>
  <span style={{ fontWeight: 'bold', marginRight: '5px',fontFamily:'Sans-serif',
 }}>9:00 AM-6:00 PM </span>
  {/* <span style={{ marginLeft: '5px' }}>Monday-Saturday</span> */}
</span>
</div>
&emsp;
&emsp;
<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
<div style={{ fontSize: '24px', color: 'white' }}>
      <MdCall />
    </div>
    </div>
    &emsp;
    <div>
      <div style={{ marginRight: '50px', color:'white'  }}>91-96069 31676</div>
      <div className='playfair-display' style={{ marginRight: '7px', color:'white' ,fontSize:'14px',fontFamily:'Sans-serif',
}}>Piefly.rptotraining@gmail.com</div>
    </div>

&emsp;  
 
<div className="col-auto">
  <Link to="/login" onMouseEnter={(e) => e.currentTarget.querySelector('button').style.transform = 'scale(1.05)'} onMouseLeave={(e) => e.currentTarget.querySelector('button').style.transform = 'scale(1)'}>
    <button className='playfair-display' style={{ background: '#B6D0E2',fontFamily:'Sans-serif',
 fontSize: '12px', color: 'black', padding: '0px 30px', fontWeight: 'bold', borderRadius: '5px', cursor: 'pointer', transition: 'transform 0.3s' }}>
      Login
    </button>
  </Link>
</div>
            </div>
            {/* End login button */}
           </div>
          </div>
          
          </header>

   

          <Sidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(!isSidebarOpen)} />
{/* Add some spacing between Sidebar and Navbar */}
<div style={{ width: '20px' }}></div>
<div
      style={{
        background:  'white', // Change background color based on scroll position
                padding: '10px',
        marginTop: '100px',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center', 
        fontFamily:'Sans-serif',
      }}
    >
      <ul
        style={{
          display: 'flex',
          alignItems: 'center',
          listStyle: 'none',
          padding: 0,
        }}
      >
<li style={{ marginRight: '10px', marginLeft: '50px' }} onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'} onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}>
        <a
        href="/#/rpto"
        style={{
          color: 'black',
          textDecoration: 'none',
          position: 'relative',
          fontWeight:'bold'
        }}
        onMouseEnter={handleRPTOMouseEnter}
        onMouseLeave={handleRPTOMouseLeave}
      >
        <span className='playfair-display'
          style={{
            position: 'relative',
            borderBottom: '4px solid transparent',
            paddingBottom: '12px',
            marginRight: '5px',
            transition: 'border-color 0.3s ease',
            fontSize:'15px',
            fontFamily:'Sans-serif',

          }}
          onMouseOver={(e) => e.target.style.borderColor = 'black'}
    onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
        >
         HOME
        
        </span>
      </a>
  <Link to="/#/rpto" style={{ color: 'white', textDecoration: 'none' }}></Link>
</li>

&emsp; &emsp; &emsp; 
  &emsp;
  <li  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'} onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}>
          <a
          href="/#/training"
          style={{
            color: 'black',
            textDecoration: 'none',
            position: 'relative',
            fontWeight:'bold'
          }}
          onMouseEnter={handleRPTOMouseEnter}
          onMouseLeave={handleRPTOMouseLeave}
        >
          <span className='playfair-display'
           style={{
            position: 'relative',
            borderBottom: '4px solid transparent',
            paddingBottom: '12px',
            marginRight: '5px',
            transition: 'border-color 0.3s ease',
            fontSize:'15px',
            fontFamily:'Sans-serif',

            }}
            onMouseOver={(e) => e.target.style.borderColor = 'black'}
      onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
          >
           TRAINING PROGRAM
          
          </span>
        </a>
    <Link to="/#/training" style={{ color: 'white', textDecoration: 'none' }}></Link>
  </li>
  &emsp; &emsp; 
  &emsp;
  <li  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'} onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}>
  <a
        href="/#/aboutt"
        style={{
          color: 'black',
          textDecoration: 'none',
          position: 'relative',
          fontWeight:'bold'
        }}
        onMouseEnter={handleAboutUsMouseEnter}
        onMouseLeave={handleAboutUsMouseLeave}
      >
        <span className='playfair-display'
          style={{
            position: 'relative',
            borderBottom: '4px solid transparent',
            paddingBottom: '12px',
            marginRight: '5px',
            transition: 'border-color 0.3s ease',
                fontSize:'15px',
                fontFamily:'Sans-serif',

          }}
          onMouseOver={(e) => e.target.style.borderColor = 'black'}
          onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
        >
          ABOUT US
          
        </span>
      </a>
    <Link to="/aboutt" style={{ color: 'white', textDecoration: 'none' }}></Link>
  </li>
  &emsp; &emsp;
  &emsp;
 
 
  <li  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'} onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}>
  <a
        
        style={{
          color: 'black',
          textDecoration: 'none',
          position: 'relative',
          fontWeight:'bold',
          cursor:'pointer'
        }}
        onMouseEnter={handleEnrollMouseEnter}
        onMouseLeave={handleEnrollMouseLeave}
      >
        <span className='playfair-display'
          style={{
            position: 'relative',
            borderBottom: '4px solid transparent',
            paddingBottom: '12px',
            marginRight: '5px',
            transition: 'border-color 0.3s ease',
                fontSize:'15px',
                fontFamily:'Sans-serif',

          }}
          onMouseOver={(e) => e.target.style.borderColor = 'black'}
          onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
        >
          ENROLL
          {dropdown3 && <Dropdown3 />}

        </span>
      </a>
  </li>
  &emsp; &emsp; &emsp; 

  <li  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'} onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}>
  <a
        href="/#/studentlogin"
        style={{
          color: 'black',
          textDecoration: 'none',
          position: 'relative',
          fontWeight:'bold'
        }}
        onMouseEnter={handleArticlesMouseEnter}
        onMouseLeave={handleArticlesMouseLeave}
      >
        <span className='playfair-display'
          style={{
            position: 'relative',
            borderBottom: '4px solid transparent',
            paddingBottom: '12px',
            
            transition: 'border-color 0.3s ease',
                fontSize:'15px',
                fontFamily:'Sans-serif',

          }}
          onMouseOver={(e) => e.target.style.borderColor = 'black'}
          onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
        >
          STUDENT LOGIN
          
        </span>
      </a>
    <Link to="/studentlogin" style={{ color: 'white', textDecoration: 'none' }}></Link>
  </li>
  &emsp; &emsp; &emsp;
 
  <li style={liStyles}>
  <a
    style={{
      color: 'black',
      textDecoration: 'none',
      position: 'relative',
      fontWeight: 'bold',
      marginRight: '10px',
    }}
    onMouseEnter={handleMouseEnter2}
    onMouseLeave={handleMouseLeave2}
  >
    <span className='playfair-display'
      style={{
        position: 'relative',
        borderBottom: '4px solid transparent',
        paddingBottom: '12px',
        transition: 'border-color 0.3s ease',
        cursor: 'pointer',
                fontSize:'15px',
                fontFamily:'Sans-serif',

      }}
      onMouseOver={(e) => e.target.style.borderColor = 'black'}
      onMouseOut={(e) => e.target.style.borderColor = 'transparent'}
    >
      MORE
    </span>
  </a>
  {dropdownOpen && (
    <div
      style={{
        position: 'absolute',
        top: 'calc(100% + 10px)', // Adjusted top value for additional spacing
        right: '-30px',
        backgroundColor: '#fff', // Dropdown background color
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Dropdown shadow
        borderRadius: '5px', // Dropdown border radius
        zIndex: '999', // Ensure dropdown appears above other content
        width: '150px', // Set dropdown width to 100%
        boxShadow: '0 0 10px rgb(232, 222, 222)', /* Adjust shadow color and size as needed */
        transition: 'transform 0.3s ease', // Add transition for smooth effect
        transform: isHovered ? 'scale(1.05)' : 'scale(1)', // Zoom in/out effect
        fontFamily:' Sans-serif',
                fontSize:'16px',
                fontFamily:'Sans-serif',

      }}
      onMouseEnter={handleMouseEnter2} // Keep dropdown open when mouse is over it
      onMouseLeave={handleMouseLeave2} // Close dropdown when mouse leaves it
    >
      {/* Dropdown content */}
      <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
        <li style={{ marginBottom: '0px', marginLeft: '15px' }}>
          <a className='playfair-display' href="/#/news" style={{ textDecoration: 'none', color: '#333',fontFamily:'Sans-serif',
 }}>
            NEWS
          </a>
        </li>
        <li style={{ marginBottom: '0px', marginLeft: '15px' }}>
          <a className='playfair-display' href="/#/contactus1" style={{ textDecoration: 'none', color: '#333',fontFamily:'Sans-serif',
 }}>
            CONTACT US
          </a>
        </li>
        {/* Add more dropdown items as needed */}
      </ul>
    </div>
  )}
</li>

</ul>                       
     
    </div>        

    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', padding: '20px' }}>
  
    <div style={{ marginBottom: '-40px', marginTop:'80px', color:'black', fontWeight: 'bold',fontSize:'25px' }}>Online Admission</div>
    
  <form onSubmit={handleSubmit} style={{ border: 'none' }}>
  <div style={{ 
    marginLeft: '750px', 
    maxWidth: '870px', 
    border: '1px solid black', 
    padding: '20px', 
    borderRadius: '5px',
    backgroundSize: 'cover', // Adjust based on your needs
    backgroundPosition: 'center' 
  }}>
    <div style={{ color:'black'}}>
      <label htmlFor="firstName">First Name:</label>
      <input
        type="text"
        id="firstname"
        name="firstname"
        value={formData.firstname}
        onChange={handleChange}
        required
        style={{ borderColor: 'black', width: '300px' }}
      />
    </div>
    <div style={{ color: 'black', marginLeft: '500px', marginTop: '-84px' }}>
      <label htmlFor="lastName">Last Name:</label>
      <input
        type="text"
        id="lastname"
        name="lastname"
        value={formData.lastname}
        onChange={handleChange}
        required
        style={{ borderColor: 'black', width: '300px' }}
      />
    </div>
    <br/>
    <div style={{ color: 'black' }}>
      <label htmlFor="email">Email:</label>
      <input
        type="email"
        id="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        required
        style={{ borderColor: 'black', width: '400px' }}
      />
    </div>
    <br/>
    <div style={{ color: 'black' }}>
      <label htmlFor="phone">Phone:</label>
      <input
        type="tel"
        id="phone"
        name="phone"
        value={formData.phone}
        onChange={handleChange}
        required
        style={{ borderColor: 'black', width: '300px' }}
      />
    </div>
    <div style={{ color: 'black', marginLeft: '500px', marginTop: '-200px' }}>
      <label htmlFor="dob">Date of Birth:</label>
      <DatePicker
        id="dob"
        name="dob"
        selected={formData.dob}
        onChange={handleDateChange}
        dateFormat="MM/dd/yyyy"
        showYearDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={15}
        required
        style={{ borderColor: 'black' }}
      />
    </div>
    <br/>
    <br/>
    <br/>
    <br/>
    <div style={{ color: 'black', marginLeft: '500px', marginTop: '-90px' }}>
      <label htmlFor="bloodGroup">Blood Group:</label>
      <select
        id="bloodGroup"
        name="bloodGroup"
        value={formData.bloodGroup}
        onChange={handleBloodGroupChange}
        required
        style={{ borderColor: 'black', width: '300px' }}
      >
        <option value="">Select Blood Group</option>
        <option value="A+">A+</option>
        <option value="A-">A-</option>
        <option value="B+">B+</option>
        <option value="B-">B-</option>
        <option value="O+">O+</option>
        <option value="O-">O-</option>
        <option value="AB+">AB+</option>
        <option value="AB-">AB-</option>
      </select>
    </div>
    <br/>
    <div style={{ color: 'black' }}>
      <label htmlFor="address">Address:</label>
      <textarea
        id="address"
        name="address"
        value={formData.address}
        onChange={handleChange}
        required
        style={{ borderColor: 'black', width: '800px' }}
      />
    </div>
    <div style={{ color: 'black' }}>
      <label htmlFor="photo">Upload Photo:</label>
      <input
        type="file"
        id="photo"
        name="photo"
        accept="image/"
        onChange={handleChange}
        style={{ borderColor: 'black', width: '300px' }}
      />
    </div>
    <div style={{ color: 'black', marginLeft: '500px', marginTop: '-90px' }}>
      <label htmlFor="age">Age:</label>
      <input
        type="text"
        id="age"
        name="age"
        value={formData.age}
        onChange={handleChange}
        style={{ borderColor: 'black', width: '300px' }}
      />
    </div>
    <br/>
    <div style={{ color: 'black' }}>
      <label htmlFor="qualification">Qualification:</label>
      <select
        id="qualification"
        name="qualification"
        value={formData.qualification}
        onChange={handleChange}
        required
        style={{ borderColor: 'black', width: '300px' }}
      >
        <option value="">Select Qualification</option>
        <option value="MMBS">MBBS</option>
        <option value="SSLC">SSLC</option>
        <option value="Master">Master</option>
        <option value="Degree">Degree</option>
      </select>
    </div>
    <div style={{ color: 'black', marginLeft: '500px', marginTop: '-90px' }}>
      <label htmlFor="aadharNumber">Aadhar Number:</label>
      <input
        type="text"
        id="aadharNumber"
        name="aadharNumber"
        value={formData.aadharNumber}
        onChange={handleChange}
        style={{ borderColor: 'black', width: '300px' }}
      />
    </div>
    <br/>
    <div style={{ color: 'black' }}>
      <label htmlFor="idProofType">Choose One ID Proof:</label>
      <select
        id="idProofType"
        name="idProofType"
        value={formData.idProofType}
        onChange={handleIdProofTypeChange}
        required
        style={{ borderColor: 'black', width: '300px' }}
      >
        <option value="">Select ID Proof Type</option>
        <option value="PanCard">Pan Card</option>
        <option value="DrivingLicense">Driving License</option>
      </select>
    </div>
    <br/>
    <div style={{ textAlign: 'center' }}>
      <button style={{ background: '#013220', fontSize: '16px', color: 'white', width: '180px', borderRadius: '5px', cursor: 'pointer', padding: '0px' }}>
        Submit
      </button>
    </div>
  </div>
</form>

          </div>
        </div>
      
      
      </div>
      <br/>
      <br/>
      
     <Footer/>
      
   </>
    
  );
};

export default Onlineadmission;
