import React, { useState, useEffect, useRef } from 'react';
import image from '../svg-sprite/mainslider/powerline.png';
import image1 from '../svg-sprite/mainslider/powerline1.png';
import image2 from '../svg-sprite/mainslider/powerline2.png';
import image3 from '../svg-sprite/mainslider/powerline3.png';
import image4 from '../svg-sprite/mainslider/powerline4.png';
import Navbar from './navbar';
import Footer from './Footer ';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { TfiTwitter } from "react-icons/tfi";
import { AiOutlineLinkedin } from "react-icons/ai";
import { BsYoutube } from "react-icons/bs";
import Modal from './EnquiryModal'; // Assuming EnquiryModal component is in a file named EnquiryModal.js
import { MdMailOutline } from "react-icons/md";
import image6 from '../svg-sprite/mainslider/pieflylogo.png';

const Powerline = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEnquireClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <> 
<div> <Navbar/> </div>

      <div>
        <div style={{ textAlign: 'center',marginTop: '130px' }}>
    
    <img
      className="slide-icon"
      src={image}
      alt="img"
      style={{ height: '600px', width: '1500px', margin: '0 auto',marginLeft:'0px' }}
    />
     <h1 className="title title-lg playfair-display" style={{ fontSize: '2.5em',fontFamily:'Sans-serif',marginTop: '-490px',color:'white',marginLeft:'600px'}}>
     POWERLINE INSPECTION<br/>
     
    </h1>
    <span className='playfair-display' style={{ fontSize: '18px',fontFamily:'Sans-serif',marginTop: '-10px',color:'yellow',marginLeft:'580px'}}>Drone solutions automate power line inspections, providing precise data on all grid components.<br/></span>
    <span className='playfair-display' style={{ fontSize: '18px',fontFamily:'Sans-serif',marginTop: '-60px',color:'yellow',marginLeft:'425px'}}>This enables timely repairs, minimizes accident risks, and reduces downtime.</span>
    </div>
    <br/>
    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>

                <p className='para-style' style={{ fontSize: '26px',fontFamily:'Sans-serif', textAlign: 'left', color:'black',fontWeight:'bold', marginLeft: '350px'  }}>
                Current challenges in power line inspection </p>
                <p className='para-style' style={{ textAlign: 'left', color:'black',fontFamily:'Sans-serif', marginLeft: '400px'  }}>
                For many utilities, power line inspections are still conducted on foot, an extremely time-consuming method given the over one million 
                 local distribution lines. Collecting visual data with the human eye can take over a year, even with a team of hundreds working
                  continuously, and is prone to human error.<br/><br/>
                  The job is hazardous, as inspectors must closely examine everything using binoculars, rope access (climbing and rappelling), scaffolding, and cranes. Standing on scaffolding or cranes exposes inspectors 
                  to the elements, increasing the risk of falls.<br/><br/></p>
                  <p className='para-style' style={{ textAlign: 'left', color:'black',fontFamily:'Sans-serif', marginLeft: '400px'  }}>
                  Forward-thinking grid operators employ manned helicopters equipped with high-resolution cameras for data collection. However,
                   this approach is too costly and challenging to scale. The average contract cost for a helicopter power line inspection is 
                   over $4,000 per day, excluding high maintenance costs. </p>
                   <br/>
                   <p className='para-style' style={{ fontSize: '26px', fontFamily:'Sans-serif',textAlign: 'left', color:'black',fontWeight:'bold', marginLeft: '350px'  }}>
                   Skip the manual climbing or flying - Work with Drone Harmony instead. <br/></p><br/>
                   <img
      className="slide-icon"
      src={image1}
      alt="img"
      style={{ height: '400px', width: '600px', margin: '0 auto',marginLeft:'430px' }}
    />
    <p className='para-style' style={{ fontSize: '23px', fontFamily:'Sans-serif',textAlign: 'left', color:'black',fontWeight:'bold', marginLeft: '350px'  }}>
    Your benefits:<br/></p>
    <p className='para-style' style={{ fontSize: '20px', fontFamily:'Sans-serif',textAlign: 'left', color:'black',fontWeight:'bold', marginLeft: '350px'  }}>
 
    1. Save Time on Site</p>
    <p className='para-style' style={{ textAlign: 'left', color:'black',fontFamily:'Sans-serif', marginLeft: '400px'  }}>
                 Automated flight planning and execution. Intuitive and streamlined workflow. Flight planning includes
                                              Environment-aware obstacle avoidance.
 </p>
              <br/>    
                   <p className='para-style' style={{ fontSize: '20px', textAlign: 'left', color:'black',fontFamily:'Sans-serif',fontWeight:'bold', marginLeft: '350px'  }}>
                   2. Get High-Quality Data</p>
                 <p className='para-style' style={{ textAlign: 'left', color:'black',fontFamily:'Sans-serif', marginLeft: '400px'  }}>
                 Full coverage and accurate images of 
Electric utilities, roads, or pipelines are
Guaranteed by optimization algorithms.

 </p>
<br/>
<p className='para-style' style={{ fontSize: '20px', textAlign: 'left', color:'black',fontFamily:'Sans-serif',fontWeight:'bold', marginLeft: '350px'  }}>
3. Reduce Cost</p>
<p className='para-style' style={{ textAlign: 'left', color:'black',fontFamily:'Sans-serif', marginLeft: '400px'  }}>
Perform more inspections with less 
Skilled personnel. High data quality reduces
 Image processing costs.</p>
<br/>
<p className='para-style' style={{ fontSize: '20px', textAlign: 'left', color:'black',fontFamily:'Sans-serif',fontWeight:'bold', marginLeft: '350px'  }}>
4. Scalable Solution</p>
 <p className='para-style' style={{ textAlign: 'left', color:'black',fontFamily:'Sans-serif', marginLeft: '400px'  }}>
 Flight plans can be shared and
 Repeatedly flown. Train beginner pilots
 In less than 30 minutes.</p>
 <br/>

 <p className='para-style' style={{ fontSize: '20px', textAlign: 'left', color:'black',fontFamily:'Sans-serif',fontWeight:'bold', marginLeft: '350px'  }}>
5. Full 3D Work Environment</p>
<p className='para-style' style={{ textAlign: 'left', color:'black',fontFamily:'Sans-serif', marginLeft: '400px'  }}>
Plan and visualize both an asset and
 Flight plans in 3D for accurate and predictable results.
</p>
<br/>
<p className='para-style' style={{ fontSize: '23px', textAlign: 'left', color:'black',fontFamily:'Sans-serif',fontWeight:'bold', marginLeft: '350px'  }}>
How Drones Are Used in Power Line Inspections?</p>
                 <p className='para-style' style={{ textAlign: 'left', color:'black',fontFamily:'Sans-serif', marginLeft: '350px'  }}>
                 Drones are highly versatile and can inspect power lines and pylons for bird nests, lightning strikes,
                  rust, corrosion, and damaged bolts.
</p>

<p className='para-style' style={{ fontSize: '23px', textAlign: 'left', color:'black',fontFamily:'Sans-serif',fontWeight:'bold', marginLeft: '350px'  }}>
Vegetation Management</p>
                 <p className='para-style' style={{ textAlign: 'left', color:'black', fontFamily:'Sans-serif',marginLeft: '350px'  }}>
                 Drones can determine the distance of vegetation from power lines. This information is then used 
                 to develop a plan for trimming vegetation to prevent interference with the lines.
</p>
<br/>
<img
      className="slide-icon"
      src={image2}
      alt="img"
      style={{ height: '400px', width: '600px', margin: '0 auto',marginLeft:'430px' }}
    />
<p className='para-style' style={{ fontSize: '23px', textAlign: 'left', color:'black',fontFamily:'Sans-serif',fontWeight:'bold', marginLeft: '350px'  }}>
Powerline Corridor Inspection</p>
                 <p className='para-style' style={{ textAlign: 'left', color:'black', fontFamily:'Sans-serif',marginLeft: '350px'  }}>
                 Drones perform frequent, on-demand inspections to enhance the safety, reliability, and integrity 
                 of power lines, thereby improving operational efficiency and mitigating risks.
</p>
<br/>
<p className='para-style' style={{ fontSize: '23px', textAlign: 'left', color:'black',fontFamily:'Sans-serif',fontWeight:'bold', marginLeft: '350px'  }}>
Fault Inspection</p>
<p className='para-style' style={{ textAlign: 'left', color:'black',fontFamily:'Sans-serif', marginLeft: '400px'  }}>
Drones capture detailed images of the grid from multiple angles, identifying faults or defects 
                 promptly and allowing for timely corrective actions.
</p>
<br/>
<p className='para-style' style={{ fontSize: '23px', textAlign: 'left', color:'black',fontFamily:'Sans-serif',fontWeight:'bold', marginLeft: '350px'  }}>
Wildfire Inspection</p>
                 <p className='playfair-display' style={{ textAlign: 'left', color:'black',fontFamily:'Sans-serif', marginLeft: '350px'  }}>
                 Drones equipped with radiant heat sensors provide real-time temperature readings of power lines, enabling immediate
                  identification of anomalies to prevent wildfires. Additionally, drone surveillance monitors damage to power lines
                   caused by wildfires from natural disasters, ensuring the continued safe operation of the power grid.
</p>
<br/>
<p className='para-style' style={{ fontSize: '23px', textAlign: 'left', color:'black',fontFamily:'Sans-serif',fontWeight:'bold', marginLeft: '350px'  }}>
What kind of maps can you expect from a power line inspection drone?<br/>3D Model</p>
                 <p className='para-style' style={{ textAlign: 'left', color:'black',fontFamily:'Sans-serif', marginLeft: '350px'  }}>
                 Commercial drones capture oblique imagery of power lines, which is then processed by third-party tools to generate detailed
                  3D models. These models can be used to calculate the height of the powerlines relative to the ground, the distance between
                   the power lines and the nearest obstacles, and the spacing between towers
</p>
<br/>

<p className='para-style' style={{ fontSize: '23px', textAlign: 'left', color:'black',fontFamily:'Sans-serif',fontWeight:'bold', marginLeft: '350px'  }}>
3D Point Cloud</p>
                 <p className='para-style' style={{ textAlign: 'left', color:'black', fontFamily:'Sans-serif',marginLeft: '350px'  }}>
                 Using a LiDAR drone, spatial coordinates of sampled points on the surface of an object are acquired, resulting in a 
                 "Point Cloud." This point cloud is created by integrating laser measurement with photogrammetry principles, capturing
                  three-dimensional coordinates (XYZ), laser reflection intensity (Intensity), and colour information (RGB). It is
                   utilized for 3D visual management of power lines, route planning, and design purposes.
</p>
<br/>
<img
      className="slide-icon"
      src={image3}
      alt="img"
      style={{ height: '400px', width: '600px', margin: '0 auto',marginLeft:'430px' }}
    />
    <br/>
    <br/>
    <p className='para-style' style={{ fontSize: '23px', textAlign: 'left', color:'black',fontFamily:'Sans-serif',fontWeight:'bold', marginLeft: '350px'  }}>
    Thermal Map</p>
                 <p className='para-style' style={{ textAlign: 'left', color:'black', fontFamily:'Sans-serif',marginLeft: '350px'  }}>
                 Utilizing a thermal drone allows for the measurement of heat emitted from power lines, facilitating <br/>
                 analysis of abnormal voltage and contact resistance. This technology aids operation and maintenance<br/>
                  personnel in the timely detection of defects for corrective action.
</p>
<br/>
<img
      className="slide-icon"
      src={image4}
      alt="img"
      style={{ height: '400px', width: '600px', margin: '0 auto',marginLeft:'430px' }}
    />
                  &emsp;&emsp; &emsp;&emsp;
                  &emsp;&emsp;
                  <br/>

 <br/>


 <footer style={{ fontSize: '10px', padding: '40px', backgroundColor: '#000068', color: '#000', width: '1500px', marginLeft:'150px' }}>
      <div className="footer-col">
        <div className="container">
          <div className="list-col">
            <div className="row">
              <div className="col-md-12 col-lg-4 col-mobile-down">
              <div className="mobile-collapse">
                <img className="top-bar__logo" src={image6} alt='logo' style={{ height: '180px', width: '180px', marginLeft: '30px',marginTop:'-40px' }} />

                <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white' }}>Contact Information</h4>
                &emsp;
                  <div className="pt-collapse-content  playfair-display"style={{  color: 'white',fontSize:'12px' }}>
                    <address className=' playfair-display'>
                    <div style={{ display: 'flex' }}>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ fill: 'white' }}>
    <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
  </svg>
  <span> # 70 B block
          1st floor, Anandnagar
          Mysore - 570022, Karnataka
          </span> 
                   </div>
                   <a className='playfair-display' href="mailto:pieflyaerospace@gmail.com" style={{ color: 'white', fontSize: '12px', display: 'flex', alignItems: 'center' }}>
  <span style={{ fontSize: '24px', color: 'white', marginRight: '8px' }}>
    <MdMailOutline />
  </span>
  Info@pieflyaerospace.com
</a>

                    </address>
                   
                    
                  </div>
                </div>
              </div>

              <div className="col-md-3 col-lg-2" style={{ fontFamily: 'Sans-serif',marginLeft: '200px', marginRight: '100px' }}>
  <div className="mobile-collapse" style={{fontFamily: 'Sans-serif', maxWidth: '400px', marginLeft: 'auto', marginRight: 'auto' }}>
    <h4 className="footer-title collapse-title playfair-display" style={{fontFamily: 'Sans-serif', color: 'white', textAlign: 'center' }}>Quick Links</h4>
    <div className="pt-collapse-content" style={{ fontFamily: 'Sans-serif',color: 'white', fontSize: '12px' }}>
      <div className="oval-grid" style={{fontFamily: 'Sans-serif', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '20px', padding: '10px' }}>
        <div className="oval-box" style={{fontFamily: 'Sans-serif', width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="#" style={{ fontFamily: 'Sans-serif',color: 'black', textAlign: 'center' }}><span className='playfair-display'>Home</span></a>
        </div>
        <div className="oval-box" style={{fontFamily: 'Sans-serif', width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="/#/training" style={{ fontFamily: 'Sans-serif',color: 'black', textAlign: 'center' }}><span className='playfair-display'>Training Program</span></a>
        </div>
        <div className="oval-box" style={{fontFamily: 'Sans-serif', width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="#" style={{fontFamily: 'Sans-serif', color: 'black', textAlign: 'center' }}><span className='playfair-display'>Products</span></a>
        </div>
        <div className="oval-box" style={{ fontFamily: 'Sans-serif',width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="/#/contactus" style={{ fontFamily: 'Sans-serif',color: 'black', textAlign: 'center' }}><span className='playfair-display'>Contact Us</span></a>
        </div>
        <div className="oval-box" style={{ fontFamily: 'Sans-serif',width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="#" style={{fontFamily: 'Sans-serif', color: 'black', textAlign: 'center' }}><span className='playfair-display'>Services</span></a>
        </div>
        <div className="oval-box" style={{fontFamily: 'Sans-serif', width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="#" style={{ fontFamily: 'Sans-serif',color: 'black', textAlign: 'center' }}><span className='playfair-display'>About Us</span></a>
        </div>
      </div>
    </div>
  </div>
</div>



</div>
         </div>
          </div>
        </div>
      {/* </div> */}
      <div className="footer-custom">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4">
              {/* <div className="copyright"style={{color: 'white',fontSize:'12px' }}>
                &copy; 2019 Quadron. All rights reserved.
              </div> */}
            </div>
           
                <div className='social-icons' style={{marginLeft:'600px',marginTop:'30px'}}>
  <a className='social-icon-link facebook' href='/' target='_blank' aria-label='Facebook'>
    <FontAwesomeIcon icon={faFacebookF} />
  </a>
  <a className='social-icon-link instagram' href='https://www.instagram.com/pieflyofficial?igsh=bmU0emdnbWs2aGIw' target='_blank' aria-label='Instagram' style={{marginLeft:'-40px'}}>
    <FontAwesomeIcon icon={faInstagram} />
  </a>
  <a className='social-icon-link twitter' href='https://x.com/pieflyofficial?t=RHOzsk0Vc47uIvKSCc5XVA&s=09' target='_blank' aria-label='Twitter' style={{marginLeft:'-40px'}}>
  <TfiTwitter />
  </a>
  <a className='social-icon-link linkedin' href='https://in.linkedin.com/company/piefly-r-d-private-limited' target='_blank' aria-label='LinkedIn' style={{marginLeft:'-40px'}}>
  <AiOutlineLinkedin />
  </a>
  <a className='social-icon-link Youtube' href='https://youtube.com/@piefly4302?si=Qecbd2nJ80cYx-Hk' target='_blank' aria-label='Youtube' style={{marginLeft:'-40px'}}>
  <BsYoutube />  </a>
</div>

              </div>
            </div>
          </div>
        
    </footer>
    </div>
                 
                 </>
);
};
export default Powerline;