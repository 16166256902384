import React, { useState, useEffect, useRef } from 'react';
import image from '../svg-sprite/mainslider/crop1.png';
import image1 from '../svg-sprite/mainslider/geo.png';
import image2 from '../svg-sprite/mainslider/assessment.png';
import image3 from '../svg-sprite/mainslider/analysis.png';
import image4 from '../svg-sprite/mainslider/crop2.png';
import image5 from '../svg-sprite/mainslider/agriculturedrone.png.jpeg';
import "./Dropdown.css";
import Navbar from './navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { TfiTwitter } from "react-icons/tfi";
import { AiOutlineLinkedin } from "react-icons/ai";
import { BsYoutube } from "react-icons/bs";
import Modal from './EnquiryModal'; // Assuming EnquiryModal component is in a file named EnquiryModal.js
import { MdMailOutline } from "react-icons/md";
import image6 from '../svg-sprite/mainslider/pieflylogo.png';
import image7 from '../svg-sprite/mainslider/agriculturedrone1.png';
import image8 from '../svg-sprite/mainslider/cuttingedge.png';
import video from '../svg-sprite/mainslider/animate.mp4';
import image9 from '../svg-sprite/mainslider/benefits1.png';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';




const Agriculture = () => {
 
  const dropdownTimeoutRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEnquireClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
useEffect(() => {
  return () => {
    clearTimeout(dropdownTimeoutRef.current);
  };
}, []);


  return (
    <> 
<div> <Navbar/> </div>

      <div>
        <div style={{ textAlign: 'center', padding: '5px',marginTop: '130px' }}>
   
    <img
      className="slide-icon"
      src={image5}
      alt="img"
      style={{ height: '600px', width: '1500px', margin: '0 auto',marginLeft:'0px' }}
    />
     <h1 className="title title-lg playfair-display" style={{ fontSize: '4em',marginTop: '-260px',color:'white'}}>
    AGRICULTURE
    </h1>
</div>
<br/><br/><br/><br/><br/>
<div style={{ backgroundColor: 'orange', marginTop: '-5px' }}>
  <br/><br/><br/>
  <div style={{ display: 'flex', alignItems: 'center', padding: '30px 130px', gap: '20px' }}>
  <div>
    <img
      className="slide-icon"
      src={image7}
      alt="img"
      style={{ height: '400px', width: '600px' }}
    />
  </div>
  <div className="para-style" style={{ textAlign: 'left', color: 'white', maxWidth: '600px' }}>
    <span className="para-style" style={{ fontSize: '21px', fontFamily: 'Sans-serif', color: '#50C878' }}>
      PRECISION AGRICULTURE DRONE SPRAYING SERVICE
    </span>
    <br/><br/>
    Piefly Aerospace delivers precision agricultural services that leverage the latest aerial
    and ground-based technologies to revolutionise farming practices. Our team of experts
    is dedicated to providing innovative solutions tailored to the needs of your business.
    <br/> With our precision agriculture services, we aim to optimise your farming operations
    with cost-effective and efficient strategies—Trust Pie Fly Aerospace to bring you the
    future of agriculture through our expertise and commitment to excellence.
  </div>
</div>

  <br/><br/>

  {/* Embedded YouTube Video */}
  <iframe
    width="560"
    height="315"
    src="https://www.youtube.com/embed/Uk7AQyE5gKs"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
    style={{ marginLeft: '730px' }}
  ></iframe>
  <br/><br/>

  <p className='para-style' style={{ color: 'white', marginLeft: '200px', marginTop: '70px' }}>
  <span className='para-style' style={{ fontSize: '30px', fontFamily: 'Sans-serif', color: '#50C878' }}>
    Innovative Farming Methodology:<br/> Cutting-Edge UAV Platforms
  </span>
  <br/><br/><br/><br/>
  Piefly Aerospace's precision agricultural drones are proven to outpace traditional methods, <br/>delivering
  spray applications faster and more efficiently.<br/> Our intelligent control system meticulously records service 
  status, ensuring precise <br/>application for every project. With centimetre-level navigation accuracy, our drones<br/>
  guarantee pinpoint precision in every pass.<br/><br/><br/><br/>
  Our cutting-edge rotary spraying technology disperses micron-level droplets evenly across crops, maximising
  coverage and adherence. Enhanced by optimised downdraft from the propellers, our system minimises drift, ensuring 
  maximum effectiveness while reducing pesticide use by 30% and slashing water waste by 90%. Covering larger areas in 
  less time, our drones reduce labour, time, and chemical costs while safeguarding farm produce quality and environmental
  integrity—Trust Pie fly Aerospace for unparalleled efficiency and sustainability in precision agriculture.
</p>

  <div>
    <img
      className="slide-icon"
      src={image8}
      alt="img"
      style={{ height: '300px', width: '600px', marginTop: '-600px', marginLeft: '850px' }}
    />
  </div>
  <br/><br/>
</div>

<div className="video">
            <video width="50%"  loop autoPlay muted style={{marginLeft:'10px'}}>
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <p className='para-style' style={{ marginRight: '50px', color: 'white', marginLeft: '850px', marginTop: '-470px' }}>
  <span className='para-style' style={{ fontSize: '20px', fontFamily: 'Sans-serif', color: 'skyblue', marginLeft: '60px', fontWeight: 'bold' }}>
    WHAT IS AGRICULTURE SPRAYING?
  </span>
  <br/><br/>
  <span className='para-style' style={{ fontSize: '18px', color: 'black', marginLeft: '0px' }}>
    Agricultural drones are revolutionising farming practices. With rising demand,<br/>
    farmers must navigate a complex array of factors that affect the success of their<br/>
    operations, including water access, soil quality, rainfall patterns, temperature<br/>
    fluctuations, climate change, wind conditions, and the presence of weeds and<br/>
    insects. To enhance and sustain productivity, farmers need to innovate<br/>
    continuously. Digital technologies like drone spraying offer significant potential<br/>
    by providing essential data to capitalise on growth opportunities and address<br/>
    these challenges. As a result, farmers are increasingly turning to agricultural<br/>
    drone technology to help mitigate these issues.
  </span>
</p>

          <div>
    <img
      className="slide-icon"
      src={image9}
      alt="img"
      style={{ height: '600px', width: '700px', marginTop: '84px', marginLeft: '760px' }}
    />
  </div>

  <p className='para-style' style={{ textAlign: 'left', color: 'white', marginLeft: '100px', marginTop: '-560px' }}>
  <span className='para-style' style={{ fontSize: '25px', fontFamily: 'Sans-serif', color: '#32CD32', marginLeft: '150px', fontWeight: 'bold' }}>
    Benefits of Agricultural<br />
    &emsp;&emsp; <span className='para-style' style={{ fontSize: '20px', marginLeft: '60px',color:'blue' }}>Drone Spraying:</span>
  </span>
  <br /><br />
  <span className='para-style' style={{ fontSize: '15px', color: 'black', marginLeft: '0px' }}>
    <span className='para-style' style={{ fontSize: '18px', fontFamily: 'Sans-serif', color: 'black', fontWeight: 'bold' }}>
    &emsp;&emsp;&emsp;&emsp; 1. Cost Savings
    </span><br />
    &emsp;&emsp;&emsp; &emsp;&emsp;&emsp;Precision control systems ensure that chemical applications and pesticide<br />
    &emsp;&emsp;&emsp;&emsp; &emsp;&emsp;spraying are targeted precisely where needed, saving both time and chemical costs.<br /><br />
    <span className='para-style' style={{ fontSize: '18px', fontFamily: 'Sans-serif', color: 'black', fontWeight: 'bold' }}>
    &emsp;&emsp;&emsp;&emsp; 2. Scientifically Proven
    </span><br />
    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;XAG technology has been tested in wind tunnels to ensure minimal spray drift,<br />
    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;exceeding industry standards.<br /><br />
    <span className='para-style' style={{ fontSize: '18px', fontFamily: 'Sans-serif', color: 'black', fontWeight: 'bold' }}>
    &emsp;&emsp;&emsp;&emsp; 3. Pest and Disease Control
    </span><br />
    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Effectively manage invasive species and noxious weeds to maintain production.<br />
    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Achieve biosecurity and disease control with the push of a button.
  </span>
</p>

  <br/><br/>
   
&emsp;&emsp; &emsp;&emsp;
                  &emsp;
                  &emsp;
                  &emsp;
                  &emsp;
                  <br/>

 <br/>

 <br/>

</div>
   

    <footer style={{ fontSize: '10px', padding: '40px', backgroundColor: '#000068', color: '#000', width: '1500px', marginLeft:'150px' }}>
      <div className="footer-col">
        <div className="container">
          <div className="list-col">
            <div className="row">
              <div className="col-md-12 col-lg-4 col-mobile-down">
              <div className="mobile-collapse">
                <img className="top-bar__logo" src={image6} alt='logo' style={{ height: '180px', width: '180px', marginLeft: '30px',marginTop:'-40px' }} />

                <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white' }}>Contact Information</h4>
                &emsp;
                  <div className="pt-collapse-content  playfair-display"style={{  color: 'white',fontSize:'12px' }}>
                    <address className=' playfair-display'>
                    <div style={{ display: 'flex' }}>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ fill: 'white' }}>
    <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
  </svg>
                   <span> # 70 </span>B block 
                   
                   <span>1st</span> floor Anandnagar Mysore<span> 570022</span> Karnataka<br />
                   </div>
                   <a className='playfair-display' href="mailto:pieflyaerospace@gmail.com" style={{ color: 'white', fontSize: '12px', display: 'flex', alignItems: 'center' }}>
  <span style={{ fontSize: '24px', color: 'white', marginRight: '8px' }}>
    <MdMailOutline />
  </span>
  Info@pieflyaerospace.com
</a>

                    </address>
                    
                  </div>
                </div>
              </div>
              {/* <div className="col-md-3 col-lg-2" style={{ marginLeft: 'auto', marginRight: '100px' }}> */}
              <div className="col-md-3 col-lg-2" style={{ fontFamily: 'Sans-serif',marginLeft: '200px', marginRight: '100px' }}>
  <div className="mobile-collapse" style={{fontFamily: 'Sans-serif', maxWidth: '400px', marginLeft: 'auto', marginRight: 'auto' }}>
    <h4 className="footer-title collapse-title playfair-display" style={{fontFamily: 'Sans-serif', color: 'white', textAlign: 'center' }}>Quick Links</h4>
    <div className="pt-collapse-content" style={{ fontFamily: 'Sans-serif',color: 'white', fontSize: '12px' }}>
      <div className="oval-grid" style={{fontFamily: 'Sans-serif', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '20px', padding: '10px' }}>
        <div className="oval-box" style={{fontFamily: 'Sans-serif', width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="#" style={{ fontFamily: 'Sans-serif',color: 'black', textAlign: 'center' }}><span className='playfair-display'>Home</span></a>
        </div>
        <div className="oval-box" style={{fontFamily: 'Sans-serif', width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="/#/training" style={{ fontFamily: 'Sans-serif',color: 'black', textAlign: 'center' }}><span className='playfair-display'>Training Program</span></a>
        </div>
        <div className="oval-box" style={{fontFamily: 'Sans-serif', width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="#" style={{fontFamily: 'Sans-serif', color: 'black', textAlign: 'center' }}><span className='playfair-display'>Products</span></a>
        </div>
        <div className="oval-box" style={{ fontFamily: 'Sans-serif',width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="/#/contactus" style={{ fontFamily: 'Sans-serif',color: 'black', textAlign: 'center' }}><span className='playfair-display'>Contact Us</span></a>
        </div>
        <div className="oval-box" style={{ fontFamily: 'Sans-serif',width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="#" style={{fontFamily: 'Sans-serif', color: 'black', textAlign: 'center' }}><span className='playfair-display'>Services</span></a>
        </div>
        <div className="oval-box" style={{fontFamily: 'Sans-serif', width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="#" style={{ fontFamily: 'Sans-serif',color: 'black', textAlign: 'center' }}><span className='playfair-display'>About Us</span></a>
        </div>
      </div>
    </div>
  </div>
</div>
   

</div>
         </div>
          </div>
        </div>
      {/* </div> */}

      <div className="footer-custom">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4">
              {/* <div className="copyright"style={{color: 'white',fontSize:'12px' }}>
                &copy; 2019 Quadron. All rights reserved.
              </div> */}
            </div>
           
                <div className='social-icons' style={{marginLeft:'600px',marginTop:'30px'}}>
                <a className='social-icon-link whatsapp' href='https://wa.me/yourwhatsapplink' target='_blank' aria-label='WhatsApp' style={{ marginLeft: '-40px' }}>
  <FontAwesomeIcon icon={faWhatsapp} />
  </a>
  <a className='social-icon-link facebook' href='/' target='_blank' aria-label='Facebook'>
    <FontAwesomeIcon icon={faFacebookF} />
  </a>
  <a className='social-icon-link instagram' href='https://www.instagram.com/pieflyofficial?igsh=bmU0emdnbWs2aGIw' target='_blank' aria-label='Instagram' style={{marginLeft:'-40px'}}>
    <FontAwesomeIcon icon={faInstagram} />
  </a>
  <a className='social-icon-link twitter' href='https://x.com/pieflyofficial?t=RHOzsk0Vc47uIvKSCc5XVA&s=09' target='_blank' aria-label='Twitter' style={{marginLeft:'-40px'}}>
  <TfiTwitter />
  </a>
  <a className='social-icon-link linkedin' href='https://in.linkedin.com/company/piefly-r-d-private-limited' target='_blank' aria-label='LinkedIn' style={{marginLeft:'-40px'}}>
  <AiOutlineLinkedin />
  </a>
  <a className='social-icon-link Youtube' href='https://youtube.com/@piefly4302?si=Qecbd2nJ80cYx-Hk' target='_blank' aria-label='Youtube' style={{marginLeft:'-40px'}}>
  <BsYoutube />  </a>
</div>

              </div>
            </div>
          </div>
        
    </footer>
    
    </>
    );
};
export default Agriculture;
