import { HashRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css';
import Enroll from './New/Enroll';
import Homepage from './New/homepage';
import ProductListing from './New/ProductListing';
import Login from './New/login';
import Dashboard from './New/Dashboard';
import Account from './New/UserAcc';
import About from './New/About';
import Details from './New/Details';
import Traceability from './New/Traceability';
import Pieflykisandrones from './New/Pieflykisandrones';
import Dropdown from './New/Dropdown';
import Surveillance from './New/Surveillance'; 
import Mapping from './New/Mapping';
import Seed from './New/Seed';
import Loudspeakerdrones from './New/Loudspeakerdrones';
import Ugvdrone from './New/Ugvdrone';
import Fixedwing from './New/Fixedwing';
import Rpto from './New/rpto';
import Agriculture from './New/Agriculture';
import Disastermanagement from './New/Disastermanagement';
import Antidrone from './New/Antidrone';
import Highpayload from './New/Highpayload';
import Trafficmonitor from './New/Trafficmonitor';
import Structuralinspection from './New/Structuralinspection';
import Topography from './New/Topography';
import Windturbine from './New/Windturbine';
import Powerline from './New/Powerline';
import Forestmanagement from './New/Forestmanagement';
import Weather from './New/Weather';
import Searchdrones from './New/Searchdrones';
import Contactus from './New/Contactus';
import Navbar from './New/navbar';
import Footer from './New/Footer ';
import PrivateRoute from './util/privateroute'; // Adjust the path as per your project structure
import DraggableImage from './New/DraggableImage';
import Aboutt from './New/Aboutt';
import Training from './New/Training';
import Studentlogin from './New/Studentlogin';
import Onlineadmission from './New/Onlineadmission';
import Register from './New/Register';
import Solarpanelcleaning from './New/Solarpanelcleaning';
import Celltower from './New/Celltower';
import EnquiryModal from './New/EnquiryModal';
import DocumentsModal from './New/DocumentsModal';
import Contactus1 from './New/Contactus1';
import Dropdown2 from './New/Dropdown2';
import Dropdown3 from './New/Dropdown3';
import InternshipEnroll from './New/Internshipenroll';
import WorkshopEnroll from './New/Workshopenroll';
import Workshop from './New/Workshop';
// import Download from './New/Download';

function App() {
  return (
    <div>
      <Router>
        <Routes>
        <Route>
          <Route path="/" element={<Homepage />} />
          <Route path="/homepage" element={<Homepage />} />
          <Route path="/about" element={<About />} />
          <Route path="/enrollmentrequest" element={<Details />} />
          <Route path="/traceability" element={<Traceability />} />
          <Route path="/shoppingbagicon" element={<ProductListing />} />
          <Route path="/shoppingbagicon" element={<Footer />} />
          <Route path="/login" element={<Login />} />
           <Route path="/users" element={<PrivateRoute/>}/> 
          <Route path="/superdashboard" element={<Dashboard />} />
          <Route path="/enroll" element={<Enroll />} />
          <Route path="/Logout" element={<Homepage />} />
          <Route path="/useracc" element={<Account />} />
          <Route path="/Pieflykisandrones" element={<Pieflykisandrones />} />

          <Route path="/surveillance" element={<Surveillance />} />
          <Route path="/Mapping" element={<Mapping />} />
          <Route path="/seed" element={<Seed />} />
          <Route path="/loudspeakerdrone" element={<Loudspeakerdrones />} />
          <Route path="/ugvdrone" element={<Ugvdrone />} />
          <Route path="/Fixedwing" element={<Fixedwing />} />
          <Route path="/Rpto" element={<Rpto />} />
          <Route path="/Agriculture" element={<Agriculture />} />
          <Route path="/disaster" element={<Disastermanagement />} />
          <Route path="/antidrone" element={<Antidrone />} />
          <Route path="/highpayload" element={<Highpayload />} />
          <Route path="/trafficmonitor" element={<Trafficmonitor />} />
          <Route path="/structuralinspection" element={<Structuralinspection />} />
          <Route path="/topography" element={<Topography />} />
          <Route path="/windturbine" element={<Windturbine />} />
          <Route path="/powerline" element={<Powerline />} />
          <Route path="/forestmanagement" element={<Forestmanagement />} />
          <Route path="/weather" element={<Weather />} />
          <Route path="/searchdrones" element={<Searchdrones />} />
          <Route path="/navbar" element={<Navbar />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/drag" element={<DraggableImage/>}/>
          <Route path="/aboutt" element={<Aboutt/>}/>
          <Route path="/training" element={<Training/>}/>
          <Route path="/studentlogin" element={<Studentlogin/>}/>
          <Route path="/onlineadmission" element={<Onlineadmission/>}/>
          <Route path="/register" element={<Register/>}/>
          <Route path="/Solarpanelcleaning" element={<Solarpanelcleaning/>}/>
          <Route path="/Celltower" element={<Celltower/>}/>
          <Route path="/EnquiryModal" element={<EnquiryModal/>}/>
          <Route path="/DocumentsModal" element={<DocumentsModal/>}/>
          <Route path="/Contactus1" element={<Contactus1/>}/>
          <Route path="/Dropdown2" element={<Dropdown2/>}/>
          <Route path="/Dropdown3" element={<Dropdown3/>}/>
          <Route path="/InternshipEnroll" element={<InternshipEnroll/>}/>
          <Route path="/WorkshopEnroll" element={<WorkshopEnroll/>}/>
          <Route path="/Workshop" element={<Workshop/>}/>
          {/* <Route path="/Download" element={<Download/>}/> */}
          
        </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
