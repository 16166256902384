import React, { useState, useEffect, useRef } from 'react';
import image1 from '../svg-sprite/mainslider/certification.png';
import image2 from '../svg-sprite/mainslider/cost.png';
import image3 from '../svg-sprite/mainslider/learner.png';
import image4 from '../svg-sprite/mainslider/eligible.png';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import Sidebar from './Sidebar';
import Footer from './Footer ';
import { MdCall } from "react-icons/md";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { BsYoutube } from "react-icons/bs";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './Footer.css'
import Dropdown3 from './Dropdown3';
import Modal from './DocumentsModal'; // Assuming EnquiryModal component is in a file named EnquiryModal.js
import { TfiTwitter } from "react-icons/tfi";
import { AiOutlineLinkedin } from "react-icons/ai";
import Navbar from './navbar';
import './Workshop.css'
import image5 from '../svg-sprite/mainslider/workshop.png';
import image6 from '../svg-sprite/mainslider/businessman-with-success-certification.png';
import image7 from '../svg-sprite/mainslider/costeffective.png';
import image8 from '../svg-sprite/mainslider/learning-workshop.png';
import image9 from '../svg-sprite/mainslider/participate.png';
import image10 from '../svg-sprite/mainslider/About us page-amico.png';
import image11 from '../svg-sprite/mainslider/laptop.png';
import image12 from '../svg-sprite/mainslider/benefits.png';

import image13 from '../svg-sprite/mainslider/workshop1.png.JPG';
import image14 from '../svg-sprite/mainslider/workshop2.png.JPG';
import image15 from '../svg-sprite/mainslider/workshop3.png.JPG';
import image16 from '../svg-sprite/mainslider/workshop4.png.JPG';
import video1 from '../svg-sprite/mainslider/PieflyWorkshop (1).mp4';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
 
function Workshop() {
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [userData, setUserData] = useState(null);
    const dropdownTimeoutRef = useRef(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [dropdown3, setDropdown3] = useState(false);
    const images = [image13, image14, image15, image16];
    const [slideIndex, setSlideIndex] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        setSlideIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 2000);
      return () => clearInterval(interval);
    }, [images.length]);

    const plusSlides = (n) => {
      setSlideIndex((prevIndex) => (prevIndex + n + images.length) % images.length);
    };

    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderRef = useRef(null);

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      arrows: true,
    };

    const [selectedDay, setSelectedDay] = useState('day1');

    const handleDayClick = (day) => {
      setSelectedDay(day);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleEnquireClick = () => {
      setIsModalOpen(true);
    };

    const closeModal = () => {
      setIsModalOpen(false);
    };

    const handleEnrollMouseEnter = (e) => {
      e.target.style.borderColor = 'white';
      e.currentTarget.style.transform = 'scale(1.05)'; // Zoom in effect
      setDropdown3(true);
    };

    const handleEnrollMouseLeave = (e) => {
      e.target.style.borderColor = 'transparent';
      e.currentTarget.style.transform = 'scale(1)'; // Zoom out effect
      setDropdown3(false);
    };

    const liStyles = {
      transition: 'transform 0.3s ease', // Add transition for smooth effect
      transform: isHovered ? 'scale(1.05)' : 'scale(1)', // Zoom in/out effect
    };

    const handleRPTOMouseEnter = (e) => {
      e.target.style.borderColor = 'white';
    };

    const handleRPTOMouseLeave = (e) => {
      e.target.style.borderColor = 'transparent';
    };

    const handleAboutUsMouseEnter = (e) => {
      e.target.style.borderColor = 'white';
    };

    const handleAboutUsMouseLeave = (e) => {
      e.target.style.borderColor = 'transparent';
    };

    const handleNewsMouseEnter = (e) => {
      e.target.style.borderColor = 'white';
    };

    const handleNewsMouseLeave = (e) => {
      e.target.style.borderColor = 'transparent';
    };

    const handleArticlesMouseEnter = (e) => {
      e.target.style.borderColor = 'white';
    };

    const handleArticlesMouseLeave = (e) => {
      e.target.style.borderColor = 'transparent';
    };

    const handleContactUsMouseEnter = (e) => {
      e.target.style.borderColor = 'white';
    };

    const handleContactUsMouseLeave = (e) => {
      e.target.style.borderColor = 'transparent';
    };

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleMouseEnter2 = () => {
      setDropdownOpen(true);
    };

    const handleMouseLeave2 = () => {
      setDropdownOpen(false);
    };

    const handleLogin = () => {
      setLoggedIn(true);
      setUserData(/* user data object */);
    };

   

    const [isHoveredGeneral, setIsHoveredGeneral] = useState(false);
    const [isHoveredStudents, setIsHoveredStudents] = useState(false);

    const handleMouseEnterGeneral = () => {
      setIsHoveredGeneral(true);
    };

    const [currentSlide1, setCurrentSlide1] = useState(0);
    const sliderRef1 = useRef(null);

    const settings1 = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true, // disable slider autoplay
      arrows: true,
      afterChange: (current) => setCurrentSlide(current), // update current slide index
      volume: 0.5, // set volume level (0.0 to 1.0)
    };
    
    // Example function to apply volume to a media element
    const applyVolume = (mediaElement, volume) => {
      if (mediaElement) {
        mediaElement.volume = volume;
      }
    };
    
    // Example usage
    const mediaElement = document.getElementById('myVideoElement');
    applyVolume(mediaElement, settings1.volume);

    const [isMuted, setIsMuted] = useState(true);

    const handleMuteToggle = () => {
      setIsMuted(!isMuted);
    };

    const handleVideoEnded = () => {
      if (sliderRef1.current) {
        sliderRef1.current.slickNext();
      }
    };

    useEffect(() => {
      const videos = document.querySelectorAll('video');
      videos.forEach((video) => {
        video.addEventListener('ended', handleVideoEnded);
      });

      return () => {
        videos.forEach((video) => {
          video.removeEventListener('ended', handleVideoEnded);
        });
      };
    }, []);

    useEffect(() => {
      const videos = document.querySelectorAll('video');
      const currentVideo = videos[currentSlide1];

      if (currentSlide1 === 0 && currentVideo) {
        currentVideo.play().catch(() => {
          console.error('Autoplay failed. User interaction may be required.');
        });
      }
    }, [currentSlide1]);

    const handleMouseLeaveGeneral = () => {
      setIsHoveredGeneral(false);
    };

    const handleMouseEnterStudents = () => {
      setIsHoveredStudents(true);
    };

    const handleMouseLeaveStudents = () => {
      setIsHoveredStudents(false);
    };

    const [scrolledUp, setScrolledUp] = useState(true);

    useEffect(() => {
      const handleScroll = () => {
        const isScrolledUp = window.scrollY === 0;
        setScrolledUp(isScrolledUp);
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
        clearTimeout(dropdownTimeoutRef.current);
      };
    }, []);

  return (
    <>
    <div>
      &emsp;&emsp;&emsp;
    {/* Start Header */}
    <Navbar/>
       
    <div className="container">

    <div className="abc">
          <p className='playfair-display' style={{ marginTop: '-200px', fontSize: '45px', fontWeight: 'bold', marginLeft: '-50px', color: '#DAA520' }}> DRONE </p>
          <p className='playfair-display' style={{ marginTop: '-80px', fontSize: '65px', fontWeight: 'bold', marginLeft: '-160px', color: '#DAA520' }}> Workshop </p>
          <p className='playfair-display' style={{ marginTop: '100px', fontSize: '17px', fontWeight: 'bold', marginLeft: '-280px', color: '#F08000' }}>FIRST-EVER DRONE WORKSHOP IN <span className='playfair-display' style={{ color: '#DAA520' }}>MYSORE</span> BY <span className='playfair-display' style={{ color: '#DAA520' }}>PIEFLY AVIATION</span> </p>
          <div className='playfair-display' style={{ marginTop: '170px', fontSize: '17px', fontWeight: 'bold', marginLeft: '-465px', color: '#F08000' }}>Take off on a rewarding journey with our drone internship </div>
        </div>
     {/* <div className="image-background">
     <img src="path/to/your/image.jpg"  />

            </div> */}
              <div className="slideshow-container">
            <Slider ref={sliderRef} {...settings}>
              {images.map((image, index) => (
                <div key={index} className="mySlides fade">
                  <img
                    className="slide-icon"
                    src={image}
                    alt={`Slide ${index + 1}`}
                    style={{ height: '505px', width: '930px', margin: '0 auto' }}
                  />
                </div>
              ))}
            </Slider>
          </div>
     </div>
    
     


     <div className="hero-containerr">
        <Slider ref={sliderRef1} {...settings1}>
          
          <div className="videoo">
            <video width="100%"  autoPlay loop unmuted>
              <source src={video1} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          
        </Slider>
      </div>

     
     
     
     <img
      className="slide-icon"
      src={image5}
      alt="img"
      style={{ height: '730px', width: '1030px', margin: '0 10px',marginLeft:'-30px' }}
    />

      <br/><br/><br/>
      <p className='playfair-display' style={{ maxWidth: '420px',
        marginLeft: '1000px',
        marginRight: 'auto',
        color: '#333',
        marginTop: '-550px',
        
        lineHeight: '2.5',
        padding: '40px',
        border: '2px solid #ccc',
        fontSize:'18px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#f9f9f9'}}> Our proficient team will guide you through the latest advancements in drone technology in an intensive 2-day program </p>
      <br/><br/><br/><br/><br/><br/><br/>
      <p className='playfair-display'  style={{marginLeft:'670px',color:'black',fontWeight:'bold',fontSize:'23px'}}> Workshop highlights </p>
      
      <img
      className="slide-icon"
      src={image6}
      alt="img"
      style={{ height: '430px', width: '530px', margin: '0 10px',marginLeft:'200px' }}
    />
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: '350px',marginTop:'-350px' }}>
    <img
      className="slide-icon"
      src={image1}
      alt="img"
      style={{ height: '30px', width: '30px', margin: '0 10px',marginLeft:'400px' }}
    />

    <h1 className='playfair-display'  style={{color:'black',fontFamily:'Sans-serif',marginTop:'25px',fontSize:'18px'}}> CERTIFICATION</h1>

    <p className='playfair-display' style={{color:'black',fontFamily:'Sans-serif',marginTop:'130px',marginLeft:'-130px'}}> Upon successful enrolment in our workshop program, students<br/> will receive 
        a workshop certificate  from PieFly Aviation, with<br/> durations ranging from 2-5 days</p>
        </div>
        <br/><br/><br/><br/><br/><br/><br/><br/><br/>

        <div style={{marginLeft:'-130px'}}>
        <img
      className="slide-icon"
      src={image2}
      alt="img"
      style={{ height: '30px', width: '30px', margin: '0 10px',marginLeft:'400px' }}
    />

    <h1 className='playfair-display' style={{color:'black',fontFamily:'Sans-serif',marginTop:'-45px',fontSize:'16px',marginLeft:'450px'}}>COST-EFFECTIVE WORKSHOP</h1>

    <p className='playfair-display' style={{color:'black',fontFamily:'Sans-serif',marginTop:'-40px',marginLeft:'450px'}}>Access our comprehensive workshop content for a minimum price,<br/> providing high-quality,
     robust, and in-depth learning materials.</p>

    </div>
<br/>
<img
      className="slide-icon"
      src={image7}
      alt="img"
      style={{ height: '450px', width: '530px', margin: '0 10px',marginLeft:'840px',marginTop:'-300px' }}
    />
    <br/><br/><br/>
    <img
      className="slide-icon"
      src={image8}
      alt="img"
      style={{ height: '550px', width: '630px', margin: '0 10px',marginLeft:'190px',marginTop:'-100px' }}
    />
    <div style={{marginLeft:'430px',marginTop:'-350px'}}>
        <img
      className="slide-icon"
      src={image3}
      alt="img"
      style={{ height: '30px', width: '30px', margin: '0 10px',marginLeft:'400px' }}
    />

    <h1 className='playfair-display' style={{color:'black',fontFamily:'Sans-serif',marginTop:'-45px',fontSize:'16px',marginLeft:'450px'}}>LEARNER FRIENDLY</h1>

    <p className='playfair-display' style={{color:'black',fontFamily:'Sans-serif',marginTop:'-40px',marginLeft:'450px'}}>"Designed by experts with domain knowledge in Research <br/>and Training, our workshop is learner-friendly. 
    We use our<br/> microcontroller and have made all sessions Arduino<br/> Compatible, utilizing Open source platforms for convenience."</p>

    </div>
    
    <br/><br/><br/><br/><br/><br/><br/><br/>
    <div style={{marginLeft:'-150px'}}>
        <img
      className="slide-icon"
      src={image4}
      alt="img"
      style={{ height: '30px', width: '30px', margin: '0 10px',marginLeft:'400px' }}
    />

    <h1 className='playfair-display' style={{color:'black',fontFamily:'Sans-serif',marginTop:'-45px',fontSize:'16px',marginLeft:'450px'}}>WHO IS ELIGIBLE TO PARTICIPATE?</h1>

    <p className='playfair-display' style={{color:'black',fontFamily:'Sans-serif',marginTop:'-40px',marginLeft:'450px'}}>Our workshop programs are designed to cater to the interdisciplinary<br/> nature of modern education.
     As pioneers in the field of interdisciplinary <br/>training, we welcome students from all engineering, science domains,<br/> and other  degree programs, starting from their
      first year of studies.</p>

    </div>
    <img
      className="slide-icon"
      src={image9}
      alt="img"
      style={{ height: '550px', width: '630px', margin: '0 10px',marginLeft:'830px',marginTop:'-350px' }}
    />
    <br/> <br/> <br/>

    <div>
    <div className="buttons-container">
      <button className="stylish-button playfair-display" onClick={() => handleDayClick('day1')}>Day 1</button>
      <button className="stylish-button1 playfair-display" onClick={() => handleDayClick('day2')}>Day 2</button>
    </div>
    <br /><br />
    <div className="content-container">
      <div className="parent-container1">
        <div className="background-box1"></div>
        <div className="box1">
          <img
            className="slide-icon"
            src={image11}
            alt="img"
            style={{
              height: '250px',
              width: '400px',
              backgroundColor: 'white',
              margin: '0 10px',
              marginLeft: '200px',
              marginTop: '-30px',
            }}
          />
          <p
            className="playfair-display"
            style={{
              marginLeft: '320px',
              marginTop: '-150px',
              color: 'black',
              fontWeight: 'bold',
              fontFamily:'Sans-serif',
              transform: 'rotate(-15deg)',
              position: 'absolute',
            }}
          >
            Day 1
          </p>
          <p
            className="playfair-display"
            style={{
              marginLeft: '350px',
              marginTop: '-140px',
              color: 'black',
              fontWeight: 'bold',
              fontFamily:'Sans-serif',
              transform: 'rotate(-15deg)',
              position: 'absolute',
            }}
          >
            Workshop
          </p>
          <p className='playfair-display' style={{ color: 'black',fontFamily:'Sans-serif', fontWeight: 'bold' }}>
            SESSION-1
            <br />
            INTRODUCTION AND THEORY ON AERODYNAMICS AND FLUID DYNAMICS
          </p>
          <p className='playfair-display' style={{ color: 'black',fontFamily:'Sans-serif', fontSize: '18px' }}>
            In this workshop, we're diving into the exciting world of drones. But first, let's understand what a drone is. A drone is like a flying robot that can be controlled remotely. It has propellers that help it fly and often comes equipped with cameras or sensors for various purposes.
          </p>
          <br />
          <p className='playfair-display' style={{ color: 'black', fontFamily:'Sans-serif',fontWeight: 'bold' }}>
            SESSION-2
            <br />
            TEAM BUILDING AND DRONE PILOT SIMULATION
          </p>
          <p className='playfair-display' style={{ color: 'black', fontFamily:'Sans-serif',fontSize: '18px' }}>
            For a drone workshop centered on team activities, form diverse teams with roles like pilot and navigator. Use simulators for training and introduce challenges like obstacle courses to enhance skills. Simulate real-world scenarios to demonstrate practical drone uses, and balance the activities with competitive and collaborative elements to maintain engagement.
          </p>
          
          <p className='playfair-display' style={{ color: 'black', fontFamily:'Sans-serif',fontWeight: 'bold', fontSize: '20px' }}>
            Training Outcomes
          </p>
          <p className='playfair-display' style={{ color: 'black', fontFamily:'Sans-serif',fontSize: '18px' }}>
            By the end of this drone workshop, participants will have improved their technical skills in drone piloting and navigation through hands-on practice and simulator training. They will also have a clear understanding of various real-world applications of drones, from emergency response to environmental monitoring. Additionally, the team-based challenges will enhance their ability to work collaboratively and solve problems under pressure, equipping them with practical knowledge and teamwork skills applicable in multiple fields.
          </p>
          <br />
        </div>
      </div>
    </div>
<br/><br/>
    <div className="content-container">
      <div className="parent-container2">
        <div className="background-box2"></div>
        <div className="box2">
          <img
            className="slide-icon"
            src={image11}
            alt="img"
            style={{
              height: '250px',
              width: '400px',
              backgroundColor: 'white',
              margin: '0 10px',
              marginLeft: '200px',
              marginTop: '-30px',
            }}
          />
          <p
            className="playfair-display"
            style={{
              marginLeft: '320px',
              marginTop: '-150px',
              color: 'black',
              fontWeight: 'bold',
              transform: 'rotate(-15deg)',
              position: 'absolute',
              fontFamily:'Sans-serif',
            }}
          >
            Day 2
          </p>
          <p
            className="playfair-display"
            style={{
              marginLeft: '350px',
              marginTop: '-140px',
              color: 'black',
              fontWeight: 'bold',
              transform: 'rotate(-15deg)',
              position: 'absolute',
              fontFamily:'Sans-serif',
            }}
          >
            Workshop
          </p>
          <p className='playfair-display' style={{ color: 'black',fontFamily:'Sans-serif', fontWeight: 'bold' }}>
            SESSION-3
            <br />
            A HANDS-ON DRONE ASSEMBLY SESSION
          </p>
          <p className='playfair-display' style={{ color: 'black',fontFamily:'Sans-serif', fontSize: '18px' }}>
            Participants will have a thorough understanding of the mechanical and electronic components of drones. They will gain practical skills in assembling drones from scratch, troubleshooting common issues, and ensuring operational functionality. This session will empower attendees with the knowledge and confidence to build and maintain their drones, enhancing both their technical expertise and problem-solving abilities.
          </p>
          
          <p className='playfair-display' style={{ color: 'black',fontFamily:'Sans-serif', fontWeight: 'bold' }}>
            SESSION-4
            <br />
            DRONE FLIGHT AND CERTIFICATE CEREMONY
          </p>
          <p className='playfair-display' style={{ color: 'black',fontFamily:'Sans-serif', fontSize: '18px' }}>
            This part of the session ensures practical proficiency in drone navigation and operation. Following this, the certificate ceremony will acknowledge their accomplishments, providing them with formal recognition of their new skills in drone technology. This recognition not only validates their learning experience but also boosts their confidence and credentials in the field of drone operations.
          </p>
          <p className='playfair-display' style={{ color: 'black', fontFamily:'Sans-serif',fontWeight: 'bold', fontSize: '20px' }}>
            Training Outcomes
          </p>
          <p className='playfair-display' style={{ color: 'black', fontFamily:'Sans-serif',fontSize: '18px' , marginTop: '10px',
}}>
            The training outcomes will equip participants with a comprehensive understanding of drone mechanics and electronics, alongside practical skills in assembling and troubleshooting drones. They will also achieve proficiency in piloting and navigating drones under various conditions. The session culminates with a certificate ceremony, formally recognizing their new skills and boosting their credentials in drone technology. This dual focus on technical assembly and operational handling ensures participants leave well-prepared to engage with all aspects of drone usage effectively.
          </p>
          <br />
        </div>
      </div>
    </div>
  </div>
<br/><br/><br/>
    <div style={{backgroundColor: '#a8e6cf', // Light green background color
        padding: '20px',
        borderRadius: '10px',
        margin: '20px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'}}> 
        <img
      className="slide-icon"
      src={image10}
      alt="img"
      style={{ height: '400px', width: '430px', margin: '0 10px',marginLeft:'30px',marginTop:'-30px' }}
    />
    <h2 className='playfair-display' style={{color:'black',fontFamily:'Sans-serif',marginTop:'-345px',fontSize:'22px',marginLeft:'400px',fontSize:'18px'}}>ABOUT DRONE WORKSHOP</h2>
<p className='playfair-display' style={{color:'black',fontFamily:'Sans-serif',marginTop:'-10px',marginLeft:'570px'}}>The image of drones in the sky is becoming increasingly familiar, yet it's essential to recognize that drones are not toys. They play a crucial role in
     various fields, including nature conservation, agriculture, disaster management, healthcare, and 3D mapping, where they are employed by governments and companies 
     to gather valuable insights.</p>

     <p className='playfair-display' style={{color:'black',fontFamily:'Sans-serif',marginTop:'10px',marginLeft:'570px'}}>Our Drone workshop program is meticulously crafted by industry experts, academicians, and our engineers.
      It is structured to cater to beginners, gradually immersing participants in the diverse applications of Drone Technology.</p>
 
      <p className='playfair-display' style={{color:'black',fontFamily:'Sans-serif',marginTop:'10px',marginLeft:'570px'}}>No prior knowledge of drones, flying systems, autonomous technology, or flying experience is necessary 
      to join us on this exciting drone journey.  We encourage all enthusiasts to adhere to the regulations and guidelines set by the Directorate General of Civil Aviation (DGCA)</p>
      </div>
    </div>
    <div>
<br/>
             
      </div>
      <br/>
  <br/>
  <div style={{backgroundColor: '#fff', // Light green background color
        padding: '20px',
        borderRadius: '10px',
        margin: '20px',
        boxShadow: '0 4px 8px grey'}}> 
        <img
      className="slide-icon"
      src={image12}
      alt="img"
      style={{ height: '350px', width: '530px', margin: '0 10px',marginLeft:'30px',marginTop:'10px' }}
    />
    <h2 className='playfair-display' style={{color:'black',fontFamily:'Sans-serif',marginTop:'-345px',fontSize:'22px',marginLeft:'400px'}}>Benefits of the Drone Workshops Program</h2>
    <p className='playfair-display'  style={{color:'black',fontFamily:'Sans-serif',marginTop:'-10px',marginLeft:'570px'}}>Embark on a transformative learning experience that not only includes fabrication but 
also involves hands-on engagement with real-time applications. Elevate your career prospects by exploring diverse industries such as Hardware Development,
 Drovionics Programming, and Image Processing. Gain comprehensive insights into agriculture, disaster management, health, and weather management, enhancing your 
 situational awareness, airspace management, communication skills, and ability to gather critical sensor data.</p>

 <p className='playfair-display' style={{color:'black',fontFamily:'Sans-serif',marginTop:'10px',marginLeft:'570px'}}>Expand your expertise in working with Android Mobile Applications and the Internet of 
     Things (IoT) platform. Stay abreast of the latest DGCA regulations on drones, ensuring compliance and operational safety. Our program offers 100% practical 
     training, emphasizing complete hands-on learning.</p>
 
         </div>
    
<br/>
        
  <br/>
            
  <footer style={{ fontSize: '10px',fontFamily:'Sans-serif', padding: '38px', backgroundColor: '#000068', color: '#000', width: '1500px', marginLeft: '150px' }}>
    <div className="d-flex justify-content-between" style={{ marginLeft: '90px', marginRight: '90px' }}>
      <div className="col-md-12 col-lg-4 col-mobile-down">
        <div className="mobile-collapse">
          <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white' }}>Contact Information</h4>
          <div className="pt-collapse-content playfair-display" style={{ color: 'white', fontSize: '12px' }}>
            <address className='playfair-display'>
              <span># 70 </span>B block <span>1st</span> floor Anandnagar Mysore<span> 570022</span> Karnataka<br />
              <a className='playfair-display' href="mailto:pieflyaerospace@gmail.com" style={{ color: 'white', fontSize: '12px' }}>Info@pieflyaerospace.com</a>
            </address>
          </div>
        </div>
      </div>
  
      <div className="col-md-3 col-lg-2">
        <div className="mobile-collapse" style={{ maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}>
          <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white', textAlign: 'center' }}>Quick Links</h4>
          <div className="pt-collapse-content" style={{ color: 'white', fontSize: '12px' }}>
            <div className="oval-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px', padding: '10px' }}>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Home</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Training Program</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Enroll</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Shop</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Contacts</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Elements</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div className="col-md-3 col-lg-2">
        <div className="mobile-collapse" style={{ maxWidth: '300px', marginLeft: 'auto', marginRight: 'auto' }}>
          <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white', textAlign: 'center' }}>Useful Links</h4>
          <div className="pt-collapse-content" style={{ color: 'white', fontSize: '12px' }}>
            <div className="oval-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px', padding: '10px' }}>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Products Documents</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Services Documents</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Products</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Blog and News</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div className="col-md-3 col-lg-2">
        <div className="mobile-collapse" style={{ maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}>
          <h4 className="footer-title collapse-title playfair-display" style={{ color: 'white', textAlign: 'center' }}>Main Menu</h4>
          <div className="pt-collapse-content" style={{ color: 'white', fontSize: '12px' }}>
            <div className="oval-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px', padding: '10px' }}>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>About Us</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Gallery</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Projects</span></a>
              </div>
              <div className="oval-box" style={{ width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Contacts</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        <div className="footer-custom">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-4">
                {/* <div className="copyright"style={{color: 'white',fontSize:'12px' }}>
                  &copy; 2019 Quadron. All rights reserved.
                </div> */}
              </div>
            
             <div className='social-icons' style={{marginLeft:'600px',marginTop:'-20px'}}>
             <a className='social-icon-link whatsapp' href='https://wa.me/yourwhatsapplink' target='_blank' aria-label='WhatsApp' style={{ marginLeft: '-40px' }}>
  <FontAwesomeIcon icon={faWhatsapp} />
  </a>
    <a className='social-icon-link facebook' href='/' target='_blank' aria-label='Facebook'>
      <FontAwesomeIcon icon={faFacebookF} />
    </a>
    <a className='social-icon-link instagram' href='https://www.instagram.com/pieflyofficial?igsh=bmU0emdnbWs2aGIw' target='_blank' aria-label='Instagram' style={{marginLeft:'-40px'}}>
      <FontAwesomeIcon icon={faInstagram} />
    </a>
    <a className='social-icon-link twitter' href='https://x.com/pieflyofficial?t=RHOzsk0Vc47uIvKSCc5XVA&s=09' target='_blank' aria-label='Twitter' style={{marginLeft:'-40px'}}>
    <TfiTwitter />
    </a>
    <a className='social-icon-link linkedin' href='https://in.linkedin.com/company/piefly-r-d-private-limited' target='_blank' aria-label='LinkedIn' style={{marginLeft:'-40px'}}>
    <AiOutlineLinkedin />
    </a>
    <a className='social-icon-link Youtube' href='https://youtube.com/@piefly4302?si=Qecbd2nJ80cYx-Hk' target='_blank' aria-label='Youtube' style={{marginLeft:'-40px'}}>
    <BsYoutube />  </a>
  </div>
  
                </div>
              </div>
            </div>
          
      </footer>
    </>
  



  );
}

export default Workshop;

