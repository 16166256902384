import React, { useState, useEffect, useRef } from 'react';
 import { useNavigate, Link } from "react-router-dom";
import { MdMailOutline } from "react-icons/md";
import image1 from '../svg-sprite/mainslider/pieflylogo.png';
import "./MainContent.css";
import { SlGlobe, SlLock, SlBriefcase } from "react-icons/sl";
import { RxCounterClockwiseClock } from "react-icons/rx";
import { LiaLifeRing } from "react-icons/lia";
import image from '../svg-sprite/mainslider/rpas.png';
import image2 from '../svg-sprite/mainslider/agri.png';
import image3 from '../svg-sprite/mainslider/power.png';
import image4 from '../svg-sprite/mainslider/traffic.png';
import image5 from '../svg-sprite/mainslider/solar.png';
import image6 from '../svg-sprite/mainslider/tower.png';
import Navbar from '../New/navbar';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { TfiTwitter } from "react-icons/tfi";
import { AiOutlineLinkedin } from "react-icons/ai";
import { BsYoutube } from "react-icons/bs";
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles
import "./homepage1.css";
import MainContent from './MainContent';
import video3 from '../svg-sprite/mainslider/home.mp4';
import video1 from '../svg-sprite/mainslider/video1.mp4';
import video2 from '../svg-sprite/mainslider/video2.mp4';
import Slider from 'react-slick';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';



function Homepage (){

  const [isHovered5, setIsHovered5] = useState(false);
  const [isHovered6, setIsHovered6] = useState(false);
  const [isHovered7, setIsHovered7] = useState(false);
  const [isHovered8, setIsHovered8] = useState(false);
  const [isHovered9, setIsHovered9] = useState(false);

  const liStyles = {
    transition: 'transform 0.3s ease', // Add transition for smooth effect
    transform: isHovered5 ? 'scale(1.05)' : 'scale(1)', // Zoom in/out effect
    transform: isHovered6 ? 'scale(1.05)' : 'scale(1)', // Zoom in/out effect
    transform: isHovered7 ? 'scale(1.05)' : 'scale(1)', // Zoom in/out effect
    transform: isHovered8 ? 'scale(1.05)' : 'scale(1)', // Zoom in/out effect
    transform: isHovered9 ? 'scale(1.05)' : 'scale(1)', // Zoom in/out effect

  };
  const dropdownTimeoutRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEnquireClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
    
  useEffect(() => {
    return () => {
      clearTimeout(dropdownTimeoutRef.current);
    };
  }, []);

  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false, // disable slider autoplay
    arrows: true,
    afterChange: (current) => setCurrentSlide(current), // update current slide index
  };

  const [isMuted, setIsMuted] = useState(true);

  const handleMuteToggle = () => {
    setIsMuted(!isMuted);
  };

  const handleVideoEnded = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  useEffect(() => {
    const videos = document.querySelectorAll('video');
    videos.forEach((video) => {
      video.addEventListener('ended', handleVideoEnded);
    });

    return () => {
      videos.forEach((video) => {
        video.removeEventListener('ended', handleVideoEnded);
      });
    };
  }, []);

 

  const HeroContainer = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
  
    const images = [
      { src: image2, link: '/agriculture', height: '500px', width: '1100px' },
      { src: image3, link: '/powerline', height: '500px', width: '1100px' },
      { src: image4, link: '/trafficmonitor', height: '500px', width: '1100px' },
      { src: image5, link: '/solarpanelcleaning', height: '500px', width: '1100px' },
      { src: image6, link: '/celltower', height: '500px', width: '1100px' }
    ];
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 5000);
  
      return () => clearInterval(interval);
    }, [images.length]);
  
    return (
      <div className="hero-container">
        {images.map((image, index) => (
          <Link
            key={index}
            to={image.link}
            style={{fontFamily: 'Sans-serif',
              display: currentImageIndex === index ? 'block' : 'none',
              animation: currentImageIndex === index ? 'slide-in 1s' : 'none'
            }}
          >
            <img
              className="slide-icon"
              src={image.src}
              alt={`img-${index}`}
              style={{
                height: image.height,
                width: image.width,
                marginBottom: '10px',
                fontFamily: 'Sans-serif'
              }}
            />
          </Link>
        ))}
      </div>
    );
  };
      return(
      <> 
       
          <div>
           <Navbar/>
           </div>
            
           <div style={{ fontFamily: 'Sans-serif',display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>

           <div className="hero-container">
        <Slider ref={sliderRef} {...settings}>
          
          <div className="video">
          <Link to='/celltower' style={{fontFamily: 'Sans-serif', display: 'block', width: '100%', height: '100%' }}>
        <div className="video">
          <video width="100%" autoPlay muted>
            <source src={video3} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </Link>
          </div>
          <div className="video">
            <video width="100%"  autoPlay muted>
              <source src={video3} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </Slider>
      </div>
  </div>

  <div>
  <MainContent/>
</div>
<div
  style={{
    backgroundSize: 'cover', 
    backgroundPosition: 'center', 
    padding: '50px', 
    fontFamily: 'Sans-serif'
  }}
>
  <h3
    className='playfair-display'
    style={{
      fontSize: '30px',
      fontWeight: 'bold',
      marginTop: '50px',  // Adjusted from 600px to 50px to reduce gap
      marginLeft: 'auto',  // Center align horizontally
      marginRight: 'auto', // Center align horizontally
      textAlign: 'center', // Center align text horizontally
      fontFamily: 'Sans-serif'
    }}
  >
    KNOW ABOUT{' '}
    <span
      style={{
        color: '#0073cf',
        fontWeight: 'bold',
        fontFamily: 'Sans-serif',
      }}
    >
      RPAS TYPES
    </span>
  </h3>

  <div style={{ display: 'flex', marginLeft: '320px' }}>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'Sans-serif',
        marginRight: '20px', // Added margin between boxes
        transition: 'transform 0.3s', // Added transform transition
        transform: isHovered5 ? 'scale(1.1)' : 'scale(1)', // Apply scale based on hover state
      }}
    >
      <div
        style={{
          background: '#FFFFFF',
          padding: '20px',
          borderRadius: '5px',
          boxShadow: isHovered5
            ? '0px 0px 10px rgba(0, 0, 0, 0.1)'
            : 'none', // Apply box shadow only on hover
            fontFamily: 'Sans-serif'
        }}
        onMouseEnter={() => setIsHovered5(true)}
        onMouseLeave={() => setIsHovered5(false)}
      >
        <img
          className="slide-icon"
          src={image}
          alt="img"
          style={{ height: '80px', width: '120px',fontFamily: 'Sans-serif' }}
        />
        <br />
        <br />
        <p
          className='playfair-display'
          style={{
            color: 'black',
            fontSize: '14px',
            marginLeft: '40px',
            fontWeight: 'bold',
            fontFamily: 'Sans-serif',
          }}
        >
          NANO
        </p>
        <p
          className='playfair-display'
          style={{
            color: 'black',
            fontSize: '14px',
            marginTop: '5px',
            marginLeft: '15px',
            fontFamily: 'Sans-serif'
          }}
        >
          UP TO <span>250</span>GM
        </p>
      </div>
    </div>

    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: '10px',
        transition: 'transform 0.3s', // Added transform transition
        transform: isHovered6 ? 'scale(1.1)' : 'scale(1)', // Apply scale based on hover state
        fontFamily: 'Sans-serif',
      }}
    >
      <div
        style={{
          background: '#FFFFFF',
          padding: '10px',
          borderRadius: '5px',
          boxShadow: isHovered6
            ? '0px 0px 10px rgba(0, 0, 0, 0.1)'
            : 'none', // Apply box shadow only on hover
        }}
        onMouseEnter={() => setIsHovered6(true)}
        onMouseLeave={() => setIsHovered6(false)}
      >
        <img
          className="slide-icon"
          src={image}
          alt="img"
          style={{
            height: '80px',
            width: '120px',
            marginTop: '10px',
            marginLeft: '10px',
            fontFamily: 'Sans-serif'
          }}
        />
        <br />
        <br />
        <p
          className='playfair-display'
          style={{
            color: 'black',
            fontSize: '14px',
            marginLeft: '50px',
            fontWeight: 'bold',
            fontFamily: 'Sans-serif'
          }}
        >
          MICRO
        </p>
        <p
          className='playfair-display'
          style={{
            color: 'black',
            fontSize: '14px',
            marginTop: '5px',
            marginLeft: '5px',
            fontFamily: 'Sans-serif'
          }}
        >
          MORE THAN <span>250 </span>GM
        </p>
        <p
          className='playfair-display'
          style={{
            color: 'black',
            fontSize: '14px',
            marginTop: '-5px',
            marginLeft: '40px',
            fontFamily: 'Sans-serif'
          }}
        >
          UP TO <span>2</span>KG
        </p>
      </div>
    </div>

    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Sans-serif',
        alignItems: 'center',
        marginLeft: '20px',
        transition: 'transform 0.3s', // Added transform transition
        transform: isHovered7 ? 'scale(1.1)' : 'scale(1)', // Apply scale based on hover state
      }}
    >
      <div
        style={{
          background: '#FFFFFF',
          padding: '10px',
          borderRadius: '5px',
          boxShadow: isHovered7
            ? '0px 0px 10px rgba(0, 0, 0, 0.1)'
            : 'none', // Apply box shadow only on hover
        }}
        onMouseEnter={() => setIsHovered7(true)}
        onMouseLeave={() => setIsHovered7(false)}
      >
        <img
          className="slide-icon"
          src={image}
          alt="img"
          style={{
            height: '80px',
            width: '120px',
            marginTop: '10px',
            marginLeft: '0px',
            fontFamily: 'Sans-serif'
          }}
        />
        <br />
        <br />
        <p
          className='playfair-display'
          style={{
            color: 'black',
            fontSize: '14px',
            marginLeft: '40px',
            fontWeight: 'bold',
            fontFamily: 'Sans-serif'
          }}
        >
          SMALL
        </p>
        <p
          className='playfair-display'
          style={{
            color: 'black',
            fontSize: '14px',
            marginTop: '5px',
            marginLeft: '10px',
            fontFamily: 'Sans-serif'
          }}
        >
          MORE THAN <span>2</span>KG
        </p>
        <p
          className='playfair-display'
          style={{
            color: 'black',
            fontSize: '14px',
            marginTop: '-5px',
            marginLeft: '25px',
            fontFamily: 'Sans-serif'
          }}
        >
          UP TO <span>25</span>KG
        </p>
      </div>
    </div>
 
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: '20px',
        fontFamily: 'Sans-serif',
        transition: 'transform 0.3s', // Added transform transition
        transform: isHovered8 ? 'scale(1.1)' : 'scale(1)', // Apply scale based on hover state
      }}
    >
      <div
        style={{
          background: '#FFFFFF',
          padding: '10px',
          borderRadius: '5px',
          boxShadow: isHovered8
            ? '0px 0px 10px rgba(0, 0, 0, 0.1)'
            : 'none', // Apply box shadow only on hover
        }}
        onMouseEnter={() => setIsHovered8(true)}
        onMouseLeave={() => setIsHovered8(false)}
      >
        <img
          className="slide-icon"
          src={image}
          alt="img"
          style={{
            height: '80px',
            width: '120px',
            marginTop: '10px',
            marginLeft: '5px',
            fontFamily: 'Sans-serif'
          }}
        />
        <br />
        <br />
        <p
          className='playfair-display'
          style={{
            color: 'black',
            fontSize: '14px',
            marginLeft: '40px',
            fontWeight: 'bold',
            fontFamily: 'Sans-serif'
          }}
        >
          MEDIUM{' '}
        </p>
        <p
          className='playfair-display'
          style={{
            color: 'black',
            fontSize: '14px',
            marginTop: '5px',
            marginLeft: '10px',
            fontFamily: 'Sans-serif'
          }}
        >
          MORE THAN <span>25</span>KG
        </p>
        <p
          className='playfair-display'
          style={{
            color: 'black',
            fontSize: '14px',
            marginTop: '-5px',
            marginLeft: '25px',
            fontFamily: 'Sans-serif'
          }}
        >
          UP TO <span>150</span> KG
        </p>
      </div>
    </div>

    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: '20px',
        fontFamily: 'Sans-serif',
        transition: 'transform 0.3s', // Added transform transition
        transform: isHovered9 ? 'scale(1.1)' : 'scale(1)', // Apply scale based on hover state
      }}
    >
      <div
        style={{
          background: '#FFFFFF',
          padding: '10px',
          borderRadius: '5px',
          boxShadow: isHovered9
            ? '0px 0px 10px rgba(0, 0, 0, 0.1)'
            : 'none', // Apply box shadow only on hover
        }}
        onMouseEnter={() => setIsHovered9(true)}
        onMouseLeave={() => setIsHovered9(false)}
      >
        <img
          className="slide-icon"
          src={image}
          alt="img"
          style={{
            height: '80px',
            width: '120px',
            marginTop: '10px',
            marginLeft: '10px',
            fontFamily: 'Sans-serif'
          }}
        />
        <br />
        <br />
        <p
          className='playfair-display'
          style={{
            color: 'black',
            fontSize: '14px',
            marginLeft: '55px',
            fontWeight: 'bold',
            fontFamily: 'Sans-serif'
          }}
        >
          LARGE{' '}
        </p>
        <p
          className='playfair-display'
          style={{
            color: 'black',
            fontSize: '14px',
            marginTop: '-5px',
            marginLeft: '10px',
            fontFamily: 'Sans-serif'
          }}
        >
          MORE THAN <span>150 </span>KG
        </p>
        <br />
      </div>
    </div>
  </div>
</div>

      
<footer style={{ fontFamily: 'Sans-serif',fontSize: '10px', padding: '40px', backgroundColor: '#000068', color: '#000', width: '1500px', height: '500px', marginLeft: '0px', marginTop: '30px' }}>
      <div className="footer-col">
        <div className="container">
          <div className="list-col">
            <div className="row">

             <div className="col-md-12 col-lg-4 col-mobile-down" style={{ fontFamily: 'Sans-serif',marginLeft: '100px' }}>
  <div className="mobile-collapse">
    <img className="top-bar__logo" src={image1} alt='logo' style={{ fontFamily: 'Sans-serif',height: '180px', width: '180px', marginLeft: '30px', marginTop: '-40px' }} />

    <h4 className="footer-title collapse-title playfair-display" style={{ fontFamily: 'Sans-serif',color: 'white' }}>Contact Information</h4>
    &emsp;
    <div className="pt-collapse-content playfair-display" style={{fontFamily: 'Sans-serif',color: 'white', fontSize: '12px' }}>
      <address className=' playfair-display'>
        <div style={{ display: 'flex' }}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{ fill: 'white' }}>
            <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
          </svg>
          <span> # 70 B block
          1st floor, Anandnagar
          Mysore-570022 
          
          Karnataka
          </span>
          <br />
        </div>
        <a className='playfair-display' href="mailto:pieflyaerospace@gmail.com" style={{ fontFamily: 'Sans-serif',color: 'white', fontSize: '12px', display: 'flex', alignItems: 'center' }}>
          <span style={{ fontSize: '24px', color: 'white', marginRight: '8px' }}>
            <MdMailOutline />
          </span>
          Info@pieflyaerospace.com
        </a>
      </address>
    </div>
  </div>
</div>

           <div className="col-md-3 col-lg-2" style={{ fontFamily: 'Sans-serif',marginLeft: '200px', marginRight: '200px' }}>
  <div className="mobile-collapse" style={{fontFamily: 'Sans-serif', maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}>
    <h4 className="footer-title collapse-title playfair-display" style={{ fontFamily: 'Sans-serif',color: 'white', textAlign: 'center' }}>Quick Links</h4>
    <div className="pt-collapse-content" style={{ fontFamily: 'Sans-serif',color: 'white', fontSize: '12px' }}>
      <div className="oval-grid" style={{ fontFamily: 'Sans-serif',display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '20px', padding: '10px' }}>
        <div className="oval-box" style={{fontFamily: 'Sans-serif', width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="#" style={{ fontFamily: 'Sans-serif',color: 'black', textAlign: 'center' }}><span className='playfair-display'>Home</span></a>
        </div>
        <div className="oval-box" style={{ fontFamily: 'Sans-serif',width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="/#/training" style={{fontFamily: 'Sans-serif', color: 'black', textAlign: 'center' }}><span className='playfair-display'>Training Program</span></a>
        </div>
        <div className="oval-box" style={{ fontFamily: 'Sans-serif',width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="#" style={{ fontFamily: 'Sans-serif',color: 'black', textAlign: 'center' }}><span className='playfair-display'>Products</span></a>
        </div>
        <div className="oval-box" style={{ fontFamily: 'Sans-serif',width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="/#/contactus" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>Contact Us</span></a>
        </div>
        <div className="oval-box" style={{ fontFamily: 'Sans-serif',width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="#" style={{ fontFamily: 'Sans-serif',color: 'black', textAlign: 'center' }}><span className='playfair-display'>Services</span></a>
        </div>
        <div className="oval-box" style={{fontFamily: 'Sans-serif', width: '120px', height: '60px', borderRadius: '50%', background: '#E6E6E6', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
          <a href="#" style={{ color: 'black', textAlign: 'center' }}><span className='playfair-display'>About Us</span></a>
        </div>
      </div>
    </div>
  </div>
</div>


            </div>
          </div>
        </div>
      </div>
      <div className="footer-custom">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4">
              {/* Other content */}
            </div>
            <div className='social-icons' style={{ fontFamily: 'Sans-serif',marginLeft: '600px', marginTop: '30px' }}>
            <a className='social-icon-link whatsapp' href='https://wa.me/yourwhatsapplink' target='_blank' aria-label='WhatsApp' style={{ marginLeft: '-40px' }}>
  <FontAwesomeIcon icon={faWhatsapp} />
  </a>
              
              <a className='social-icon-link facebook' href='/' target='_blank' aria-label='Facebook'>
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a className='social-icon-link instagram' href='https://www.instagram.com/pieflyofficial?igsh=bmU0emdnbWs2aGIw' target='_blank' aria-label='Instagram' style={{fontFamily: 'Sans-serif', marginLeft: '-40px' }}>
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a className='social-icon-link twitter' href='https://x.com/pieflyofficial?t=RHOzsk0Vc47uIvKSCc5XVA&s=09' target='_blank' aria-label='Twitter' style={{ fontFamily: 'Sans-serif',marginLeft: '-40px' }}>
                <TfiTwitter />
              </a>
              <a className='social-icon-link linkedin' href='https://in.linkedin.com/company/piefly-r-d-private-limited' target='_blank' aria-label='LinkedIn' style={{ fontFamily: 'Sans-serif',marginLeft: '-40px' }}>
                <AiOutlineLinkedin />
              </a>
              <a className='social-icon-link Youtube' href='https://youtube.com/@piefly4302?si=Qecbd2nJ80cYx-Hk' target='_blank' aria-label='Youtube' style={{ fontFamily: 'Sans-serif',marginLeft: '-40px' }}>
                <BsYoutube />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
        
        </>
      );
}
export default Homepage;